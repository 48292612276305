import React, {useEffect} from 'react';
import ProductsCard from '../../components/category-without-sidebar/ProductsCard';
import {transformProduct} from '../../utils/product.transform';

import OwlCarousel from 'react-owl-carousel3';
import QuickView from '../Modal/QuickView';
import {useState} from 'react';

const options = {
    loop: true,
    nav: false,
    dots: true,
    autoplayHoverPause: true,
    autoplay: true,
    navText: [
        "<i class='fas fa-chevron-left'></i>",
        "<i class='fas fa-chevron-right'></i>",
    ],
    responsive: {
        0: {
            items: 1,
        },
        576: {
            items: 2,
        },
        768: {
            items: 2,
        },
        1024: {
            items: 3,
        },
        1200: {
            items: 4,
        },
    },
};

const RelatedProducts = (props) => {
    const [display, setDisplay] = useState(false);
    const [modalOpen, setModalopen] = useState(false);
    const [modalData] = useState(null);

    const closeModal = () => {
        setModalopen(false);
    };

    useEffect(() => {
        setDisplay(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    let {products} = props;
    return (
        <>
            <div className='related-products-area'>
                <div className='container'>
                    <div className='section-title'>
                        <h2>
                            <span className='dot'></span> Related Products
                        </h2>
                    </div>

                    <div className='row'>
                        {display ? (
                            <OwlCarousel
                                className='trending-products-slides-two owl-carousel owl-theme'
                                {...options}
                            >
                                {/* <div className="col-lg-12 col-md-12"> */}
                                {!!products && (
                                    <ProductsCard
                                        isCarousel={true}
                                        products={products.relatedProducts.map((product) =>
                                            transformProduct(product)
                                        )}
                                    />
                                )}
                            </OwlCarousel>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>

            {modalOpen ? (
                <QuickView closeModal={closeModal} modalData={modalData}/>
            ) : (
                ''
            )}
        </>
    );
};

export default RelatedProducts;
