import { withRouter } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { toastAlert } from '../../../utils/toastify';
import {
  SET_STRIPE_PAYMENT_METHOD_GQL,
  CREATE_EMPTY_CART_GQL,
  GET_STRIPE_CONFIG,
  GET_CART_INFO_GQL,
} from '../../../graphql/checkout.gql';
import { mapStateToProps, mapDispatchToProps } from './state';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#000',
      color: '#000',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',

      ':-webkit-autofill': {
        color: '#000',
      },
      '::placeholder': {
        color: '#000',
      },
    },
    invalid: {
      iconColor: '#00000',
      color: '#000000',
    },
  },
} as any;

const CardField = ({ onChange }) => (
  <div className='FormRow'>
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <div className='FormRow'>
    <label htmlFor={id} className='FormRowLabel'>
      {label}
    </label>
    <input
      className='FormRowInput'
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    style={{
      background: '#000',
      border: 'none',
      color: '#FFF',
      height: 38,
      lineHeight: '38px',
      padding: '0 20px',
      width: 170,
      opacity: processing || disabled ? 0.5 : 1,
    }}
    className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
    type='submit'
    disabled={processing || disabled}
  >
    {processing || disabled ? (
      <span className='spinner-grow spinner-grow-sm' />
    ) : (
      children
    )}
  </button>
);

const ErrorMessage = ({ children }) => (
  <div className='ErrorMessage' role='alert'>
    <svg width='16' height='16' viewBox='0 0 17 17'>
      <path
        fill='#FFF'
        d='M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z'
      />
      <path
        fill='#6772e5'
        d='M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z'
      />
    </svg>
    {children}
  </div>
);

const CheckoutForm = ({
  name,
  email,
  phone,
  cartId,
  clearCartAction,
  createEmptyCart,
  history,
  disabled,
  saveCartInfoAction,
  onSuccess,
  onError,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    email: email || 'emptyemail@email.com',
    phone: phone || '0900000000',
    name: name || 'empty name',
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement('card').focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: billingDetails,
    });

    // setProcessing(false);

    if (payload.error) {
      setError(payload.error);
    } else {
      const paymentMethod = payload.paymentMethod;
      setPaymentMethod({
        variables: {
          cartId,
          ccStripejsToken: paymentMethod.id,
        },
      });
    }
  };

  const [setPaymentMethod]: any = useMutation(SET_STRIPE_PAYMENT_METHOD_GQL, {
    onCompleted: (data) => {
      getCartInfo({ variables: { cartId } });
    },
    onError: ({ message }) => {
      toastAlert({ type: 'error', message });
      setProcessing(false);
    },
  });

  const [getCartInfo, { data: cartData }]: any = useLazyQuery(
    GET_CART_INFO_GQL,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      onCompleted: ({ cart }) => {
        saveCartInfoAction(cart);
        onSuccess();
        setProcessing(false);
      },
      onError: ({ message }: any) => {
        if (!!cartData) {
          const { cart } = cartData;
          if (!!saveCartInfoAction(cart)) {
            saveCartInfoAction(cart);
          }
        }
        toastAlert({ type: 'error', message });
        onError();
        setProcessing(false);
      },
    }
  );

  return (
    <form className='Form' onSubmit={handleSubmit}>
      <fieldset className='FormGroup' style={{ display: 'none' }}>
        <Field
          label='Name'
          id='name'
          type='text'
          placeholder='name'
          required
          autoComplete='name'
          value={billingDetails.name}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, name: e.target.value });
          }}
        />
        <Field
          label='Email'
          id='email'
          type='email'
          placeholder='email'
          required
          autoComplete='email'
          value={billingDetails.email}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, email: e.target.value });
          }}
        />
        <Field
          label='Phone'
          id='phone'
          type='tel'
          placeholder='phone'
          required
          autoComplete='tel'
          value={billingDetails.phone}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, phone: e.target.value });
          }}
        />
      </fieldset>
      <fieldset
        className='FormGroup'
        style={{
          border: '1px solid #abc',
          padding: 10,
          background: '#f7f8f9',
          marginBottom: 20,
        }}
      >
        <CardField
          onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}
        />
      </fieldset>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      <br />
      <SubmitButton
        processing={processing}
        error={error}
        disabled={!stripe || disabled}
      >
        PLACE ORDER
      </SubmitButton>
    </form>
  );
};

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const App = ({
  checkoutState,
  clearCartAction,
  saveCartTokenAction,
  history,
  onSuccess,
  onError,
  disabled,
  saveCartInfoAction,
}) => {
  const cartInfo = checkoutState && checkoutState.cartInfo;
  const name =
    cartInfo &&
    cartInfo.billing_address &&
    cartInfo.billing_address.lastname + cartInfo.billing_address.firstname;
  const email = cartInfo.email;
  const phone =
    cartInfo && cartInfo.billing_address && cartInfo.billing_address.telephone;

  const { cartId } = checkoutState;

  const [createEmptyCart]: any = useMutation(CREATE_EMPTY_CART_GQL, {
    onCompleted: ({ createEmptyCart }) => saveCartTokenAction(createEmptyCart),
  });

  const [stripeConfigId, setStripeConfigId] = useState('');
  const [stripePromise, setStripePromise] = useState(null);
  const [getCustomerAddress]: any = useLazyQuery(GET_STRIPE_CONFIG, {
    fetchPolicy: 'network-only',
    onCompleted: ({ storeConfig }) => {
        if (storeConfig.stripe_mode === 'test') {
            setStripePromise(loadStripe(storeConfig.stripe_test_pk));
            setStripeConfigId(storeConfig.stripe_test_pk);
        } else if (storeConfig.stripe_mode === 'live') {
            setStripePromise(loadStripe(storeConfig.stripe_live_pk));
            setStripeConfigId(storeConfig.stripe_live_pk);
        }
    },
    onError: ({ message }) => toastAlert({ type: 'error', message }),
  });

  const handleCreateEmptyCart = () => createEmptyCart();

  useEffect(() => {
    getCustomerAddress();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!stripeConfigId || !stripeConfigId.length) {
    return (
      <div
        className='container'
        style={{
          width: '100%',
          margin: '0 auto',
          padding: '20px 0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span className='spinner-grow spinner-grow-lg' />
      </div>
    );
  }

  return (
    <div
      className='container'
      style={{
        width: '100%',
        margin: '0 auto',
        padding: '20px 0',
      }}
    >
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
        <CheckoutForm
          {...{
            name,
            email,
            phone,
            cartId,
            clearCartAction,
            createEmptyCart: handleCreateEmptyCart,
            history,
            onSuccess,
            onError,
            disabled,
            saveCartInfoAction,
          }}
        />
      </Elements>
    </div>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(App) as any
) as any;
