import { gql } from "@apollo/client";
export const GET_CUSTOMER_ORDER_LIST_GQL = gql`
  query getOrder($currentPage: Int!){
    customer {
      orders(pageSize: 10, currentPage: $currentPage) {
        total_count
        page_info {
          page_size,
          current_page
        }
        items {
          number
          order_date
          status
          total {
            grand_total {
              value
              currency
            }
          }
        }
      }
    }
  }
`;
export const GET_CUSTOMER_ORDER_DETAIL_GQL = gql`
  query orders($param: String!) {
    customer {
      orders(filter: { number: { eq: $param } }) {
        items {
          number
          order_date
          status
          items {
            status
            product_name
            product_sku
            product_url_key
            product_sale_price {
              value
            }
            product_sale_price {
              value
              currency
            }
            quantity_ordered
            quantity_invoiced
            quantity_shipped
          }
          shipping_method
          payment_methods {
            name
            type
          }
          shipping_address {
            firstname
            lastname
            region
            country_code
            street
            city
            telephone
            postcode
          }
          billing_address {
            firstname
            lastname
            region
            country_code
            street
            city
            telephone
            postcode
          }
          shipments {
            id
            number
            items {
              product_name
              quantity_shipped
            }
          }
          total {
            grand_total {
              value
              currency
            }
            total_tax {
              value
            }
            subtotal {
              value
              currency
            }
            taxes {
              amount {
                value
                currency
              }
              title
              rate
            }
            shipping_handling {
              total_amount {
                value
              }
            }
            discounts {
              amount {
                value
                currency
              }
              label
            }
          }
        }
      }
    }
  }
`;
