import {useState} from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {useMutation, useLazyQuery} from '@apollo/client';
import * as ROUTINGS from '../../routings/path';
import {toastAlert} from '../../utils/toastify';
import AddToCart from '../Shared/AddToCart';
import {calculateProductItemPriceForWishList} from '../../utils/cart';
import {
    saveWishlistInfoAction,
    updateWishlistItemsAction,
} from '../../flows/wishlist/action';
import {saveCartInfoAction} from '../../flows/checkout/action';
import {
    REMOVE_PRODUCT_FROM_WISH_LIST,
} from '../../graphql/customer.gql';
import {
    ADD_SIMPLE_PRODUCT_TO_CART_GQL,
    ADD_CONFIGRURABLE_PRODUCT_TO_CART_GQL,
    GET_CART_INFO_GQL,
} from '../../graphql/checkout.gql';

const Wishlist = ({
                      onClick,
                      items,
                      checkoutState: {cartId},
                      wishlistState,
                      saveCartInfoAction,
                      updateWishlistItemsAction,
                  }: any) => {
    const wishlistId = wishlistState.info && wishlistState.info.id;
    const [removingId, setRemovingId] = useState(0);

    const closeWishlist = () => onClick(false);

    const handleRemoveWishlist = (wishlistItemId) => {
        setRemovingId(wishlistItemId);
        removeProductFromWishlist({
            variables: {
                wishlistId,
                id: wishlistItemId,
            },
        });
    };

    const [removeProductFromWishlist, {loading: isRemoveWishlistLoading}]: any =
        useMutation(REMOVE_PRODUCT_FROM_WISH_LIST, {
            onCompleted: ({removeProductsFromWishlist}) => {
                toastAlert({
                    type: 'info',
                    message: 'Removing product from with list success',
                });
                const newItem = removeProductsFromWishlist?.wishlist?.items_v2?.items;
                updateWishlistItemsAction(newItem);
            },
            onError: ({message}) => toastAlert({type: 'error', message}),
        });

    const [addSimpleProductToCart]: any = useMutation(
        ADD_SIMPLE_PRODUCT_TO_CART_GQL,
        {
            onCompleted: ({addSimpleProductsToCart}) => {
                toastAlert({type: 'success', message: 'Added to cart successful'});

                const cartInfo =
                    !!addSimpleProductsToCart && addSimpleProductsToCart.cart;
                !!cartInfo && saveCartInfoAction(cartInfo);
            },
            onError: ({message}) => {
                toastAlert({type: 'error', message});
                getCartInfo({variables: {cartId}});
            },
        }
    );

    const [addConfigurableProductToCart]: any = useMutation(
        ADD_CONFIGRURABLE_PRODUCT_TO_CART_GQL,
        {
            onCompleted: ({addConfigurableProductsToCart}) => {
                toastAlert({type: 'success', message: 'Added to cart successful'});
                const cartInfo =
                    !!addConfigurableProductsToCart && addConfigurableProductsToCart.cart;
                !!cartInfo && saveCartInfoAction(cartInfo);
            },
            onError: ({message}) => {
                toastAlert({type: 'error', message});
                getCartInfo({variables: {cartId}});
            },
        }
    );

    const [getCartInfo, {data: cartDataInfo}]: any = useLazyQuery(
        GET_CART_INFO_GQL,
        {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            onCompleted: ({cart}) => {
                saveCartInfoAction(cart);
            },
            onError: ({message}: any) => {
                if (!!cartDataInfo) {
                    const {cart} = cartDataInfo;
                    if (!!saveCartInfoAction(cart)) {
                        saveCartInfoAction(cart);
                    }
                }
            },
        }
    );

    const handleAddToCart = (product, childSku) => {
        const fromWishList = true;
        if (!product) return;
        const {sku, configurableOptions} = product;

        if (!!fromWishList && !!product.variants) {
            addConfigurableProductToCart({
                variables: {sku: childSku, quantity: 1, cartId, parentSku: sku},
            });
            onClick && onClick();
            return;
        }

        if (!!configurableOptions) {
            toastAlert({
                type: 'info',
                message: 'Please select required variants before adding to cart',
            });
            return;
        }

        addSimpleProductToCart({
            variables: {sku, quantity: 1, cartId},
        });

        onClick && onClick();
    };

    const handleAddAllToCart = (e) => {
        e.preventDefault();

        items.map((item, idx) => {
            handleRemoveWishlist(item.id);
            setTimeout(() => handleAddToCart(item.product, item.child_sku), 1000);
            return true;
        });
    };

    if (!items || !items.length) return null;

    return (
        <div
            className='modal right fade show shoppingWishlistModal'
            style={{
                display: 'block',
                paddingRight: '16px',
            }}
        >
            <div className='modal-dialog' role='document'>
                <div className='modal-content'>
                    <button
                        type='button'
                        className='close'
                        data-dismiss='modal'
                        aria-label='Close'
                        onClick={closeWishlist}
                    >
                        <span aria-hidden='true'>&times;</span>
                    </button>

                    <div className='modal-body'>
                        <h3>My Wish List ({items.length})</h3>

                        <div className='product-cart-content'>
                            {items.map((item, idx) => {
                                if (!item || !item.product) return null;
                                const currentProduct = !!item?.product?.variants
                                    ? item.product.variants.find(
                                        (variant) => variant.product.sku === item.child_sku
                                    )
                                    : item;

                                const {price, oldPrice}: any =
                                    calculateProductItemPriceForWishList(currentProduct);

                                return (
                                    <div
                                        className='product-cart'
                                        key={`wl-${idx}`}
                                        style={{position: 'relative'}}
                                    >
                                        <NavLink
                                            onClick={onClick}
                                            to={`${ROUTINGS.ROUTING_PRODUCT_DETAIL_PATH}/${item.product.url_key}`}
                                            className='product-image'
                                        >
                                            <img
                                                src={
                                                    item.product.thumbnail &&
                                                    item.product.thumbnail.url
                                                }
                                                alt={
                                                    item.product.thumbnail &&
                                                    item.product.thumbnail.label
                                                }
                                            />
                                        </NavLink>

                                        <div className='product-content'>
                                            <h3>
                                                <NavLink
                                                    onClick={onClick}
                                                    to={`${ROUTINGS.ROUTING_PRODUCT_DETAIL_PATH}/${item.product.url_key}`}
                                                >
                                                    {item.product.name}
                                                </NavLink>
                                            </h3>
                                            <span>
                        {item?.product?.variants?.map((variant) => {
                            if (variant?.product?.sku === item.child_sku) {
                                return variant?.attributes
                                    ?.map((attr) => attr.label)
                                    .join(' / ');
                            } else {
                                return null;
                            }
                        })}
                      </span>
                                            <div className='product-price'>
                                                {!!oldPrice && (
                                                    <span
                                                        style={{
                                                            textDecoration: 'line-through',
                                                            opacity: 0.4,
                                                            marginRight: 5,
                                                        }}
                                                        className='price'
                                                    >
                            ${oldPrice}
                          </span>
                                                )}
                                                <span className='price'>${price}</span>
                                                <AddToCart
                                                    childSku={item.child_sku}
                                                    fromWishList={true}
                                                    type={'text'}
                                                    product={item.product}
                                                    onClick={() => {
                                                        handleRemoveWishlist(item.id);
                                                        closeWishlist();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {isRemoveWishlistLoading && removingId === item.id ? (
                                            <span
                                                style={{position: 'absolute', right: 10, bottom: 10}}
                                                className='spinner-grow spinner-grow-sm'
                                            />
                                        ) : (
                                            <i
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleRemoveWishlist(item.id);
                                                }}
                                                style={{
                                                    position: 'absolute',
                                                    right: 10,
                                                    bottom: 10,
                                                    color: 'red',
                                                    cursor: 'pointer',
                                                }}
                                                className={'far fa-trash-alt'}
                                            ></i>
                                        )}
                                    </div>
                                );
                            })}
                        </div>

                        {!!items && !!items.length && (
                            <div className='product-cart-btn'>
                                <NavLink
                                    to='#'
                                    className='btn btn-light'
                                    onClick={handleAddAllToCart}
                                >
                                    Add All To Cart
                                </NavLink>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const mapStateToProps = (state) => ({
    wishlistState: state.wishlist,
    checkoutState: state.checkout,
});

export const mapDispatchToProps = (dispatch) => ({
    saveWishlistInfoAction: (data) => dispatch(saveWishlistInfoAction(data)),
    updateWishlistItemsAction: (data) =>
        dispatch(updateWishlistItemsAction(data)),
    saveCartInfoAction: (data: any) => dispatch(saveCartInfoAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);
