import { connect } from 'react-redux';
import { useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { toastAlert } from '../../../utils/toastify';
import { checkEmptryString } from '../../../utils/validation';
import {
  UPDATE_CUSTOMER_INFO_GQL,
  GET_CUSTOMER_DATA_GQL,
} from '../../../graphql/customer.gql';
import { mapStateToProps, mapDispatchToProps } from './state';

interface IProps {
  userState: any;
  saveUerInfoAction: any;
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props: IProps) => {
  const {
    userState: { info: userInfo },
    saveUerInfoAction,
  } = props;
  if(!userInfo) return null;
  const { email, firstname, lastname, is_subscribed, date_of_birth } = userInfo;

  const [firstName, setFirstName] = useState(firstname);
  const [lastName, setLastName] = useState(lastname);
  const [birthday, setBirthday] = useState(date_of_birth);
  const [isSubscribed, setSubscribed] = useState(is_subscribed);

  const hanndleChangeFirstName = (e) => setFirstName(e.target.value);
  const hanndleChangeLastName = (e) => setLastName(e.target.value);
  const hanndleChangeBirthday = (e) => setBirthday(e.target.value);
  const hanndleChangeSubscribed = (e) => setSubscribed(!isSubscribed);

  const validateForm = () => {
    if (checkEmptryString(firstName)) {
      toastAlert({ type: 'error', message: 'Error: First name is invalid.' });
      return false;
    }

    if (checkEmptryString(lastName)) {
      toastAlert({ type: 'error', message: 'Error: Last name is invalid.' });
      return false;
    }

    return true;
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();

    if (!validateForm()) return false;

    submitForm({
      variables: {
        firstname: firstName,
        lastname: lastName,
        date_of_birth: birthday,
        is_subscribed: isSubscribed,
      },
    });
  };

  const [submitForm, { loading: isSubmitLoading }]: any = useMutation(
    UPDATE_CUSTOMER_INFO_GQL,
    {
      onCompleted: () => {
        toastAlert({ type: 'success', message: 'Update info success' });
        getCustomerData();
      },
      onError: ({ message }) => toastAlert({ type: 'error', message }),
    }
  );

  const [getCustomerData]: any = useLazyQuery(GET_CUSTOMER_DATA_GQL, {
    fetchPolicy: 'network-only',
    onCompleted: ({ customer }) => saveUerInfoAction(customer),
  });

  return (
    <>
      <div className='col-lg-6 col-sm-12' style={{ margin: '0 auto' }}>
        <form className='login-form' onSubmit={handleSubmitForm}>
          <div className='form-group'>
            <label>Email</label>
            <input
              value={email}
              type='text'
              disabled={true}
              className='form-control'
              placeholder='Email is read only'
              id='email'
              name='email'
            />
          </div>

          <div className='form-group'>
            <label>
              First name <span className='required'>*</span>
            </label>
            <input
              value={firstName}
              onChange={hanndleChangeFirstName}
              type='text'
              required
              className='form-control'
              placeholder='Enter your first name'
              id='first-name'
              name='first-name'
            />
          </div>

          <div className='form-group'>
            <label>
              Last name <span className='required'>*</span>
            </label>
            <input
              value={lastName}
              required
              onChange={hanndleChangeLastName}
              type='text'
              className='form-control'
              placeholder='Enter your last name'
              id='last-name'
              name='last-name'
            />
          </div>

          <div className='form-group'>
            <label>Birthday</label>
            <input
              value={birthday}
              onChange={hanndleChangeBirthday}
              type='date'
              className='form-control'
              placeholder='Enter your birthday'
              id='birthday'
              name='birthday'
            />
          </div>

          <div className='pb-3 form-check form-switch'>
            <input
              checked={isSubscribed}
              onChange={hanndleChangeSubscribed}
              className='form-check-input'
              type='checkbox'
              id='isSubscribed'
            />
            <label
              style={{ cursor: 'pointer' }}
              className='form-check-label'
              htmlFor='isSubscribed'
            >
              Is Subscribed
            </label>
          </div>

          <button
            type='submit'
            disabled={isSubmitLoading}
            className='btn btn-primary'
          >
            {!isSubmitLoading ? (
              'Save'
            ) : (
              <span className='spinner-grow spinner-grow-sm' />
            )}
          </button>
        </form>
      </div>
    </>
  );
});
