import React, {useEffect, useState} from 'react';
import {
    QUERY_GET_POPULAR_PRODUCT_GQL,
    QUERY_GET_BESTSELLERS_PRODUCT_GQL,
    QUERY_GET_TRENDING_PRODUCT_GQL,
    QUERY_GET_CMS_PAGE_GQL
} from '../../graphql/layout.gql';
import {GET_POST_RECENT_GQL} from '../../graphql/blog.gql';
import callGetApi, {toAstError} from '../../api';

import {useLazyQuery} from '@apollo/client';
import {toastAlert} from '../../utils/toastify';
import {transformProduct} from '../../utils/product.transform';
import Meta from '../../components/meta';

import BannerSlider from '../../components/shop-style-seven/BannerSlider';
import ProductCategories from '../../components/shop-style-seven/ProductCategories';
import PopularProducts from '../../components/shop-style-seven/PopularProducts';
import ProductsCategoryStyleTwo from '../../components/shop-style-seven/ProductsCategoryStyleTwo';
import BestSellersProducts from '../../components/shop-style-seven/BestSellersProducts';
import Facility from '../../components/Common/Facility';
import TrendingProducts from '../../components/shop-style-seven/TrendingProducts';
import TestimonialsTwo from '../../components/Common/TestimonialsTwo';
import NewsThree from '../../components/Common/NewsThree';
import Subscribe from '../../components/Common/Subscribe';
import Partner from '../../components/Common/Partner';
import InstagramPhoto from '../../components/Common/InstagramPhoto';

export default () => {

    const [popularProducts, setPopularProduct] = useState(null);
    const [bestSellerProducts, setBestSellerProducts] = useState(null);
    const [trendingProducts, setTrendingProducts] = useState(null);
    const [posts, setPosts] = useState(null);
    const [meta, setMeta] = useState({
        title: "",
        description: "",
        keyword: ""
    });

    const init = () => {
        queryGetPopularProduct();
        queryGetBestSellerProduct();
        queryGetMeta("home");
        queryGetTrendingProduct(process.env.REACT_APP_TRENDING_CATEGORY_ID);
        getPostRecent({
            variables: {
                page: 1,
            },
        });
    };

    const queryGetMeta = (identifier) => {
        let final_query = QUERY_GET_CMS_PAGE_GQL.replaceAll("{{$identifier}}", "\"" + identifier + "\"");

        callGetApi(final_query).then((res: any) => {
            let cmsPage =
                !!res.data &&
                !!res.data.data &&
                !!res.data.data.cmsPage && res.data.data.cmsPage;

            if (cmsPage) {
                setMeta({
                    title: cmsPage.meta_title,
                    description: cmsPage.meta_description,
                    keyword: cmsPage.meta_keywords
                });
            }

            if (res.data.errors) {
                let errors = res.data.errors;
                toAstError(errors);
            }
        });
    };

    const queryGetPopularProduct = () => {
        callGetApi(QUERY_GET_POPULAR_PRODUCT_GQL, false, true).then((res: any) => {
            let popularProducts =
                !!res.data &&
                !!res.data.data &&
                !!res.data.data.popularProducts &&
                res.data.data.popularProducts;

            if (popularProducts) {
                setPopularProduct(popularProducts.items);
            }

            if (res.data.errors) {
                let errors = res.data.errors;
                toAstError(errors);
            }
        });
    };

    const queryGetBestSellerProduct = () => {
        callGetApi(QUERY_GET_BESTSELLERS_PRODUCT_GQL, false, true).then((res: any) => {
            let bestSellerProducts =
                !!res.data &&
                !!res.data.data &&
                !!res.data.data.bestSellerProducts &&
                res.data.data.bestSellerProducts;

            if (bestSellerProducts) {
                setBestSellerProducts(bestSellerProducts.items);
            }

            if (res.data.errors) {
                let errors = res.data.errors;
                toAstError(errors);
            }
        });
    };

    const queryGetTrendingProduct = (trendingId) => {
        let final_query = QUERY_GET_TRENDING_PRODUCT_GQL.replaceAll(
            '{{$trendingCategoryId}}',
            '"' + trendingId + '"'
        );

        callGetApi(final_query, false, true).then((res: any) => {
            let trendingProducts =
                !!res.data &&
                !!res.data.data &&
                !!res.data.data.products &&
                res.data.data.products;

            if (trendingProducts) {
                setTrendingProducts(trendingProducts.items);
            }

            if (res.data.errors) {
                let errors = res.data.errors;
                toAstError(errors);
            }
        });
    };

    const [getPostRecent]: any = useLazyQuery(GET_POST_RECENT_GQL, {
        fetchPolicy: 'network-only',
        onCompleted: ({posts}) => {
            setPosts(posts.items);
        },
        onError: ({message}) => toastAlert({type: 'error', message}),
    });

    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Meta
                title={meta.title}
                description={meta.description}
                keywords={meta.keyword}
            />
            <BannerSlider/>

            <ProductCategories/>
            {!!popularProducts && (
                <PopularProducts
                    popularProducts={popularProducts.map((product) =>
                        transformProduct(product)
                    )}
                />
            )}

            <ProductsCategoryStyleTwo/>

            {!!bestSellerProducts && (
                <BestSellersProducts
                    bestSellersProducts={bestSellerProducts.map((product) =>
                        transformProduct(product)
                    )}
                />
            )}
            <Facility/>

            {!!trendingProducts && (
                <TrendingProducts
                    trendingProducts={trendingProducts.map((product) =>
                        transformProduct(product)
                    )}
                />
            )}
            <TestimonialsTwo/>
            {!!posts && <NewsThree posts={posts}/>}

            <Subscribe/>

            <Partner/>

            <InstagramPhoto/>

            {/* <AddsModal /> */}
        </>
    );
};
