import { saveCartInfoAction } from '../../../flows/checkout/action';
import { saveWishlistInfoAction } from '../../../flows/wishlist/action';
import { saveCompareList } from '../../../flows/compare/action';

export const mapStateToProps = (state) => ({
  checkoutState: state.checkout,
  authState: state.auth,
  wishlistState: state.wishlist,
  compareState: state.compare,
});

export const mapDispatchToProps = (dispatch) => ({
  saveCartInfoAction: (data: any) => dispatch(saveCartInfoAction(data)),
  saveWishlistInfoAction: (data: any) => dispatch(saveWishlistInfoAction(data)),
  saveCompareList: (data: any) => dispatch(saveCompareList(data)),
});
