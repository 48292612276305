import {PRODUCT_STOCK_STATUS} from '../constants/status';

export const transformProduct = (product) => {
    if (!product) return null;

    const price =
        (!!product.price_range &&
            !!product.price_range.minimum_price &&
            !!product.price_range.minimum_price.regular_price &&
            product.price_range.minimum_price.regular_price.value) ||
        0;

    const finalPrice =
        !!product.price_range &&
        !!product.price_range.minimum_price &&
        !!product.price_range.minimum_price.final_price
            ? product.price_range.minimum_price.final_price.value
            : price;

    const small_image = (!!product.small_image && product.small_image.url) || '';

    const image_label = (!!product.small_image && product.small_image.label) || '';

    return {
        small_image,
        image_label,
        configurableOptions: product.configurable_options,
        imageHover: small_image,
        title: product.name,
        price: finalPrice,
        oldPrice: finalPrice !== price ? price : 0,
        id: product.id,
        slug: product.url_key,
        sku: product.sku,
        ratingSummary: product.rating_summary,
        reviewCount: product.review_count,
        variants: product.variants
    };
};

export const transformProductDetail = (product) => {
    if (!product) return null;
    const price =
        !!product.price_range &&
        !!product.price_range.minimum_price &&
        !!product.price_range.minimum_price.regular_price
            ? product.price_range.minimum_price.regular_price.value
            : 0;

    const finalPrice =
        !!product.price_range &&
        !!product.price_range.minimum_price &&
        !!product.price_range.minimum_price.final_price
            ? product.price_range.minimum_price.final_price.value
            : price;

    return {
        id: product.id,
        name: product.name,
        sku: product.sku,
        slug: product.url_key,
        price: finalPrice,
        oldPrice: finalPrice !== price ? price : 0,
        ratingSummary: product.rating_summary,
        reviewCount: product.review_count,
        shortDescription: product.short_description && product.short_description.html,
        description: product.description && product.description.html,
        stockStatus: PRODUCT_STOCK_STATUS[product.stock_status] || '',
        configurableOptions: product.configurable_options,
        variants: product.variants,
        image: product.image,
        mediaGallery: product.media_gallery,
        relatedProducts: product.related_products,
        reviews: product.reviews && product.reviews.items,
        meta_title: product.meta_title ? product.meta_title : "",
        meta_description: product.meta_description ? product.meta_description : "",
        meta_keyword: product.meta_keyword ? product.meta_keyword : ""
    };
};
