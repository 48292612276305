import {NavLink} from 'react-router-dom';
import {UserRouter} from '../../../routings/router';
import * as ROUTINGS from '../../../routings/path';
import React, {useState} from "react";
import Meta from "../../../components/meta";

const USER_NAV = [
    {
        id: 1,
        title: 'Edit User Info',
        link: ROUTINGS.ROUTING_USER_EDIT,
    },
    {
        id: 2,
        title: 'Change password',
        link: ROUTINGS.ROUTING_USER_CHANGE_PASSWORD,
    },
    {
        id: 4,
        title: 'Address Book',
        link: ROUTINGS.ROUTING_ADDRESS_LIST,
    },
    {
        id: 3,
        title: 'My Orders',
        link: ROUTINGS.ROUTING_ORDER_LIST,
    },
];

export default () => {

    const [meta] = useState({
        title: "Customer Profile",
        description: "",
        keyword: ""
    });

    return (
        <>
            <Meta
                title={meta.title}
                description={meta.description}
                keywords={meta.keyword}
            />
            <section className='products-collections-area ptb-60'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-12'>
                            <h6
                                style={{lineHeight: '50px', borderBottom: '2px solid #000'}}
                            >
                                USER NAVIGATION
                            </h6>
                            <div style={{padding: '14px 0'}}>
                                {USER_NAV.map((item, idx) => (
                                    <React.Fragment key={idx}>
                                        <NavLink
                                            style={{
                                                color: '#999',
                                                lineHeight: '44px',
                                                fontWeight: 300,
                                            }}
                                            activeStyle={{color: '#000', fontWeight: 700}}
                                            to={item.link}
                                        >
                                            {item.title}
                                        </NavLink>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>

                        <div className='col-lg-9 col-md-12'>
                            <UserRouter/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
