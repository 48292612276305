import { Route, Redirect } from 'react-router-dom';
import { auth } from '../utils/auth';
import * as ROUTINGS from './path';

export const AuthenticatedRoute = ({
  component: Component,
  exact = false,
  path,
}: {
  component: React.ComponentType<any>;
  path: string;
  exact?: boolean;
}) => (
  <Route
    path={path}
    render={(routeProps) => {
      if (!!auth.loggedIn()) return <Component {...routeProps} exact={exact} />;

      // localStorage.setItem('referral-redirect', window.location.pathname + window.location.search);
      return <Redirect to={ROUTINGS.ROUTING_AUTH_SIGN_IN} />;
    }}
  />
);

export const UnAuthenticatedRoute = ({
  component: Component,
  exact = false,
  path,
}: {
  component: React.ComponentType<any>;
  path: string;
  exact?: boolean;
}) => (
  <Route
    path={path}
    render={(routeProps) => {
      if (!auth.loggedIn()) return <Component {...routeProps} exact={exact} />;

      // localStorage.setItem('referral-redirect', window.location.pathname + window.location.search);
      return <Redirect to={ROUTINGS.ROUTING_HOME} />;
    }}
  />
);
