import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client';
import { getUrlParameter } from '../../../utils/uri';
import { toastAlert } from '../../../utils/toastify';
import * as ROUTINGS from '../../../routings/path';
import {
  PLACE_ORDER_GQL,
  SET_PAYPAL_PAYMENT_METHOD_GQL,
  CREATE_EMPTY_CART_GQL,
} from '../../../graphql/checkout.gql';
import { mapStateToProps, mapDispatchToProps } from './state';

interface IProps {
  location: any;
  history: any;
  checkoutState: any;
  saveCartTokenAction: Function;
  saveAuthInfoAction: Function;
  clearCartAction: Function;
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )((props: IProps) => {
    const {
      saveCartTokenAction,
      clearCartAction,
      checkoutState: { cartId },
      history,
    } = props;

    const [setPaymentMethod]: any = useMutation(SET_PAYPAL_PAYMENT_METHOD_GQL, {
      onCompleted: (data) => placeOrder({ variables: { cartId } }),
      onError: ({ message }) => toastAlert({ type: 'error', message }),
    });

    const [placeOrder]: any = useMutation(PLACE_ORDER_GQL, {
      onCompleted: ({ placeOrder }) => {
        toastAlert({
          type: 'success',
          message: 'Congratulation, your order is completed',
        });
        clearCartAction();
        createEmptyCart();
        history.push(
          `${ROUTINGS.ROUTING_ORDER_SUCCESS}?id=${
            placeOrder && placeOrder.order && placeOrder.order.order_number
          }`
        );
      },
      onError: ({ message }) => toastAlert({ type: 'error', message }),
    });

    const [createEmptyCart]: any = useMutation(CREATE_EMPTY_CART_GQL, {
      onCompleted: ({ createEmptyCart }) =>
        saveCartTokenAction(createEmptyCart),
    });

    useEffect(() => {
      const {
        location: { search },
      } = props;

      const token = getUrlParameter(search.replace('#', '?'), 'token');
      const payerId = getUrlParameter(search.replace('#', '?'), 'PayerID');

      setPaymentMethod({
        variables: {
          cartId,
          payerId,
          token,
        },
      });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <div className='text-center py-5 my-5'>
        <span className='spinner-grow spinner-grow-lg my-5' />
        <h3 className='py-1'>Connect with Paypal</h3>
        <h4 className='py-1'>Redirecting, please wait a moment...</h4>
      </div>
    );
  }) as any
);
