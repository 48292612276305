import React, {useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import {getUrlParameter} from '../../../utils/uri';
import {transformProduct} from '../../../utils/product.transform';
import ProductsCard from '../../../components/category-without-sidebar/ProductsCard';
import ProductsFilterOptions from '../../../components/category-without-sidebar/ProductsFilterOptions';
import {
    QUERY_GET_CATEGORY_BY_SLUG,
    QUERY_GET_PRODUCTS_BY_CATEGORY_ID
} from '../../../graphql/catelog.gql';
import {PAGE_SIZE_DEFAULT, SORT_DEFAULT} from '../../../constants/product';
import {createSortObject, validSortValue} from '../../../utils/product';
import NotFoundImage from '../../../images/404.png';

import styles from './style.module.scss';
import callGetApi, {toAstError} from "../../../api";
import Meta from "../../../components/meta";

interface IProps {
    match: any;
    location: any;
    history: any;
}

export default (props: IProps) => {
    const [products, setProducts] = useState(null);
    const {
        match: {
            params: {categorySlug},
        },
        location: {search},
        history,
    } = props;

    const [categoryName, setCategoryName] = useState('');
    const [meta, setMeta] = useState({
        title: "",
        description: "",
        keyword: ""
    });

    /* page info */
    const [currentPage, setCurrentPage] = useState('1');
    const [totalPage, setTotalPage] = useState(0);

    /* to get filter aggregations from API */
    const [filter, setFilter] = useState([]);

    /* to get sort field from API */
    const [sortField, setSortField] = useState([]);

    /* sort / page size form query params */
    const [pageSize, setPageSize] = useState(PAGE_SIZE_DEFAULT);
    const [sort, setSort] = useState(SORT_DEFAULT);
    const [getProductsByCategoryIdLoading, setGetProductsByCategoryIdLoading] = useState(true);
    const [getCategoryBySlugLoading, setGetCategoryBySlugLoading] = useState(true);
    const [readyToQuery, setReadyToQuery] = useState(false);
    const [gridType, setGridType] = useState(null);

    /* combine filter from params */
    const [combineFilter, setCombineFilter] = useState([]);

    const updateCombindeFilter = (filterList) => {
        if (!filterList || !filterList.length) return [];

        let newCombineFilter = combineFilter;
        filterList.map((filter) => {
            newCombineFilter = newCombineFilter.filter(
                (item) => item.key !== filter.key
            );
            return true;
        });

        newCombineFilter = [...newCombineFilter, ...filterList];
        return newCombineFilter.filter((item) => !!item && !!item.value);
    };

    const splitParams = (search) => {
        if (!search || !search.length) return [];

        let str = search.replace('?', '');
        if (!str || !str.length) return [];

        const splitted = str.split('&');
        if (!splitted || !splitted.length) return [];
        return splitted
            .map((item) => {
                const query = item.split('=');
                if (!query || query.length !== 2) return null;

                return {
                    key: query[0],
                    value: query[1],
                };
            })
            .filter((item) => !!item);
    };

    const splitParamsIgnorePageSizeAndSort = (search) => {
        if (!search || !search.length) return [];

        let str = search.replace('?', '');
        if (!str || !str.length) return [];

        const splitted = str.split('&');
        if (!splitted || !splitted.length) return [];
        return splitted
            .map((item) => {
                const query = item.split('=');
                if (!query || query.length !== 2) return null;

                if (query[0] === 'page-size' || query[0] === 'sort') {
                    return null;
                }

                return {
                    key: query[0],
                    value: query[1],
                };
            })
            .filter((item) => !!item);
    };

    const init = () => {
        /* page size */
        const currentPageParams = getUrlParameter(search, 'page') || '1';
        setCurrentPage(currentPageParams);

        /* page size */
        const pageSizeParams = getUrlParameter(search, 'page-size') || PAGE_SIZE_DEFAULT;
        setPageSize(pageSizeParams);

        /* sort */
        const softParams = getUrlParameter(search, 'sort') || SORT_DEFAULT;
        setSort(validSortValue(softParams) ? softParams : SORT_DEFAULT);

        const splittedParams = splitParamsIgnorePageSizeAndSort(search);
        const newCombindeFilter = updateCombindeFilter(splittedParams);
        setCombineFilter(newCombindeFilter);

        const gridType = parseInt(getUrlParameter(search, 'gridType')) || 3;
        setGridType(gridType);

        setReadyToQuery(true);
    };

    const getProduct = (
        {
            categories = undefined,
            sortObject,
            combineFilter
        }) => {
        const newCombineFilter = !!categories
            ? updateCombindeFilter([
                ...combineFilter,
                {
                    key: 'category_id',
                    value: categories,
                },
            ])
            : combineFilter;

        let filter: any = {};
        newCombineFilter.map((item) => {
            if (item.key === 'price') {
                const plittedPrice = item.value.split('_');
                filter.price = {from: plittedPrice[0], to: plittedPrice[1]};
                return null;
            }

            filter[item.key] = {in: [String(item.value)]};
            return null;
        });

        queryGetProductsByCategoryId(filter, pageSize, currentPage, sortObject);
    };

    const queryGetProductsByCategoryId: any = (filter, pageSize, currentPage, sort) => {
        //keep keys as non-string
        filter = JSON.stringify(filter);
        filter = filter.replace(/"([^"]+)":/g, '$1:');

        //keep keys as non-string
        sort = JSON.stringify(sort);
        sort = sort.replace(/"([^"]+)":/g, '$1:');
        //ASC and DESC should not be string
        sort = sort.replace(/:"([^"]+)"/g, ':$1');

        let final_query = QUERY_GET_PRODUCTS_BY_CATEGORY_ID.replaceAll("{{$filter}}", filter);
        final_query = final_query.replaceAll("{{$pageSize}}", pageSize);
        final_query = final_query.replaceAll("{{$currentPage}}", currentPage);
        final_query = final_query.replaceAll("{{$sort}}", sort);

        callGetApi(final_query, false, true).then((res: any) => {
            let products =
                !!res.data &&
                !!res.data.data &&
                !!res.data.data.products && res.data.data.products;

            if (products) {
                setProducts(products);
                setSortField(
                    (products.sort_fields && products.sort_fields.options) || []
                );
                setFilter(products.aggregations);

                const totalPage = Math.floor(
                    products.total_count / products.page_info.page_size
                );
                setTotalPage(
                    totalPage * products.page_info.page_size === products.total_count
                        ? totalPage
                        : totalPage + 1
                );
                setGetProductsByCategoryIdLoading(false);
            }

            if (res.data.errors) {
                let errors = res.data.errors;
                toAstError(errors);
                updateUrl([{key: 'page', value: 1}]);
            }

            return;
        });
    };

    const getQueryProductCategory = (slug) => {
        let final_query = QUERY_GET_CATEGORY_BY_SLUG.replaceAll("{{$param}}", "\"" + slug + "\"");

        callGetApi(final_query, false, true).then((res: any) => {
            let categories =
                !!res.data &&
                !!res.data.data &&
                !!res.data.data.categories && res.data.data.categories;

            if (categories) {
                const sortObject = createSortObject(sort);
                setCategoryName(categories?.items[0]?.name);

                setMeta({
                    title: categories?.items[0]?.meta_title,
                    description: categories?.items[0]?.meta_description,
                    keyword: categories?.items[0]?.meta_keywords
                });

                setGetCategoryBySlugLoading(false);
                getProduct({
                    sortObject,
                    categories: categories?.items[0].id,
                    combineFilter,
                });
            }

            if (res.data.errors) {
                let errors = res.data.errors;
                toAstError(errors);
            }
        });
    };

    const [gridClass, setGridClass] = useState('products-col-four');
    const handleGrid = (gridType, classValue) => {
        setGridClass(classValue);
        setGridType(gridType);
    };

    const isSubmitLoading = getProductsByCategoryIdLoading || getCategoryBySlugLoading;

    const updateUrl = (filters) => {
        const currentUrl = splitParams(search);
        const keys = filters.map((items) => items.key);
        let newUrl = currentUrl.filter((item) => keys.indexOf(item.key) < 0);

        filters.map((item) => {
            if (!!item.value) {
                newUrl.push({
                    key: item.key,
                    value: item.value,
                });
            }

            return null;
        });

        const queryParams = newUrl
            .map((item) => {
                return `${item.key}=${item.value}`;
            })
            .join('&');
        const generateNewUrl = `${window.location.pathname}?${queryParams}`;

        history.push(generateNewUrl);
    };

    const handleOnChangePageSize = (e) => {
        e.preventDefault();

        const pageSize = e.target.value;
        updateUrl([{key: 'page-size', value: pageSize}]);
    };

    const handleChangeSort = (e, gridType = 3) => {
        e.preventDefault();
        setGridType(gridType);
        const sort = e.target.value;
        updateUrl([{key: 'sort', value: sort}, {key: 'gridType', value: gridType}]);
    };

    const handleChangeFilter = (key, value) => {
        updateUrl([{key, value}]);
        setCombineFilter(updateCombindeFilter([{key, value}]));
    };

    const handleRemoveFilter = (filters) => {
        const newCombineFilter = combineFilter.filter((filter) => {
            const find = filters.find((item) => item.key === filter.key);
            return !find;
        });

        setCombineFilter(newCombineFilter.filter((item) => !!item));

        !!filters &&
        updateUrl(
            filters.map((item) => ({
                key: item.key,
                value: undefined,
            }))
        );
    };

    const hanleOnPageChange = ({selected}) => {
        if (selected + 1 === parseInt(currentPage)) return;

        updateUrl([{key: 'page', value: selected * 1 + 1}]);
        setCurrentPage(selected);
    };

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.categorySlug, search]);

    useEffect(() => {
        if (readyToQuery) {
            setGetProductsByCategoryIdLoading(true);
            const categoryIdParams = getUrlParameter(search, 'category_id') || '';

            if (!categoryIdParams || !categoryIdParams.length) {
                !!categorySlug &&
                getQueryProductCategory(categorySlug);
            } else {
                const sortObject = createSortObject(sort);
                getProduct({
                    sortObject,
                    combineFilter: combineFilter
                });
            }
            setReadyToQuery(false);
        }
    }, [readyToQuery]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Meta
                title={meta.title ? meta.title : categoryName}
                description={meta.description}
                keywords={meta.keyword}
            />
            <Breadcrumb title={categoryName}/>

            <section className='products-collections-area ptb-60'>
                <div className='container'>
                    <div className='section-title'>
                        <h2>
                            <span className='dot'></span>
                            {`#${categoryName}`}
                        </h2>
                    </div>

                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            {!!isSubmitLoading ? (
                                <div className='d-flex justify-content-center py-5 my-5'>
                                    <span className='spinner-grow spinner-grow'/>
                                </div>
                            ) : !!products && !!products.items && !!products.items.length ? (
                                <>
                                    <ProductsFilterOptions
                                        pageSize={pageSize}
                                        onChangePageSize={handleOnChangePageSize}
                                        onChangeSort={handleChangeSort}
                                        onChangeFilter={handleChangeFilter}
                                        onRemoveFilter={handleRemoveFilter}
                                        sort={sort}
                                        sortField={sortField}
                                        onClick={handleGrid}
                                        pageInfo={products.page_info}
                                        totalCount={products.total_count}
                                        filter={filter}
                                        combineFilter={combineFilter}
                                        gridType={gridType}
                                    />
                                    <div
                                        id='products-filter'
                                        className={`products-collections-listing row ${gridClass}`}
                                    >
                                        <ProductsCard
                                            products={products.items.map((product) =>
                                                transformProduct(product)
                                            )}
                                        />
                                    </div>
                                </>
                            ) : (
                                <section className='error-area ptb-60'>
                                    <div className='container'>
                                        <div className='error-content'>
                                            <img src={NotFoundImage} alt='error'/>

                                            <h3>Product Not Found</h3>
                                            <p>
                                                There are have no product with this keyword, please try
                                                again
                                            </p>

                                            <NavLink to='/' className='btn btn-light'>
                                                Go to Home
                                            </NavLink>
                                        </div>
                                    </div>
                                </section>
                            )}
                        </div>
                        {totalPage > 1 && (
                            <div
                                className='col-lg-12 col-md-12'
                                style={{opacity: isSubmitLoading ? 0 : 1}}
                            >
                                <div className={styles.pagination}>
                                    <ReactPaginate
                                        forcePage={parseInt(currentPage) - 1}
                                        initialPage={parseInt(currentPage) - 1}
                                        activeClassName={styles.activeLink}
                                        activeLinkClassName={styles.activeLink}
                                        breakClassName={''}
                                        breakLabel='...'
                                        breakLinkClassName={''}
                                        containerClassName={''}
                                        disabledClassName={''}
                                        marginPagesDisplayed={1}
                                        nextClassName={''}
                                        nextLabel='Next'
                                        nextLinkClassName={styles.nextLink}
                                        onPageChange={hanleOnPageChange}
                                        pageClassName={styles.page}
                                        pageCount={totalPage}
                                        pageLinkClassName={styles.pageLink}
                                        pageRangeDisplayed={5}
                                        previousClassName={styles.previous}
                                        previousLabel='Prev'
                                        previousLinkClassName={styles.previousLink}
                                        subContainerClassName='pages pagination'
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
            {/* <Facility /> */}
        </>
    );
};
