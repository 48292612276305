import React, {Component} from "react";
import Facility from "../../components/Common/Facility";
import Breadcrumb from "../../components/Common/Breadcrumb";
import BlogGrid from "../../components/blog/BlogGrid";
import Meta from "../../components/meta";

class Blog extends Component {
  render() {

    return (
      <>
          <Meta
              title={"Blog"}
              description={""}
              keywords={""}
          />
        <Breadcrumb title="Blog" />

        <BlogGrid />

        <Facility />
      </>
    );
  }
}

export default Blog;
