import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { toastAlert } from '../../utils/toastify';
import { renderHtmlContent } from '../../utils/html';

import { GET_SLIDER_GQL } from '../../graphql/layout.gql';
import OwlCarousel from 'react-owl-carousel3';

const options = {
  items: 1,
  loop: true,
  nav: false,
  dots: true,
  animateOut: 'slideOutDown',
  animateIn: 'slideInDown',
  smartSpeed: 750,
  autoplay: true,
  autoplayHoverPause: true,
  navText: [
    "<i class='fas fa-arrow-left'></i>",
    "<i class='fas fa-arrow-right'></i>",
  ],
};

function BannerSlider() {
  const [display, setDisplay] = useState(false);
  const [slider, setSlider] = useState(null);

  const init = () => {
    getSlider();
  };

  const [getSlider]: any = useLazyQuery(GET_SLIDER_GQL, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getSlider }) => {
      setSlider(getSlider.items);
      setDisplay(true);
    },
    onError: ({ message }) => toastAlert({ type: 'error', message }),
  });

  useEffect(() => {
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {display ? (
        <OwlCarousel
          className='home-slides-three owl-carousel owl-theme'
          {...options}
        >
          {!!slider &&
            !!slider.length &&
            slider.map((item) => renderHtmlContent({ content: item.html }))}
        </OwlCarousel>
      ) : (
        ''
      )}
    </>
  );
}

export default BannerSlider;
