import React from 'react';

/* Home */
import Home from '../containers/home';

/* Search */
import Search from '../containers/search';

/* Product */
import ProductDetail from '../containers/product/detail';
import Category from '../containers/product/category';

/* Collection*/
import Collection from '../containers/collection';

/* AUTH */
import SignIn from '../containers/auth/sign-in';
import SignUp from '../containers/auth/sign-up';
import ForgotPassword from '../containers/auth/forgot-password';
import ResetForgotPassword from '../containers/auth/reset-forgot-password';

import ConnectFacebook from '../containers/auth/connect-facebook';
import ConnectGoogle from '../containers/auth/connect-google';

/* User Profile */
import UserPanel from '../containers/user/panel';
import UserEditProfile from '../containers/user/edit-profile';
import UserChangePassword from '../containers/user/change-password';
import OrderList from '../containers/user/order-list';
import OrderDetail from '../containers/user/order-list/order-detail';
import AddressList from '../containers/user/address-book';
import CreateAddress from '../containers/user/address-book/create-form';
import EditAddress from '../containers/user/address-book/edit-address';

/* Checkout */
import Cart from '../containers/shop/cart';
import Checkout from '../containers/shop/checkout';
import OrderSuccess from '../containers/shop/success';
import ConnectPaypal from '../containers/shop/connect-paypal';
import Stripe from '../containers/shop/connect-stripe';

/* Static Page */
import About from '../containers/about';
import ContactUs from '../containers/contact-us';
import CustomerServices from '../containers/customer-services';
import Faq from '../containers/faq';
import SizingGuide from '../containers/size-guide';
import Gallery from '../containers/gallery';

/* Blog Page */
import Blog from '../containers/blog';
import BlogDetail from '../containers/blog/detail';

/*Compare */
import Compare from '../containers/compare';

/* Not Found */
import NotFound from '../containers/404';

/* Utilrs */
import { AuthenticatedRoute, UnAuthenticatedRoute } from './router-utils';

import * as ROUTINGS from './path';

const { Route, Switch, Redirect } = require('react-router-dom');

export const UserRouter = () => (
  <Switch>
    <Route
      exact
      path={ROUTINGS.ROUTING_USER}
      render={() => <Redirect to={ROUTINGS.ROUTING_USER_EDIT} />}
    />
    <AuthenticatedRoute
      exact
      path={ROUTINGS.ROUTING_USER_CHANGE_PASSWORD}
      component={UserChangePassword}
    />
    <AuthenticatedRoute
      exact
      path={ROUTINGS.ROUTING_USER_EDIT}
      component={UserEditProfile}
    />
    <AuthenticatedRoute
      exact
      path={ROUTINGS.ROUTING_ORDER_LIST}
      component={OrderList}
    />
    <AuthenticatedRoute
      path={ROUTINGS.ROUTING_ORDER_ID}
      component={OrderDetail}
    />
    <AuthenticatedRoute
      exact
      path={ROUTINGS.ROUTING_ADDRESS_LIST}
      component={AddressList}
    />
    <AuthenticatedRoute
      exact
      path={ROUTINGS.ROUTING_CREATE_ADDRESS}
      component={CreateAddress}
    />
    <AuthenticatedRoute
      path={ROUTINGS.ROUTING_EDIT_ADDRESS}
      component={EditAddress}
    />
  </Switch>
);

export default () => (
  <Switch>
    {/* Home */}
    <Route
      exact
      path={ROUTINGS.ROUTING_HOME}
      render={(routeProps: any) => <Home {...routeProps} />}
    />

    {/* Search */}
    <Route
      exact
      path={ROUTINGS.ROUTING_SEARCH}
      render={(routeProps: any) => <Search {...routeProps} />}
    />

    {/* Product */}
    <Route
      path={ROUTINGS.ROUTING_PRODUCT_DETAIL}
      render={(routeProps: any) => <ProductDetail {...routeProps} />}
    />

    {/* Category */}
    <Route
      exact
      path={ROUTINGS.ROUTING_PRODUCT_CATEGORY}
      render={(routeProps: any) => <Category {...routeProps} />}
    />

    {/* Auth */}
    <UnAuthenticatedRoute
      exact
      path={ROUTINGS.ROUTING_AUTH_SIGN_IN}
      component={SignIn}
    />
    <UnAuthenticatedRoute
      exact
      path={ROUTINGS.ROUTING_AUTH_SIGN_UP}
      component={SignUp}
    />
    <UnAuthenticatedRoute
      exact
      path={ROUTINGS.ROUTING_AUTH_FORGOT_PASSWORD}
      component={ForgotPassword}
    />
    <UnAuthenticatedRoute
      exact
      path={ROUTINGS.ROUTING_AUTH_RESET_FORGOT_PASSWORD}
      component={ResetForgotPassword}
    />
    <UnAuthenticatedRoute
      exact
      path={ROUTINGS.ROUTING_AUTH_CONNECT_FACEBOOK}
      component={ConnectFacebook}
    />
    <UnAuthenticatedRoute
      exact
      path={ROUTINGS.ROUTING_AUTH_CONNECT_GOOGLE}
      component={ConnectGoogle}
    />

    {/* User Panel Routing */}
    <AuthenticatedRoute path={ROUTINGS.ROUTING_USER} component={UserPanel} />

    {/* Checkout */}
    <Route
      path={ROUTINGS.ROUTING_CART}
      render={(routeProps: any) => <Cart {...routeProps} />}
    />
    <Route
      path={ROUTINGS.ROUTING_CHECKOUT}
      render={(routeProps: any) => <Checkout {...routeProps} />}
    />
    <Route
      path={ROUTINGS.ROUTING_CONNECT_STRIPE}
      render={(routeProps: any) => <Stripe {...routeProps} />}
    />
    <Route
      path={ROUTINGS.ROUTING_ORDER_SUCCESS}
      render={(routeProps: any) => <OrderSuccess {...routeProps} />}
    />
    <Route
      path={ROUTINGS.ROUTING_CONNECT_PAYPAL}
      render={(routeProps: any) => <ConnectPaypal {...routeProps} />}
    />
    <Route
      exact
      path={ROUTINGS.ROUTING_CANCEL_PAYPAL}
      render={() => <Redirect to={ROUTINGS.ROUTING_CHECKOUT} />}
    />

    {/* Blog Page */}
    <Route
      exact
      path={ROUTINGS.ROUTING_BLOG_PATH}
      render={(routeProps: any) => <Blog {...routeProps} />}
    />
    <Route
      path={ROUTINGS.ROUTING_BLOG_DETAIL}
      render={(routeProps: any) => <BlogDetail {...routeProps} />}
    />

    {/* Static Page */}
    <Route
      path={ROUTINGS.ROUTING_ABOUT_US}
      render={(routeProps: any) => <About {...routeProps} />}
    />
    <Route
      path={ROUTINGS.ROUTING_CONTACT_US}
      render={(routeProps: any) => <ContactUs {...routeProps} />}
    />
    <Route
      path={ROUTINGS.ROUTING_SIZE_GUIDE}
      render={(routeProps: any) => <SizingGuide {...routeProps} />}
    />
    <Route
      path={ROUTINGS.ROUTING_FAQ}
      render={(routeProps: any) => <Faq {...routeProps} />}
    />
    <Route
      path={ROUTINGS.ROUTING_CUSTOMER_SERVICE}
      render={(routeProps: any) => <CustomerServices {...routeProps} />}
    />

    <Route
      path={ROUTINGS.ROUTING_GALLERY}
      render={(routeProps: any) => <Gallery {...routeProps} />}
    />
    <Route
      path={ROUTINGS.ROUTING_COLLECTION}
      render={(routeProps: any) => <Collection {...routeProps} />}
    />
    <Route
      path={ROUTINGS.ROUTING_COMPARE}
      render={(routeProps: any) => <Compare {...routeProps} />}
    />

    <Route
      path={ROUTINGS.ROUTING_404}
      render={(routeProps: any) => <NotFound {...routeProps} />}
    />
  </Switch>
);
