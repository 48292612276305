import {REDUCER_GROUP} from '../reducer.group';
import * as ACTION_TYPE from './type';

export const INITIAL_STATE = {
    info: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (
    state = INITIAL_STATE,
    action = {
        type: '',
        payload: {data: {group_id: "0"}},
        meta: {},
        group: '',
    }
) => {
    if (action.group !== REDUCER_GROUP.USER) return state;

    switch (action.type) {
        case ACTION_TYPE.SAVE_USER_INFO:
            const {data} = action.payload;

            if (!localStorage.getItem('customer-group-id')
                || localStorage.getItem('customer-group-id') != data.group_id // eslint-disable-line
            ) {
                localStorage.setItem('customer-group-id', data.group_id);
            }

            return Object.assign({}, state, {
                info: data || null,
            });

        case ACTION_TYPE.CLEAR_USER_INFO:
            return INITIAL_STATE;

        default:
            return state;
    }
};