import * as ACTION_TYPE from './type';
import { REDUCER_GROUP } from '../reducer.group';

export const saveUerInfoAction = (data) => ({
  type: ACTION_TYPE.SAVE_USER_INFO,
  payload: { data },
  group: REDUCER_GROUP.USER,
});

export const clearUerInfoAction = () => ({
  type: ACTION_TYPE.CLEAR_USER_INFO,
  payload: {},
  group: REDUCER_GROUP.USER,
});
