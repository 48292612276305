export const QUERY_GET_MEGA_MENU_GQL = `
  query megamenu {
    megamenu {
      id
      name
      html
      data {
        index
        label
        url_key
        children {
          index
          label
          url_key
          children {
            index
            label
            url_key
          }
        }
      }
    }
  }
`;
