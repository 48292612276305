import React, { useEffect, useState } from 'react';
import {
  GET_POST_BY_SLUG_GQL,
  GET_COMMENTS_BY_POST_ID,
  ADD_NEW_COMMENT,
} from '../../../graphql/blog.gql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { toastAlert } from '../../../utils/toastify';

import { transformComment } from '../../../utils/comment.transform';
import Facility from '../../../components/Common/Facility';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import DetailsContentTwo from '../../../components/blog/DetailsContentTwo';
import Meta from "../../../components/meta";

export default (props: any) => {
  const {
    match: {
      params: { blogSlug },
    },
  } = props;

  const [post, setPost] = useState(null);
  const [comment, setComment] = useState([]);
  const [totalComment, setTotalComment] = useState(0);

  const init = () => {
    !!blogSlug &&
      getPostDetail({
        variables: { param: blogSlug },
      });
  };

  const [getPostDetail]: any = useLazyQuery(GET_POST_BY_SLUG_GQL, {
    onCompleted: ({ post }) => {
      setPost(post);

      const postId = post.post_id;
      getCommentByPostId({ variables: { postId } });
    },
    onError: ({ message }) => toastAlert({ type: 'error', message }),
  });

  const [getCommentByPostId]: any = useLazyQuery(GET_COMMENTS_BY_POST_ID, {
    fetchPolicy: 'network-only',
    onCompleted: ({ comments }) => {
      if (!comments || !comments.items || !comments.items.length) return;

      setTotalComment(comments.items.length || 0);
      setComment(transformComment(comments));
    },
    onError: ({ message }) => toastAlert({ type: 'error', message }),
  });

  useEffect(() => {
    init();
  }, [props.match.params.blogSlug]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (post) {
      setMeta({
        title: post.meta_title,
        description: post.meta_description,
        keyword: post.meta_tags
      });
    }
  }, [post]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnSubmit = ({ replyTo, message, name, email }) => {
    addNewCommentAction({
      variables: {
        replyTo,
        message,
        name,
        email,
        postId: post.post_id,
      },
    });
  };

  const [addNewCommentAction, { loading: isAddCommentLoading }]: any =
    useMutation(ADD_NEW_COMMENT, {
      onCompleted: (data) => {
        toastAlert({ type: 'success', message: 'Adding comment success' });
        getCommentByPostId({ variables: { postId: post.post_id } });
      },
      onError: ({ message }) => {
        toastAlert({ type: 'error', message });
      },
    });

  const [meta, setMeta] = useState({
    title: "",
    description: "",
    keyword: ""
  });

  return (
    <>
      <Meta
          title={meta.title}
          description={meta.description}
          keywords={meta.keyword}
      />
      {!!post && <Breadcrumb title={post.title} />}

      <DetailsContentTwo
        post={post}
        comments={comment}
        totalComment={totalComment}
        onSubmit={handleOnSubmit}
        isAddCommentLoading={isAddCommentLoading}
      />

      <Facility />
    </>
  );
};
