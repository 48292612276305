import React, {useEffect, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {toastAlert} from '../../utils/toastify';
import {renderHtmlContent} from '../../utils/html';

import {GET_TESTIMONIALS_SLIDER_GQL} from '../../graphql/layout.gql';

import OwlCarousel from 'react-owl-carousel3';

const options = {
    loop: true,
    nav: false,
    dots: true,
    autoplayHoverPause: true,
    items: 1,
    autoplay: true,
    navText: [
        "<i class='fas fa-chevron-left'></i>",
        "<i class='fas fa-chevron-right'></i>",
    ],
};

const TestimonialsTwo = () => {
    const [display, setDisplay] = useState(false);
    const [slider, setSlider] = useState(null);

    const init = () => {
        getSlider();
    };

    const [getSlider]: any = useLazyQuery(GET_TESTIMONIALS_SLIDER_GQL, {
        fetchPolicy: 'network-only',
        onCompleted: ({getSlider}) => {
            setSlider(getSlider.items);
            setDisplay(true);
        },
        onError: ({message}) => toastAlert({type: 'error', message}),
    });

    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='testimonials-area ptb-60 bg2'>
            <div className='container'>
                {display ? (
                    <OwlCarousel
                        className='testimonials-slides owl-carousel owl-theme'
                        {...options}
                    >
                        {!!slider &&
                            !!slider.length &&
                            slider.map((item) => renderHtmlContent({content: item.html}))}
                    </OwlCarousel>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

export default TestimonialsTwo;
