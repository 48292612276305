export const generateSortList = (sortField) =>
  sortField
    .map((item) => {
      return [
        {
          title: `${item.label} Ascending`,
          key: item.value,
          value: 'ASC',
          combineValue: `${item.value}-ASC`,
        },
        {
          title: `${item.label} Descending`,
          key: item.value,
          value: 'DESC',
          combineValue: `${item.value}-DESC`,
        },
      ];
    })
    .flat();

export const createSortObject = (sort) => {
  const splitter = sort.split('-');
  if (!splitter || splitter.length !== 2) return {};

  return {
    [splitter[0]]: splitter[1],
  };
};

export const validSortValue = (sort) => {
  const splitter = sort.split('-');
  if (!splitter || splitter.length !== 2) return false;

  return splitter[1] === "ASC" || splitter[1] === "DESC";
};
