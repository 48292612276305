import Swal from 'sweetalert2';
import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {toastAlert} from '../../../../utils/toastify';
import {checkEmptryString} from '../../../../utils/validation';
import {useMutation, useLazyQuery} from '@apollo/client';
import AvailableCountriesOptions from "../../../../components/form/AvailableCountriesOptions";

import {
    GET_LIST_ADDRESS_GQL,
    UPDATE_CUSTOMER_ADDRESS_SQL,
    DELETE_CUSTOMER_ADDRESS_SQL
} from '../../../../graphql/customer.gql';

import * as ROUTINGS from '../../../../routings/path';

const InputGroup = (
    {
        name,
        isRequired = true,
        label,
        inputType = 'text',
        requiredLabel = true,
        initValue,
        msg,
        validation,
        onUpdate,
        col = 6,
    }) => {
    const [value, setValue] = useState(initValue || '');
    const [isDirty, setDirty] = useState(false);
    const [isValid, setValid] = useState(true);

    const onChange = (e) => {
        !isDirty && setDirty(true);
        setValue(e.target.value);
    };

    const onKeyUp = () => {
        validate();
    };

    const validate = () => {
        let valid = true;
        validation.map((item) => {
            switch (item) {
                case 'required':
                    if (checkEmptryString(value)) valid = false;
                    break;

                case 'phone':
                    if (!value.match(/^[+]?\d{9,12}$/g)) valid = false;
                    break;

                case 'zipcode':
                    valid = true;
                    break;
            }
            return true;
        });

        setValid(valid);
        onUpdate({value, valid});
    };

    return (
        <div className={`col-lg-${col} col-md-${col}`}>
            <div className='form-group'>
                <label>
                    {label} {!!requiredLabel && <span className='required'>*</span>}
                </label>
                <input
                    autoComplete='off'
                    type={inputType || "text"}
                    name={name}
                    required={isRequired}
                    className='form-control'
                    onKeyDown={(e) => {
                        if (validation.indexOf('zipcode') >= 0) {
                            if (
                                !(
                                    (e.keyCode >= 48 && e.keyCode <= 57) ||
                                    [46, 8, 9, 27, 13, 110].indexOf(e.keyCode) >= 0
                                )
                            )
                                e.preventDefault();
                        }
                    }}
                    onKeyUp={onKeyUp}
                    onChange={onChange}
                    value={value}
                />
                {!!isDirty && !isValid && (
                    <p style={{color: 'red', fontSize: '13px'}}>{msg}</p>
                )}
            </div>
        </div>
    );
};

const EditAddress = ({customerAddress, addressID}) => {
    const history = useHistory();
    const [countryCode, setCountryCode] = useState(customerAddress.country_code);
    const handleOnChangeCountryCode = (e) => {
        setCountryCode(e.target.value);
    };

    const [city, setCity] = useState(customerAddress.city);
    const handleOnChangeCity = ({value}) => {
        setCity(value);
    };

    const [firstname, setFirstname] = useState(customerAddress.firstname);
    const handleOnChangeFirstname = ({value}) => {
        setFirstname(value);
    };

    const [lastname, setLastname] = useState(customerAddress.lastname);
    const handleOnChangeLastname = ({value}) => {
        setLastname(value);
    };

    const [company, setCompany] = useState(customerAddress.company);
    const handleOnChangeCompany = ({value}) => {
        setCompany(value);
    };

    const [street, setStreet] = useState(customerAddress.street);
    const handleOnChangeStreet = ({value}) => {
        setStreet(value);
    };

    const [postcode, setPostcode] = useState(customerAddress.postcode);
    const handleOnChangePostcode = ({value}) => {
        setPostcode(value);
    };

    const [phonenumber, setPhonenumber] = useState(customerAddress.telephone);
    const handleOnChangePhonenumber = ({value}) => {
        setPhonenumber(value);
    };

    //Check Value Input
    const checkValidShippingAddress = () => {
        if (!countryCode || !countryCode.length) return false;
        if (!firstname || !firstname.length) return false;
        if (!lastname || !lastname.length) return false;
        if (!street || !street.length) return false;
        if (!city || !city.length) return false;
        if (!phonenumber || !phonenumber.length) return false;
        if (!phonenumber.match(/^[+]?\d{9,12}$/g)) return false;
        return true;
    };

    const [updateCustomerAddress, {loading: isSaveLoading}]: any = useMutation(
        UPDATE_CUSTOMER_ADDRESS_SQL,
        {
            onCompleted: () => {
                toastAlert({
                    type: 'success',
                    message: 'Update Address successful',
                });
                setTimeout(() => {
                    history.push(`${ROUTINGS.ROUTING_ADDRESS_LIST}`);
                }, 1000);
            },
            onError: ({message}) => {
                toastAlert({type: 'error', message});
            },
        }
    );

    const [deleteCustomerAddress, {loading: isDeleteLoading}]: any =
        useMutation(DELETE_CUSTOMER_ADDRESS_SQL, {
            onCompleted: () => {
                toastAlert({
                    type: 'success',
                    message: 'Delete Address successful',
                });
                setTimeout(() => {
                    history.push(`${ROUTINGS.ROUTING_ADDRESS_LIST}`);
                }, 1000);
            },
            onError: ({message}) => {
                toastAlert({type: 'error', message});
                setTimeout(() => {
                    history.push(`${ROUTINGS.ROUTING_ADDRESS_LIST}`);
                }, 1000);
            },
        });

    const handleClickSave = (e) => {
        e.preventDefault();
        const check = checkValidShippingAddress();
        if (!check) {
            toastAlert({
                type: 'error',
                message: 'Error: Data is invalid',
            });
            return;
        }

        updateCustomerAddress({
            variables: {
                id: addressID,
                countryCode,
                firstname,
                lastname,
                company: company || "",
                street,
                city,
                postcode,
                phonenumber,
                region: city,
            },
        });
    };
    const handleClickDelete = (e) => {
        e.preventDefault();
        Swal.fire({
            title: 'Do you want to delete this address?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCustomerAddress({
                    variables: {id: addressID},
                });
            }
        });
    };

    useEffect(() => {
        //trigger countryCode after load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AvailableCountriesOptions()]);

    return (
        <form>
            <div className='billing-details'>
                <div className='row'>
                    <div className='col-lg-12 col-md-12'>
                        <div className='form-group'>
                            <label>
                                Country <span className='required'>*</span>
                            </label>
                            <div className='select-box'>
                                <select
                                    className='form-control'
                                    name='country'
                                    required
                                    value={countryCode}
                                    onChange={handleOnChangeCountryCode}
                                >
                                    <AvailableCountriesOptions/>
                                </select>
                            </div>
                        </div>
                    </div>
                    <InputGroup
                        name={'firstName'}
                        label={'First Name'}
                        initValue={firstname}
                        msg={'Invalid first name format'}
                        validation={['required']}
                        onUpdate={handleOnChangeFirstname}
                    />

                    <InputGroup
                        name={'lastName'}
                        label={'Last Name'}
                        initValue={lastname}
                        msg={'Invalid last name format'}
                        validation={['required']}
                        onUpdate={handleOnChangeLastname}
                    />

                    <InputGroup
                        name={'company'}
                        isRequired={false}
                        label={'Company Name'}
                        requiredLabel={false}
                        initValue={company}
                        msg={''}
                        col={12}
                        validation={[]}
                        onUpdate={handleOnChangeCompany}
                    />

                    <InputGroup
                        name={'address'}
                        label={'Address'}
                        initValue={street}
                        col={12}
                        msg={'This is required field'}
                        validation={['required']}
                        onUpdate={handleOnChangeStreet}
                    />

                    <InputGroup
                        name={'city'}
                        label={'Town / City'}
                        initValue={city}
                        msg={'This is required field'}
                        validation={['required']}
                        onUpdate={handleOnChangeCity}
                    />

                    <InputGroup
                        name={'postcode'}
                        label={'Postcode / Zip'}
                        initValue={postcode}
                        msg={''}
                        validation={['required']}
                        onUpdate={handleOnChangePostcode}
                    />

                    <InputGroup
                        name={'phone'}
                        label={'Phone'}
                        inputType={'tel'}
                        initValue={phonenumber}
                        msg={'Invalid phone number format, use like +2923432432432'}
                        validation={['required', 'phone']}
                        onUpdate={handleOnChangePhonenumber}
                    />
                </div>
                <div className='row'>
                    <div
                        className={`col-lg-6 col-md-6`}
                        style={{alignSelf: 'flex-end'}}
                    >
                        <div className='form-group'>
                            <button
                                type='submit'
                                style={{
                                    width: '100%',
                                    color: '#000',
                                    fontSize: 17,
                                    border: '1px solid #000',
                                    backgroundColor: '#FFF',
                                }}
                                className='form-control btn-submit'
                                onClick={handleClickDelete}
                            >
                                {!isDeleteLoading ? (
                                    'DELETE'
                                ) : (
                                    <span className='spinner-grow spinner-grow-sm'/>
                                )}
                            </button>
                        </div>
                    </div>
                    <div
                        className={`col-lg-6 col-md-6`}
                        style={{alignSelf: 'flex-end'}}
                    >
                        <div className='form-group'>
                            <button
                                type='submit'
                                style={{
                                    width: '100%',
                                    color: '#fff',
                                    fontSize: 17,
                                    backgroundColor: '#000',
                                }}
                                onClick={handleClickSave}
                                className='form-control btn-submit'
                            >
                                {!isSaveLoading ? (
                                    'SAVE'
                                ) : (
                                    <span className='spinner-grow spinner-grow-sm'/>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default (props: any) => {
    const {
        match: {
            params: {addressID},
        },
    } = props;

    const init = () => {
        getListAddress();
    };

    const [customerAddresses, setcustomerAddresses] = useState(null);
    const [getListAddress]: any = useLazyQuery(
        GET_LIST_ADDRESS_GQL,
        {
            fetchPolicy: 'network-only',
            onCompleted: ({customer}) => {
                setcustomerAddresses(customer.addresses);
            },
            onError: ({message}) => toastAlert({type: 'error', message}),
        }
    );

    useEffect(() => {
        init();
    }, [props.match.params.addressID]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {!!customerAddresses &&
                customerAddresses
                    .filter((address) => address.id === parseInt(addressID))
                    .map((customerAddress, idx) => (
                        <React.Fragment key={idx}>
                            <EditAddress {...{customerAddress, addressID}} />
                        </React.Fragment>
                    ))}
        </>
    );
};
