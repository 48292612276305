import MetaTags from 'react-meta-tags';

export default ({ title, description, keywords }) => {
  let meta_title = title ? title : "Customize and Personalize Mug, Blanket, Clothing, Canvas - iGBMerch Official Website";
  let meta_description = description ? description : "At iGB Merch, you can make every occasion, from Christmas to Mother&#39;s day Father&#39;s day, special with a unique and thoughtful custom coffee mug, canvas print, fleece blanket, pillow... with unique personalize design";
  let meta_keyword = keywords ? keywords : "";

  return (
    <MetaTags>
      {<title>{meta_title}</title>}
      {<meta name='description' content={meta_description} />}
      {<meta name='keywords' content={meta_keyword} />}
    </MetaTags>
  );
};
