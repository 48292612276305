// import { saveAuthInfoAction } from '../../flows/auth/action';
import {
  saveCartTokenAction,
  clearCartAction,
} from '../../../flows/checkout/action';

export const mapStateToProps = (state) => ({
  checkoutState: state.checkout,
});

export const mapDispatchToProps = (dispatch) => ({
  clearCartAction: () => dispatch(clearCartAction()),
  saveCartTokenAction: (data) => dispatch(saveCartTokenAction(data)),
});
