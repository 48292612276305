import React, {useEffect, useState} from "react";
import {QUERY_GET_SIZE_GUIDE_AREA_GQL} from "../../graphql/layout.gql";
import {renderHtmlContent} from "../../utils/html";
import callGetApi, {toAstError} from "../../api";

const SizeGuide = (props) => {
    const {closeSizeGuide} = props;
    const [sizeguide, setSizeGuide] = useState(null);

    const init = () => {
        queryGetSizeGuide();
    };
  
    const queryGetSizeGuide = () => {
        callGetApi(QUERY_GET_SIZE_GUIDE_AREA_GQL).then((res: any) => {
            let cmsBlocks =
                !!res.data &&
                !!res.data.data &&
                !!res.data.data.cmsBlocks && res.data.data.cmsBlocks;

            if (cmsBlocks) {
                setSizeGuide(cmsBlocks.items);
            }

            if (res.data.errors) {
                let errors = res.data.errors;
                toAstError(errors);
            }
        });
    };

    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className="modal fade sizeGuideModal show"
            style={{paddingRight: "16px", display: "block", overflow: "scroll"}}
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <button
                        type="button"
                        onClick={closeSizeGuide}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
            <span aria-hidden="true">
              <i className="fas fa-times"></i>
            </span>
                    </button>

                    <div className="modal-sizeguide">
                        <h3>Size Guide</h3>
                        <p>
                            This is an approximate conversion table to help you find your
                            size.
                        </p>
                        {!!sizeguide &&
                            !!sizeguide.length &&
                            sizeguide.map((item) => {
                                return renderHtmlContent({content: item.content});
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SizeGuide;
