import React, {useState} from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {calculateProductItemPrice} from '../../../utils/cart';
import * as ROUTINGS from '../../../routings/path';

import {mapStateToProps, mapDispatchToProps} from './state';

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(({checkoutState, onClick}: any) => {
    const {
        cartInfo: {items: cartItems, prices},
    } = checkoutState;
    const subTotal =
        !!prices &&
        !!prices.subtotal_including_tax &&
        prices.subtotal_including_tax.value;
    const [display] = useState(false);

    const closeCart = () => {
        onClick(display);
    };

    return (
        <div
            className='modal right fade show shoppingCartModal'
            style={{
                display: 'block',
                paddingRight: '16px',
            }}
        >
            <div className='modal-dialog' role='document'>
                <div className='modal-content'>
                    <button
                        type='button'
                        className='close'
                        data-dismiss='modal'
                        aria-label='Close'
                        onClick={closeCart}
                    >
                        <span aria-hidden='true'>&times;</span>
                    </button>

                    {!!cartItems && !!cartItems.length && (
                        <div className='modal-body'>
                            <h3>My Cart ({cartItems.length})</h3>

                            <div className='product-cart-content'>
                                {cartItems.length > 0
                                    ? cartItems.map((item, idx) => {
                                        if (!item || !item.product) return null;
                                        const price = calculateProductItemPrice(item);

                                        return (
                                            <div className='product-cart' key={idx}>
                                                <div className='product-image'>
                                                    <img
                                                        src={
                                                            item.product.thumbnail &&
                                                            item.product.thumbnail.url
                                                        }
                                                        alt={
                                                            item.product.thumbnail &&
                                                            item.product.thumbnail.label
                                                        }
                                                    />
                                                </div>

                                                <div className='product-content'>
                                                    <h3>
                                                        <NavLink
                                                            to={`${ROUTINGS.ROUTING_PRODUCT_DETAIL_PATH}/${item.product.url_key}`}
                                                        >
                                                            {item.product.name}
                                                        </NavLink>
                                                    </h3>
                                                    {'OUT_OF_STOCK' === item.product.stock_status &&
                                                        <div style={{fontSize: 12, color: 'red', fontWeight: 500}}>
                                                            This product is out of stock.
                                                        </div>
                                                    }
                                                    <span>
                              {item.configurable_options &&
                                  item.configurable_options
                                      .map((config) => config.value_label)
                                      .join(' / ')}
                            </span>
                                                    <div className='product-price'>
                                                        <span>{item.quantity}</span>
                                                        <span>x</span>
                                                        <span className='price'>${price}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                    : 'Empty'}
                            </div>

                            <div className='product-cart-subtotal'>
                                <span>Subtotal</span>

                                <span className='subtotal'>{subTotal}</span>
                            </div>

                            <div className='product-cart-btn'>
                                <NavLink to={ROUTINGS.ROUTING_CHECKOUT} className={'btn btn-primary'}
                                         onClick={closeCart}>
                                    Proceed to Checkout
                                </NavLink>
                                <NavLink to={ROUTINGS.ROUTING_CART} className={'btn btn-light'} onClick={closeCart}>
                                    View Shopping Cart
                                </NavLink>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});
