import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {toastAlert} from '../../../../utils/toastify';
import {checkEmptryString} from '../../../../utils/validation';
import {useMutation} from '@apollo/client';
import AvailableCountriesOptions from "../../../../components/form/AvailableCountriesOptions";

import {CREATE_CUSTOMER_ADDRESS_SQL} from '../../../../graphql/customer.gql';

import * as ROUTINGS from '../../../../routings/path';
import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "../state";

const InputGroup = (
    {
        name,
        isRequired = true,
        label,
        inputType = 'text',
        requiredLabel = true,
        initValue,
        msg,
        validation,
        onUpdate,
        col = 6,
    }) => {
    const [value, setValue] = useState(initValue || '');
    const [isDirty, setDirty] = useState(false);
    const [isValid, setValid] = useState(true);

    const onChange = (e) => {
        !isDirty && setDirty(true);
        setValue(e.target.value);
    };

    const onKeyUp = () => {
        validate();
    };

    const validate = () => {
        let valid = true;
        validation.map((item) => {
            switch (item) {
                case 'required':
                    if (checkEmptryString(value)) valid = false;
                    break;

                case 'phone':
                    if (!value.match(/^[+]?\d{9,12}$/g)) valid = false;
                    break;

                case 'zipcode':
                    valid = true;
                    break;
            }

            return true;
        });

        setValid(valid);
        onUpdate({value, valid});
    };

    return (
        <div className={`col-lg-${col} col-md-${col}`}>
            <div className='form-group'>
                <label>
                    {label} {!!requiredLabel && <span className='required'>*</span>}
                </label>
                <input
                    autoComplete='off'
                    type={inputType || 'text'}
                    name={name}
                    required={isRequired}
                    className='form-control'
                    onKeyDown={(e) => {
                        if (validation.indexOf('zipcode') >= 0) {
                            if (
                                !(
                                    (e.keyCode >= 48 && e.keyCode <= 57) ||
                                    [46, 8, 9, 27, 13, 110].indexOf(e.keyCode) >= 0
                                )
                            )
                                e.preventDefault();
                        }
                    }}
                    onKeyUp={onKeyUp}
                    onChange={onChange}
                    value={value}
                />
                {!!isDirty && !isValid && (
                    <p style={{color: 'red', fontSize: '13px'}}>{msg}</p>
                )}
            </div>
        </div>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)((props: any) => {
    const history = useHistory();

    const [countryCode, setCountryCode] = useState(props.appState.default_country);
    const handleOnChangeCountryCode = (e) => {
        setCountryCode(e.target.value);
    };

    const [firstname, setFirstname] = useState('');
    const handleOnChangeFirstname = ({value}) => {
        setFirstname(value);
    };

    const [lastname, setLastname] = useState('');
    const handleOnChangeLastname = ({value}) => {
        setLastname(value);
    };

    const [company, setCompany] = useState('');
    const handleOnChangeCompany = ({value}) => {
        setCompany(value);
    };

    const [street, setStreet] = useState('');
    const handleOnChangeStreet = ({value}) => {
        setStreet(value);
    };

    const [city, setCity] = useState('');
    const handleOnChangeCity = ({value}) => {
        setCity(value);
    };

    const [postcode, setPostcode] = useState('');
    const handleOnChangePostcode = ({value}) => {
        setPostcode(value);
    };

    const [phonenumber, setPhonenumber] = useState('');
    const handleOnChangePhonenumber = ({value}) => {
        setPhonenumber(value);
    };

    //Check Value Input
    const checkValidShippingAddress = () => {
        if (!countryCode || !countryCode.length) return false;

        if (!firstname || !firstname.length) return false;
        if (!lastname || !lastname.length) return false;
        if (!street || !street.length) return false;
        if (!city || !city.length) return false;
        if (!phonenumber || !phonenumber.length) return false;
        if (!phonenumber.match(/^[+]?\d{9,12}$/g)) return false;

        if (checkEmptryString(firstname)) return false;
        if (checkEmptryString(lastname)) return false;
        if (checkEmptryString(street)) return false;
        if (checkEmptryString(city)) return false;

        if (checkEmptryString(postcode)) {
            toastAlert({
                type: 'error',
                message: 'Error: Postcode / zip is empty',
            });
            return false;
        }
        return true;
    };

    const [createCustomerAddress, {loading: isSubmitLoading}]: any =
        useMutation(CREATE_CUSTOMER_ADDRESS_SQL, {
            onCompleted: () => {
                toastAlert({
                    type: 'success',
                    message: 'Create New Address successful',
                });
                setTimeout(() => {
                    history.push(`${ROUTINGS.ROUTING_ADDRESS_LIST}`);
                }, 1000);
            },
            onError: ({message}) => {
                toastAlert({type: 'error', message});
            },
        });

    const handleOnSubmit = (e) => {
        e.preventDefault();
        const check = checkValidShippingAddress();
        if (!check) {
            toastAlert({
                type: 'error',
                message: 'Error: Data is invalid',
            });
            return;
        }

        createCustomerAddress({
            variables: {
                countryCode,
                firstname,
                lastname,
                company,
                street,
                city,
                postcode,
                phonenumber,
                region: city,
            },
        });
    };

    useEffect(() => {
        //trigger countryCode after load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AvailableCountriesOptions()]);

    return (
        <form onSubmit={handleOnSubmit}>
            <div className='billing-details'>
                <div className='row'>
                    <div className='col-lg-12 col-md-12'>
                        <div className='form-group'>
                            <label>
                                Country <span className='required'>*</span>
                            </label>
                            <div className='select-box'>
                                <select
                                    className='form-control'
                                    name='country'
                                    required
                                    value={countryCode}
                                    onChange={handleOnChangeCountryCode}
                                >
                                    <AvailableCountriesOptions/>
                                </select>
                            </div>
                        </div>
                    </div>
                    <InputGroup
                        name={'firstName'}
                        label={'First Name'}
                        initValue={firstname}
                        msg={'Invalid first name format'}
                        validation={['required']}
                        onUpdate={handleOnChangeFirstname}
                    />

                    <InputGroup
                        name={'lastName'}
                        label={'Last Name'}
                        initValue={lastname}
                        msg={'Invalid last name format'}
                        validation={['required']}
                        onUpdate={handleOnChangeLastname}
                    />

                    <InputGroup
                        name={'company'}
                        isRequired={false}
                        label={'Company Name'}
                        requiredLabel={false}
                        initValue={company}
                        msg={''}
                        col={12}
                        validation={[]}
                        onUpdate={handleOnChangeCompany}
                    />

                    <InputGroup
                        name={'address'}
                        label={'Address'}
                        initValue={street}
                        col={12}
                        msg={'This is required field'}
                        validation={['required']}
                        onUpdate={handleOnChangeStreet}
                    />

                    <InputGroup
                        name={'city'}
                        label={'Town / City'}
                        initValue={city}
                        msg={'This is required field'}
                        validation={['required']}
                        onUpdate={handleOnChangeCity}
                    />

                    <InputGroup
                        name={'postcode'}
                        label={'Postcode / Zip'}
                        initValue={postcode}
                        requiredLabel={false}
                        msg={''}
                        validation={['required']}
                        onUpdate={handleOnChangePostcode}
                    />

                    <InputGroup
                        name={'phone'}
                        label={'Phone'}
                        inputType={'tel'}
                        initValue={phonenumber}
                        msg={'Invalid phone number format, use like +2923432432432'}
                        validation={['required', 'phone']}
                        onUpdate={handleOnChangePhonenumber}
                    />
                </div>
                <div className='row'>
                    <div
                        className={`col-lg-6 col-md-6`}
                        style={{alignSelf: 'flex-end'}}
                    >
                        <div className='form-group'>
                            <button
                                type='submit'
                                style={{
                                    width: '100%',
                                    color: '#fff',
                                    fontSize: 17,
                                    backgroundColor: '#000',
                                }}
                                className='form-control btn-submit'
                            >
                                {!isSubmitLoading ? (
                                    'CREATE'
                                ) : (
                                    <span className='spinner-grow spinner-grow-sm'/>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
});
