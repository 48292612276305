import { PENDING_TYPE, FULFILLED_TYPE, REJECTED_TYPE } from '../flows/action.config';
import { isPromise } from '../utils/validation';

const Promise = ({ dispatch }) => next => action => {
  if (!isPromise(action.payload)) return next(action);

  const { type, payload = {}, meta = {}, group = '' } = action;
  const { promise } = payload;

  const PENDING = PENDING_TYPE(type);
  const FULFILLED = FULFILLED_TYPE(type);
  const REJECTED = REJECTED_TYPE(type);

  dispatch({ type: PENDING, payload: {}, meta, group });

  let syncActivityFinished = false;
  let actionQueue = [];

  const flushQueue = () => {
    actionQueue.forEach(_ => dispatch(_));
    actionQueue = [];
  };

  const asyncDispatch = asyncAction => {
    const asyncActionArray: any = [asyncAction];
    actionQueue = actionQueue.concat(asyncActionArray);
    if (syncActivityFinished) flushQueue();
  };

  return promise.then(
      ({ result }) => {
        'function' === typeof action.onSuccess && action.onSuccess();
        dispatch({ type: FULFILLED, payload: result, meta, group, asyncDispatch });
        syncActivityFinished = true;
        flushQueue();
      },
      ({ error }) => {
        'function' === typeof action.onReject && action.onReject();
        dispatch({ type: REJECTED, payload: error, meta, group, asyncDispatch });
        syncActivityFinished = true;
        flushQueue();
      }
  );
}

export default Promise;