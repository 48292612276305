import React, {useEffect, useState} from 'react';
import {
    GET_POST_GRID_GQL,
    GET_POST_RECENT_GQL,
    GET_BLOG_CATEGORIES_GQL,
    GET_All_TAGS_GQL,
} from '../../graphql/blog.gql';
import {useLazyQuery} from '@apollo/client';
import {toastAlert} from '../../utils/toastify';
import {NavLink} from 'react-router-dom';

export default () => {
    const [popularPosts, setPopularPosts] = useState(null);
    const [recentPosts, setRecentPosts] = useState(null);
    const [blogCategories, setBlogCategories] = useState(null);
    const [allTags, setAllTags] = useState(null);

    const init = () => {
        getPopularPost({variables: {page: 1}});
        getRecentPost({
            variables: {page: 1},
        });
        getBlogCategories();
        getAllTags();
    };

    const [getPopularPost]: any = useLazyQuery(GET_POST_GRID_GQL, {
        fetchPolicy: 'network-only',
        onCompleted: ({posts}) => {
            setPopularPosts(posts.items);
        },
        onError: ({message}) => toastAlert({type: 'error', message}),
    });

    const [getRecentPost]: any = useLazyQuery(GET_POST_RECENT_GQL, {
        fetchPolicy: 'network-only',
        onCompleted: ({posts}) => {
            setRecentPosts(posts.items);
        },
        onError: ({message}) => toastAlert({type: 'error', message}),
    });

    const [getBlogCategories]: any = useLazyQuery(GET_BLOG_CATEGORIES_GQL, {
        fetchPolicy: 'network-only',
        onCompleted: ({blog_categories}) => {
            setBlogCategories(blog_categories.children);
        },
        onError: ({message}) => toastAlert({type: 'error', message}),
    });

    const [getAllTags]: any = useLazyQuery(GET_All_TAGS_GQL, {
        fetchPolicy: 'network-only',
        onCompleted: ({tags}) => {
            setAllTags(tags.items);
        },
        onError: ({message}) => toastAlert({type: 'error', message}),
    });

    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <aside className='widget-area' id='secondary'>
            <section className='widget widget_comero_posts_thumb'>
                <h3 className='widget-title'>Popular Posts</h3>
                {!!popularPosts &&
                    !!popularPosts.length &&
                    popularPosts
                        .filter((post, index) => index < 3)
                        .map((item, index) => {
                            return (
                                <article className='item' key={`popular-${index}`}>
                                    <NavLink to={item.url_key} className='thumb'>
                                        <img
                                            style={{objectFit: 'cover'}}
                                            className='fullimage cover'
                                            src={item.post_thumbnail}
                                            alt=''
                                            role='img'
                                        ></img>
                                    </NavLink>

                                    <div className='info'>
                                        <time>{item.published_at}</time>
                                        <h4 className='title usmall'>
                                            <NavLink to={item.url_key}>{item.title}</NavLink>
                                        </h4>
                                    </div>

                                    <div className='clear'></div>
                                </article>
                            );
                        })}
            </section>

            <section className='widget widget_recent_entries'>
                <h3 className='widget-title'>Recent Posts</h3>

                <ul>
                    {!!recentPosts &&
                        !!recentPosts.length &&
                        recentPosts
                            .filter((post, index) => index < 5)
                            .map((item, index) => {
                                return (
                                    <li key={`recent-${index}`}>
                                        <NavLink to={item.url_key}>{item.title}</NavLink>
                                    </li>
                                );
                            })}
                </ul>
            </section>

            <section className='widget widget_categories'>
                <h3 className='widget-title'>Categories</h3>

                <ul>
                    {!!blogCategories &&
                        !!blogCategories.length &&
                        blogCategories.map((item, index) => {
                            return (
                                <li key={`cat-${index}`}>
                                    <NavLink to='#'>{item.name}</NavLink>
                                </li>
                            );
                        })}
                </ul>
            </section>

            <section className='widget widget_tag_cloud'>
                <h3 className='widget-title'>Tags</h3>

                <div className='tagcloud'>
                    {!!allTags &&
                        !!allTags.length &&
                        allTags.map((item, index) => {
                            return (
                                <NavLink to='#' key={`tag-${index}`}>
                                    {item.name} <span className='tag-link-count'>(3)</span>
                                </NavLink>
                            );
                        })}
                </div>
            </section>
        </aside>
    );
};
