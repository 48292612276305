import React, {useState} from "react";

import Breadcrumb from "../../components/Common/Breadcrumb";
import ProductsCollections from "../../components/collections-styles/ProductsCollections";
import Facility from "../../components/Common/Facility";
import Meta from "../../components/meta";

export default () => {

    const [meta] = useState({
        title: "Collection page",
        description: "",
        keyword: ""
    });

  return (
    <React.Fragment>
        <Meta
            title={meta.title}
            description={meta.description}
            keywords={meta.keyword}
        />
      <Breadcrumb title="Collections Type 1" />
      <ProductsCollections />
      <Facility />
    </React.Fragment>
  );
};
