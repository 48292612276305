import {combineReducers} from '@reduxjs/toolkit';

import app from './app/reducer';
import auth from './auth/reducer';
import user from './user/reducer';
import checkout from './checkout/reducer';
import wishlist from './wishlist/reducer';
import compare from './compare/reducer';

const createRootReducer = (history: any) => {
  const appReducer: any = combineReducers({
    app,
    auth,
    user,
    checkout,
    wishlist,
    compare,
  } as any);

  return (state, action) => appReducer(state, action);
};

export default createRootReducer;
