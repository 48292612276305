import React, {useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import {QUERY_GET_COLLECTION_LISTS_GQL} from '../../graphql/layout.gql';

import * as ROUTINGS from '../../routings/path';
import {transformCollection} from '../../utils/collection.transform';
import callGetApi, {toAstError} from "../../api";

const ProductsCollections = () => {
    const [collectionList, setCollectionList] = useState(null);
    const init = () => {
        queryGetCollectionList();
    };

    const queryGetCollectionList = () => {
        callGetApi(QUERY_GET_COLLECTION_LISTS_GQL).then((res: any) => {
            let categories =
                !!res.data &&
                !!res.data.data &&
                !!res.data.data.categories && res.data.data.categories;

            if (categories) {
                setCollectionList(transformCollection(categories.items[0].children));
            }

            if (res.data.errors) {
                let errors = res.data.errors;
                toAstError(errors);
            }
        });
    };

    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const imageSrc = "/category_placeholder.png";

    return (
        <section className='products-collections-area ptb-60'>
            <div className='container'>
                <div className='section-title'>
                    <h2>
                        <span className='dot'></span> Collections
                    </h2>
                </div>

                <div className='row'>
                    {!!collectionList &&
                        collectionList.map((data, idx) => {
                            return (
                                <div className='col-lg-4 col-md-6' key={`collections-container-${idx}`}>
                                    <div className='collections-box'>
                                        <img src={(!!data.image && data.image) || imageSrc} alt=''/>

                                        <div className='category'>
                                            <h4>{data.name}</h4>
                                            <span>{data.product_count}</span>
                                        </div>

                                        <NavLink
                                            to={`${ROUTINGS.ROUTING_PRODUCT_CATEGORY_PATH}/${data.url_key}`}
                                        >
                                        </NavLink>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </section>
    );
};

export default ProductsCollections;
