import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import {NavLink} from 'react-router-dom';
import LeftFilter from '../Modal/LeftFilter';
import {
    PAGE_SIZE_DEFAULT,
    PAGE_SIZE_LIST,
    SORT_FIELD_DEFAULT,
} from '../../constants/product';
import {generateSortList} from '../../utils/product';

import styles from './ProductsFilterOptions.module.scss';

const ProductsFilterOptions = (props: any) => {
    const {
        onClick,
        pageInfo = null,
        totalCount = 0,
        pageSize = PAGE_SIZE_DEFAULT,
        onChangePageSize,
        onChangeSort,
        onChangeFilter,
        onRemoveFilter,
        sort = SORT_FIELD_DEFAULT,
        sortField = [],
        filter = [],
        combineFilter = [],
        gridType = 3
    } = props;
    const [display, setDisplay] = useState(false);

    const handleGrid = (gridType, classValue) => onClick(gridType, classValue);

    const handleLeftFilter = () => {
        setDisplay(!display);
    };

    const handleSelectFilter = (type, value) => {
        setDisplay(false);
        onChangeFilter(type, value);
    };

    const handleRemoveFilter = (filters) => {
        setDisplay(false);
        onRemoveFilter(filters);
    };

    const sortList = generateSortList(sortField);

    let numberOfDisplay = pageInfo && pageInfo.page_size;
    if (pageInfo) {
        if (pageInfo.page_size * pageInfo.current_page > totalCount) {
            numberOfDisplay =
                totalCount - pageInfo.page_size * (pageInfo.current_page - 1);
        }
    }

    useEffect(() => {
        switch (gridType) {
            case 1:
                handleGrid(gridType, 'products-col-two');
                break;
            case 2:
                handleGrid(gridType, 'products-col-three');
                break;
            case 3:
                handleGrid(gridType, 'products-col-four');
                break;
            case 4:
                handleGrid(gridType, 'products-row-view');
                break;
        }
    }, [gridType]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className='products-filter-options'>
                <div className='row align-items-center'>
                    <div className='col d-flex'>
                        <span>
                            <NavLink
                                to='#'
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleLeftFilter();
                                }}
                            >
                            <i className='fas fa-filter'></i> Filter
                            </NavLink>
                        </span>
                        <span>View:</span>
                        <div className='view-list-row'>
                            <div className={classnames('view-column', styles.girdViewList)}>
                                <NavLink
                                    to='#'
                                    className={classnames(
                                        'icon-view-two',
                                        styles.girdViewItem,
                                        gridType === 1 && styles.selected
                                    )}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleGrid(1, 'products-col-two');
                                    }}
                                >
                                    <span></span>
                                    <span></span>
                                </NavLink>

                                <NavLink
                                    to='#'
                                    className={classnames(
                                        'icon-view-three',
                                        styles.girdViewItem,
                                        gridType === 2 && styles.selected
                                    )}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleGrid(2, 'products-col-three');
                                    }}
                                >
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </NavLink>

                                <NavLink
                                    to='#'
                                    className={classnames(
                                        'icon-view-four',
                                        styles.girdViewItem,
                                        gridType === 3 && styles.selected
                                    )}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleGrid(3, 'products-col-four');
                                    }}
                                >
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </NavLink>

                                <NavLink
                                    to='#'
                                    className={classnames(
                                        'view-grid-switch',
                                        styles.girdViewItemSwitch,
                                        gridType === 4 && styles.selected
                                    )}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleGrid(4, 'products-row-view');
                                    }}
                                >
                                    <span></span>
                                    <span></span>
                                </NavLink>
                            </div>
                        </div>
                    </div>

                    <div className='col d-flex justify-content-center'>
                        <p>{`Showing ${numberOfDisplay} of ${totalCount} results`}</p>
                    </div>

                    <div className='col d-flex'>
                        <span>Show:</span>

                        <div className='show-products-number'>
                            <select onChange={onChangePageSize} defaultValue={pageSize}>
                                {PAGE_SIZE_LIST.map((item, idx) => (
                                    <option key={idx} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {!!sortField && !!sortField.length && (
                            <>
                                <span>Sort:</span>

                                <div className='products-ordering-list'>
                                    <select onChange={(e) => {
                                        onChangeSort(e, gridType)
                                    }} defaultValue={sort}>
                                        {sortList.map((item, idx) => (
                                            <option
                                                key={idx}
                                                value={item.combineValue}
                                            >
                                                {item.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {!!display ? (
                <LeftFilter
                    filter={filter}
                    combineFilter={combineFilter}
                    onClick={handleLeftFilter}
                    onSelectFilter={handleSelectFilter}
                    onRemoveFilter={handleRemoveFilter}
                />
            ) : (
                ''
            )}
        </>
    );
};

export default ProductsFilterOptions;
