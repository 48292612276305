import React, {useEffect, useState} from 'react';
import jQuery from 'jquery';
import {QUERY_GET_FAQ_AREA_GQL} from '../../graphql/layout.gql';
import {renderHtmlContent} from '../../utils/html';
import Facility from '../../components/Common/Facility';
import Breadcrumb from '../../components/Common/Breadcrumb';
import Meta from "../../components/meta";
import callGetApi, {toAstError} from "../../api";

export default () => {
    const [faqcontent, setFaqContent] = useState(null);

    const init = () => {
        queryGetFaqContent();
    };
  
    const queryGetFaqContent = () => {
        callGetApi(QUERY_GET_FAQ_AREA_GQL).then((res: any) => {
            let cmsBlocks =
                !!res.data &&
                !!res.data.data &&
                !!res.data.data.cmsBlocks && res.data.data.cmsBlocks;

            if (cmsBlocks) {
                setFaqContent(cmsBlocks.items);
                initAccordion();
            }

            if (res.data.errors) {
                let errors = res.data.errors;
                toAstError(errors);
            }
        });
    };

    const initAccordion = () => {
        setTimeout(() => {
            jQuery('.accordion .accordion__item').click(function () {
                jQuery('.accordion .accordion__item .accordion__panel').attr(
                    'hidden',
                    'true'
                );
                jQuery(this).find('.accordion__panel').removeAttr('hidden');
            });
        }, 100);
    };

    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [meta] = useState({
        title: "FAQs - iGBMerch Official Website",
        description: "- What payment methods do you accept?We accept PayPal and all major credit/debit cards- What should I do if I made a mistake on my order?Immediately contact us via support@igbmerch.com and our team will gladly resolve all of your concerns!- How can I change my order?Send us an email to support@igbmerch.com and let us k",
        keyword: ""
    });

    return (
        <>
            <Meta
                title={meta.title}
                description={meta.description}
                keywords={meta.keyword}
            />
            <Breadcrumb title='FAQs'/>

            <section className='faq-area ptb-60'>
                <div className='container'>
                    <div className='section-title'>
                        <h2>
                            <span className='dot'></span> Frequently Asked Questions
                        </h2>
                    </div>

                    {!!faqcontent &&
                        !!faqcontent.length &&
                        faqcontent.map((item) => {
                            return renderHtmlContent({content: item.content});
                        })}
                </div>
            </section>
            <Facility/>
        </>
    );
};
