import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { toastAlert } from '../../../../utils/toastify';
import { NavLink } from 'react-router-dom';
import * as ROUTINGS from '../../../../routings/path';

import {
  GET_CUSTOMER_ORDER_DETAIL_GQL,
} from '../../../../graphql/order.gql';

export default (props: any) => {
  const {
    match: {
      params: { orderID },
    },
  } = props;

  const [order, setOrder] = useState(null);

  const init = () => {
    !!orderID &&
      getOrderDetail({
        variables: { param: orderID },
      });
  };

  const [getOrderDetail]: any = useLazyQuery(
    GET_CUSTOMER_ORDER_DETAIL_GQL,
    {
      onCompleted: ({ customer }) => {
        setOrder(customer.orders.items[0]);
      },
      onError: ({ message }) => toastAlert({ type: 'error', message }),
    }
  );
  
  useEffect(() => {
    init();
  }, [props.match.params.orderID]);  // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <>
      {!!order && (
        <>
          <div style={{ display: 'flex' }}>
            <h3>ORDER #{order.number}</h3>
            <p
              style={{
                border: '1px solid',
                marginLeft: '10px',
                padding: '0 10px',
                height: 30,
                lineHeight: '30px',
              }}
            >
              {order.status}
            </p>
          </div>
          <p>{order.order_date}</p>
        </>
      )}
      <table className='table'>
        <thead>
          <tr>
            <th scope='col' style={{ textAlign: 'left' }}>
              Product Name
            </th>
            <th scope='col' style={{ textAlign: 'left' }}>
              SKU
            </th>
            <th scope='col' style={{ textAlign: 'right' }}>
              Price
            </th>
            <th scope='col' style={{ textAlign: 'right' }}>
              Qty
            </th>
            <th scope='col' style={{ textAlign: 'right' }}>
              Status
            </th>
            <th scope='col' style={{ textAlign: 'right' }}>
              Subtotal
            </th>
          </tr>
        </thead>
        <tbody>
          {!!order &&
            order.items.map((data, idx) => {
              if(!data) return null;

              let subTotal =
                data.quantity_ordered * data.product_sale_price.value;
              return (
                <tr key={idx} style={{ lineHeight: '50px' }}>
                  <td className='product-name' style={{ textAlign: 'left' }}>
                    <NavLink
                      style={{ color: '#000' }}
                      to={`${ROUTINGS.ROUTING_PRODUCT_DETAIL_PATH}/${data.product_url_key}`}
                    >
                      {data.product_name}
                    </NavLink>
                  </td>
                  <td style={{ textAlign: 'left' }}>{data.product_sku}</td>
                  <td style={{ textAlign: 'right' }}>
                    <b>${data.product_sale_price.value}</b>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {data.quantity_ordered}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {data.status}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <b>${subTotal}</b>
                  </td>
                </tr>
              );
            })}
          {!!order && (
            <>
              <tr style={{ borderStyle: 'hidden', background: '#cccccc59' }}>
                <td style={{ textAlign: 'end' }} colSpan={5}>
                  <p>Subtotal</p>
                </td>
                <td colSpan={1} style={{ textAlign: 'right' }}>
                  <p>${order.total.subtotal.value}</p>
                </td>
              </tr>
              <tr style={{ borderStyle: 'hidden', background: '#cccccc59' }}>
                <td style={{ textAlign: 'end' }} colSpan={5}>
                  <p>Shipping and Handling</p>
                </td>
                <td colSpan={1} style={{ textAlign: 'right' }}>
                  <p>${order.total.shipping_handling.total_amount.value}</p>
                </td>
              </tr>
              <tr style={{ borderStyle: 'hidden', background: '#cccccc59' }}>
                <td style={{ textAlign: 'end' }} colSpan={5}>
                  <p>Grand Total</p>
                </td>
                <td colSpan={1} style={{ textAlign: 'right' }}>
                  <b>${order.total.grand_total.value}</b>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      {!!order && (
        <>
          <h3
            style={{
              marginTop: '50px',
              borderBottom: '1px solid #ccc',
              paddingBottom: '25px',
            }}
          >
            Order Information
          </h3>
          <div
            style={{
              paddingTop: '15px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <h6>Shipping Address</h6>
              <p>
                {`${order.shipping_address.lastname} ${order.shipping_address.firstname}`}
                <br />
                {`${order.shipping_address.street[0]}`}
                <br />
                {`${order.shipping_address.city}, 
                 ${order.shipping_address.region}, 
                 ${order.shipping_address.postcode}`}
                <br />
                {`${order.shipping_address.country_code}`}
                <br />
                {`Tel: ${order.shipping_address.telephone}`}
              </p>
            </div>
            <div>
              <h6>Shipping Method</h6>
              <p>{order.shipping_method}</p>
            </div>
            <div>
              <h6>Billing Address</h6>
              <p>
                {`${order.billing_address.lastname} ${order.billing_address.firstname}`}
                <br />
                {`${order.billing_address.street[0]}`}
                <br />
                {`${order.billing_address.city}, 
                ${order.billing_address.region}, 
                ${order.billing_address.postcode}`}
                <br />
                {`${order.billing_address.country_code}`}
                <br />
                {`Tel: ${order.billing_address.telephone}`}
              </p>
            </div>
            <div>
              <h6>Payment Method</h6>
              <p>{order.payment_methods[0].name}</p>
            </div>
          </div>
        </>
      )}
    </>
  );
};
