import React, {useEffect, useState} from 'react';
import {QUERY_GET_ABOUT_AREA_GQL} from '../../graphql/layout.gql';
import {renderHtmlContent} from '../../utils/html';

import Facility from '../../components/Common/Facility';
import Breadcrumb from '../../components/Common/Breadcrumb';
import TestimonialsTwo from '../../components/Common/TestimonialsTwo';
import Meta from "../../components/meta";
import callGetApi, {toAstError} from "../../api";

export default () => {
    const [aboutContent, setAboutContent] = useState(null);
    const [meta] = useState({
        title: "About Us - iGBMerch Official Website",
        description: "About IGB Merch So glad you are here! We began as a professional and inspired design team in Vietnam. After 5 years working in digital printing industry, we decided to make our own way and created IGB Merch. Eventually, we have been working hard to reach our goals, but we keep going above and beyond to achieve further",
        keyword: ""
    });

    const init = () => {
        queryGetAboutContent();
    };

    const queryGetAboutContent = () => {
        callGetApi(QUERY_GET_ABOUT_AREA_GQL).then((res: any) => {
            let cmsBlocks =
                !!res.data &&
                !!res.data.data &&
                !!res.data.data.cmsBlocks &&
                res.data.data.cmsBlocks;

            if (cmsBlocks) {
                setAboutContent(cmsBlocks.items);
            }

            if (res.data.errors) {
                let errors = res.data.errors;
                toAstError(errors);
            }
        });
    };

    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <Meta
                title={meta.title}
                description={meta.description}
                keywords={meta.keyword}
            />
            <Breadcrumb title='About Us'/>

            {!!aboutContent &&
                !!aboutContent.length &&
                aboutContent.map((item) => {
                    return renderHtmlContent({content: item.content});
                })}

            <TestimonialsTwo/>
            <Facility/>
        </>
    );
};
