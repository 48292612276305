import React, {useEffect, useState} from "react";
import {QUERY_GET_SIZE_GUIDE_AREA_GQL} from "../../graphql/layout.gql";
import {renderHtmlContent} from "../../utils/html";
import Facility from "../../components/Common/Facility";
import Breadcrumb from "../../components/Common/Breadcrumb";
import Meta from "../../components/meta";
import callGetApi, {toAstError} from "../../api";

export default () => {
    const [sizeguide, setSizeGuide] = useState(null);

    const init = () => {
        queryGetSizeGuide();
    };
  
    const queryGetSizeGuide = () => {
        callGetApi(QUERY_GET_SIZE_GUIDE_AREA_GQL).then((res: any) => {
            let cmsBlocks =
                !!res.data &&
                !!res.data.data &&
                !!res.data.data.cmsBlocks && res.data.data.cmsBlocks;

            if (cmsBlocks) {
                setSizeGuide(cmsBlocks.items);
            }

            if (res.data.errors) {
                let errors = res.data.errors;
                toAstError(errors);
            }
        });
    };

    useEffect(() => {
        init();
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    const [meta] = useState({
        title: "Sizing Guide page",
        description: "",
        keyword: ""
    });

    return (
        <>
            <Meta
                title={meta.title}
                description={meta.description}
                keywords={meta.keyword}
            />
            <Breadcrumb title="Sizing Guide"/>
            <section className="sizing-guide-area ptb-60">
                <div className="container">
                    <div className="section-title">
                        <h2>
                            <span className="dot"></span> Size Guide
                        </h2>
                    </div>
                    {!!sizeguide &&
                        !!sizeguide.length &&
                        sizeguide.map((item) => {
                            return renderHtmlContent({content: item.content});
                        })}
                </div>
            </section>
            <Facility/>
        </>
    );
};
