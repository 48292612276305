import {useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {useLazyQuery, useMutation} from '@apollo/client';
import {ToastContainer, Slide} from 'react-toastify';

import {auth} from '../../utils/auth';
import Navbar from '../../components/Layout/Navbar';
import Footer from '../../components/Layout/Footer';
import GoTop from '../../components/Shared/GoTop';
import Router from '../../routings/router';

import callGetApi from '../../api/index';

import {
    CREATE_COMPARE_LIST,
    GET_COMPARE_LIST,
    ASSIGN_COMPARE_LIST_TO_CUSTOMER,
    IS_CUSTOMER_TOKEN_AVAILABLE,
    QUERY_GET_WISH_LIST,
    QUERY_GET_CUSTOMER_DATA_GQL
} from '../../graphql/customer.gql';

import {
    CREATE_EMPTY_CART_GQL,
    GET_CART_INFO_GQL,
    MERGE_CART_GQL
} from '../../graphql/checkout.gql';

import {
    GET_STORE_GQL,
    QUERY_GET_STORE_CONFIG_GQL,
} from '../../graphql/store.gql';

import {mapStateToProps, mapDispatchToProps} from './state';
import './style.css';
import {toastAlert} from "../../utils/toastify";

const App = connect(
    mapStateToProps,
    mapDispatchToProps
)((props: any) => {
    const {
        authState: {info: authInfo},
        compareState: {compareListUUID, isAuthUUID},
        saveUerInfoAction,
        saveCartTokenAction,
        saveCartInfoAction,
        saveWishlistInfoAction,
        saveCompareInfo,
        saveCompareList,
        history,
        saveStoreConfigAction,
        signOutAction,
        clearUerInfoAction,
        clearWishlistAction,
        clearCompareData
    } = props;

    const init = () => {
        history && history.listen(() => {
            try {
                setTimeout(() => {
                    window.scrollTo(0, 0);
                    window.scrollBy(0, 0);
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                }, 500);
            } catch (e) {
            }
        });

        getStore();
        getStoreConfig();
        
        if (!!auth.loggedIn()) {
            prepareMergeCart();
            getCustomerData();
            getWishlist();
        } else {
            const guestCartId = localStorage.getItem('guest-cart-id');
            if (!!guestCartId) {
                getCartInfo({variables: {guestCartId}});
            } else {
                createEmptyCart();
            }
            clearUerInfoAction();
            clearWishlistAction();
        }

        initCompareList();
    };

    const checkExpiredTime = () => {
        let currentToken = localStorage.getItem('token');
        if (!currentToken) return;

        let expiredTime = localStorage.getItem('expired-time');
        if (!expiredTime) return;

        const now = new Date().getTime();
        const offset = parseFloat(expiredTime) - now;

        if (offset <= 0) {
            isCustomerTokenAvailable({
                variables: {
                    token: currentToken
                },
            });
        }
    };

    const initCompareList = () => {
        if (!compareListUUID || !compareListUUID.length) {
            createCompareList();
            return;
        }

        if (!isAuthUUID && auth.loggedIn()) {
            assignCompareListToCustomer({variables: {compareListUUID}});
        } else {
            !!compareListUUID &&
            !!compareListUUID.length &&
            getCompareList({
                variables: {
                    compareListUUID,
                },
            });
        }
    };

    const getWishlist = () => {
        callGetApi(QUERY_GET_WISH_LIST, true).then((item: any) => {
            let data = (!!item.data && item.data.data) || null;

            if (data) {
                let wishlist = (data.customer && data.customer.wishlist) || null;
                if (wishlist !== null) {
                    saveWishlistInfoAction(wishlist);
                }
            }
        });
    };

    const getCustomerData = () => {
        callGetApi(QUERY_GET_CUSTOMER_DATA_GQL, true).then((item: any) => {
            let data = (!!item.data && item.data.data) || null;

            if (data) {
                let customer = (data.customer && data.customer) || null;
                if (customer !== null) {
                    saveUerInfoAction(customer);
                }
            }
        });
    };

    const [createEmptyCart]: any = useMutation(CREATE_EMPTY_CART_GQL, {
        onCompleted: ({createEmptyCart}) => {
            saveCartTokenAction(createEmptyCart);
            getCartInfo({variables: {cartId: createEmptyCart}});
        },
    });

    const [prepareMergeCart]: any = useMutation(CREATE_EMPTY_CART_GQL, {
        onCompleted: ({createEmptyCart}) => {
            const guestCartId = localStorage.getItem('guest-cart-id');
            if (!!guestCartId) {
                mergeCart({
                    variables: {
                        guestCartId: guestCartId,
                        customerCartId: createEmptyCart,
                    },
                });

                localStorage.removeItem('guest-cart-id');
            } else {
                saveCartTokenAction(createEmptyCart);
                getCartInfo({variables: {cartId: createEmptyCart}});
            }
        },
    });

    const [mergeCart]: any = useMutation(MERGE_CART_GQL, {
        onCompleted: (response) => {
            saveCartTokenAction(response.mergeCarts.id);
            getCartInfo({variables: {cartId: response.mergeCarts.id}});
        },
    });

    const [getCartInfo, {data: cartData}]: any = useLazyQuery(
        GET_CART_INFO_GQL,
        {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            onCompleted: ({cart}) => {
                saveCartInfoAction(cart);
            },
            onError: (errors: any) => {
                if (!!cartData) {
                    const {cart} = cartData;
                    if (!!cart) {
                        saveCartInfoAction(cart);
                    }
                }
            },
        }
    );

    const [createCompareList]: any = useMutation(CREATE_COMPARE_LIST, {
        onCompleted: ({createCompareList: {uid: compareListUUID}}) => {
            const isLogin = !!authInfo && !!authInfo.token;

            compareListUUID && saveCompareInfo({compareListUUID, isAuthUUID: isLogin});

            !!compareListUUID && !!compareListUUID.length &&
            getCompareList({
                variables: {
                    compareListUUID,
                },
            });
        },
    });

    const [isCustomerTokenAvailable]: any = useMutation(IS_CUSTOMER_TOKEN_AVAILABLE, {
        onCompleted: ({isCustomerTokenAvailable}) => {
            if (isCustomerTokenAvailable.is_available === true) {
                const now = new Date().getTime();
                const HOUR_TO_MILI_SECOND = 60 * 60 * 1000;
                const expiredTime = now + (isCustomerTokenAvailable.expired_after_hours <= 0 ? 1 : isCustomerTokenAvailable.expired_after_hours) * HOUR_TO_MILI_SECOND;

                localStorage.setItem('expired-time', expiredTime.toString());
            } else {
                signOutAction();
            }
        },
        onError: ({message}) => {
            toastAlert({type: "error", message});
            signOutAction();
        },
    });

    const [getCompareList]: any = useLazyQuery(GET_COMPARE_LIST, {
        fetchPolicy: 'network-only',
        onCompleted: ({compareList}) => {
            saveCompareList({compareData: compareList});
        },
        onError: ({message}) => {
            clearCompareData();
            createCompareList();
        },
    });

    const [assignCompareListToCustomer]: any = useMutation(
        ASSIGN_COMPARE_LIST_TO_CUSTOMER, {
            onCompleted: ({assignCompareListToCustomer: {result: result, compare_list: {uid: compareListUUID}}}) => { // eslint-disable-line no-useless-rename
                if (result === true) {
                    saveCompareInfo({compareListUUID, isAuthUUID: true});
                    getCompareList({
                        variables: {
                            compareListUUID,
                        },
                    });
                }
            },
            onError: ({message}) => toastAlert({type: 'error', message}),
        }
    );

    const getStore = () => {
        callGetApi(GET_STORE_GQL).then((item: any) => {
            let data = (!!item.data && item.data.data) || null;

            if (data) {
                const store =
                    (data &&
                        data.availableStores &&
                        data.availableStores[0] &&
                        data.availableStores[0].store_code) || 'default';
                localStorage.setItem('store', store);

                const currency = (data &&
                    data.availableStores &&
                    data.availableStores[0] &&
                    data.availableStores[0].base_currency_code) || null;

                localStorage.setItem('currency', currency);
            }
        });
    };

    const getStoreConfig = () => {
        callGetApi(QUERY_GET_STORE_CONFIG_GQL).then((item: any) => {
            let storeConfig =
                (!!item.data &&
                    !!item.data.data &&
                    !!item.data.data.storeConfig &&
                    item.data.data.storeConfig) ||
                null;
            if (storeConfig) {
                saveStoreConfigAction(storeConfig);
            }
        });
    };

    useEffect(() => {
        init();
        checkExpiredTime();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id='App'>
            <ReactTooltip/>

            <Navbar/>

            <Router/>

            <Footer/>

            <ToastContainer transition={Slide}/>

            <GoTop scrollStepInPx='100' delayInMs='10.50'/>
        </div>
    );
});

export default withRouter(App);
