import React, {Component} from 'react';
import Slider from 'react-slick';

class ProductImage extends Component<any, any> {
    private slider1: any;
    private slider2: any;

    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    renderSliderMainImages = ({list}) => {
        return list.map(({label, url}) => {
            return (
                <div key={label}>
                    <div className='item'>
                        <img src={url} alt={label}/>
                    </div>
                </div>
            );
        });
    };

    renderSliderSubImages = ({list}) => {
        return list.map(({label, url}) => {
            return (
                <div key={label}>
                    <div className='item'>
                        <img src={url} alt={label}/>
                    </div>
                </div>
            );
        });
    };

    render() {
        const {list} = this.props;
        const formatedList =
            list.length >= 2
                ? [...list, ...list, ...list]
                : [...list, ...list, ...list, ...list, ...list, ...list];

        return (
            <div className='col-lg-6 col-md-6'>
                <div className='products-page-gallery'>
                    <div className='product-page-gallery-main'>
                        <div>
                            <Slider
                                asNavFor={this.state.nav2}
                                ref={(slider) => (this.slider1 = slider)}
                            >
                                {this.renderSliderMainImages({list: formatedList})}
                            </Slider>
                        </div>
                    </div>

                    <div className='product-page-gallery-preview'>
                        <div>
                            <Slider
                                asNavFor={this.state.nav1}
                                ref={(slider) => (this.slider2 = slider)}
                                slidesToShow={5}
                                swipeToSlide={true}
                                focusOnSelect={true}
                                arrows={false}
                                dots={false}
                            >
                                {this.renderSliderSubImages({list: formatedList})}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductImage;
