/* eslint-disable import/no-anonymous-default-export */
import { REDUCER_GROUP } from '../reducer.group';
import * as ACTION_TYPE from './type';

export const INITIAL_STATE = {
  info: null,
};

export default (
  state = INITIAL_STATE,
  action = {
    type: '',
    payload: { data: {}, items: [] },
    meta: {},
    group: '',
  }
) => {
  if (action.group !== REDUCER_GROUP.WISHLIST) return state;

  switch (action.type) {
    case ACTION_TYPE.SAVE_WISHLIST_INFO:
      let { data }: any = action.payload;
      data = Object.assign({}, data, {
        items: data.items_v2.items,
      });

      return Object.assign({}, state, {
        info: data,
      });

    case ACTION_TYPE.UPDATE_WISHLIST_ITEMS:
      const { items } = action.payload;

      const newState = Object.assign({}, state, {
        info: Object.assign({}, state.info, { items }),
      });

      return newState;

    case ACTION_TYPE.CLEAR_WISHLIST:
      return Object.assign({}, state, {
        info: null,
      });

    default:
      return state;
  }
};
