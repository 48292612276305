import {v4 as uuidv4} from 'uuid';

export const renderHtmlContent = (
    {
        content = '',
        style = {},
        isDetectLink = false,
        isReplaceVideoEmbed = false,
    }) => {
    try {
        let formatedContent = replaceNewLine(content);

        if (!!isDetectLink) formatedContent = autoDetectUrl(formatedContent);

        const inheritStyle = {
            fontSize: 'inherit',
            fontWeight: 'inherit',
            lineHeight: 'inherit',
        } as any;

        return (
            <div
                key={uuidv4()}
                style={Object.assign({}, inheritStyle, style)}
                dangerouslySetInnerHTML={{
                    __html: formatedContent,
                }}
            />
        );
    } catch (e) {
        return '';
    }
};

export const replaceNewLine = (content) => content;

// export const replaceNewLine = (content) =>
//   content
//     .replace(/\n\n/g, '\n')
//     .replace(/\r\n\r\n/g, '\n')
//     .replace(/\n/g, '<br />');

export const autoDetectUrl = (content) => {
    try {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        const style = 'color: #1887db; font-size: inherit;';

        return content.replace(urlRegex, function (url) {
            return ` <a style="${style}" href="${url}">${url}</a> `;
        });
    } catch (e) {
        return '';
    }
};
