import React, {useEffect, useState} from "react";
import {QUERY_GET_FACILITY_AREA_GQL} from "../../../graphql/layout.gql";
import {renderHtmlContent} from "../../../utils/html";
import callGetApi, {toAstError} from "../../../api";

export default () => {
    const [facility, setFacility] = useState(null);

    const init = () => {
        getCmsBlocks();
    };

    const getCmsBlocks = () => {
        callGetApi(QUERY_GET_FACILITY_AREA_GQL).then((res: any) => {
            let cmsBlocks =
                !!res.data &&
                !!res.data.data &&
                !!res.data.data.cmsBlocks && res.data.data.cmsBlocks;

            if (cmsBlocks) {
                setFacility(cmsBlocks.items);
            }

            if (res.data.errors) {
                let errors = res.data.errors;
                toAstError(errors);
            }
        });
    };

    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {!!facility &&
                !!facility.length &&
                facility.map((item) => {
                    return renderHtmlContent({content: item.content});
                })}
        </>
    );
};
