export const transformCollection = (data) => {
  if (!data) return null;
  let rootList = [];
  data.map((item) => {
    if (parseInt(item.children_count) === 0) {
      rootList.push(item);
    } else {
      rootList.push(item);
      item.children.map((item1) => {
        rootList.push(item1);
        return true;
      });
    }
    
    return true;
  });
  return rootList;
};
