import * as ACTION_TYPE from './type';
import { REDUCER_GROUP } from '../reducer.group';

export const saveCompareInfo = (data) => ({
  type: ACTION_TYPE.SAVE_COMPARE_INFO,
  payload: data,
  group: REDUCER_GROUP.COMPARE,
});

export const saveCompareList = (data) => ({
  type: ACTION_TYPE.SAVE_COMPARE_LIST,
  payload: data,
  group: REDUCER_GROUP.COMPARE,
});

export const clearCompareData = () => ({
  type: ACTION_TYPE.CLEAR_COMPARE_DATA,
  payload: {},
  group: REDUCER_GROUP.COMPARE,
});
