import {NavLink} from "react-router-dom";

import Breadcrumb from "../../components/Common/Breadcrumb";
import Facility from "../../components/Common/Facility";
import NotFoundImage from "../../images/404.png";
import Meta from "../../components/meta";
import React, {useState} from "react";

export default () => {

    const [meta] = useState({
        title: "404 Not Found",
        description: "",
        keyword: ""
    });

    return (
        <>
            <Meta
                title={meta.title}
                description={meta.description}
                keywords={meta.keyword}
            />
            <Breadcrumb title="404 Not Found"/>

            <section className="error-area ptb-60">
                <div className="container">
                    <div className="error-content">
                        <img src={NotFoundImage} alt="error"/>

                        <h3>Page Not Found</h3>
                        <p>
                            The page you are looking for might have been removed had its name
                            changed or is temporarily unavailable.
                        </p>

                        <NavLink to="/" className={'btn btn-light'}>
                            Go to Home
                        </NavLink>
                    </div>
                </div>
            </section>
            <Facility/>
        </>
    );
};
