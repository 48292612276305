import React, {useEffect, useState} from 'react';
import {
    SUBMIT_CONTACT_FORM,
    QUERY_GET_CONTACT_INFO_AREA_GQL
} from '../../graphql/layout.gql';
import {useMutation} from '@apollo/client';
import {toastAlert} from '../../utils/toastify';
import {renderHtmlContent} from '../../utils/html';

import Facility from '../../components/Common/Facility';
import Breadcrumb from '../../components/Common/Breadcrumb';
import Meta from "../../components/meta";
import callGetApi, {toAstError} from "../../api";

export default () => {
    const [contactinfo, setContactInfo] = useState(null);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [mess, setMessage] = useState('');

    const handleChangeName = (e) => setName(e.target.value);
    const handleChangeEmail = (e) => setEmail(e.target.value);
    const handleChangePhone = (e) => setPhone(e.target.value);
    const handleChangeMess = (e) => setMessage(e.target.value);

    const init = () => {
        queryGetContactInfo();
    };

    const queryGetContactInfo = () => {
        callGetApi(QUERY_GET_CONTACT_INFO_AREA_GQL).then((res: any) => {
            let cmsBlocks =
                !!res.data &&
                !!res.data.data &&
                !!res.data.data.cmsBlocks && res.data.data.cmsBlocks;

            if (cmsBlocks) {
                setContactInfo(cmsBlocks.items);
            }

            if (res.data.errors) {
                let errors = res.data.errors;
                toAstError(errors);
            }
        });
    };

    const [submitForm, {loading: isSignUpLoading}]: any = useMutation(
        SUBMIT_CONTACT_FORM,
        {
            onCompleted: () => {
                toastAlert({
                    type: 'success',
                    message: 'Submit Form successful',
                });
            },
            onError: ({message}) => {
                toastAlert({type: 'error', message});
            },
        }
    );
    const handleSubmitForm = (e) => {
        e.preventDefault();
        submitForm({
            variables: {
                name,
                email,
                phoneNumber: phone,
                message: mess,
            },
        });
        setName('');
        setPhone('');
        setMessage('');
        setEmail('');
    };

    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [meta] = useState({
        title: "Contact Us - iGBMerch Official Website",
        description: "If you have any questions, feel free to contact us at email: support@igbmerch.com. We are more than happy to help you.Have an idea for design? Reach out to us and we&#39;ll take care the rest.Contact us via:Email: support@igbmerch.comAddress: 600 N Broad Street, Suite 5 #3450, Middletown, DE 19709, USA.",
        keyword: ""
    });

    return (
        <>
            <Meta
                title={meta.title}
                description={meta.description}
                keywords={meta.keyword}
            />
            <Breadcrumb title='Contact Us'/>

            <section className='contact-area ptb-60'>
                <div className='container'>
                    <div className='section-title'>
                        <h2>
                            <span className='dot'></span> Contact Us
                        </h2>
                    </div>

                    <div className='row'>
                        <div className='col-lg-5 col-md-12'>
                            {!!contactinfo &&
                                !!contactinfo.length &&
                                contactinfo.map((item) => {
                                    return renderHtmlContent({content: item.content});
                                })}
                        </div>

                        <div className='col-lg-7 col-md-12'>
                            <div className='contact-form'>
                                <h3>Drop Us A Line</h3>
                                <p>
                                    We’re happy to answer any questions you have or provide you
                                    with an estimate. Just send us a message in the form below
                                    with any questions you may have.
                                </p>

                                <form id='contactForm' onSubmit={handleSubmitForm}>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className='form-group'>
                                                <label>
                                                    Name <span>(required)*</span>
                                                </label>
                                                <input
                                                    onChange={handleChangeName}
                                                    value={name}
                                                    type='text'
                                                    name='name'
                                                    id='name'
                                                    className='form-control'
                                                    required={true}
                                                    data-error='Please enter your name'
                                                    placeholder='Enter your name'
                                                />
                                                <div className='help-block with-errors'></div>
                                            </div>
                                        </div>

                                        <div className='col-lg-12 col-md-12'>
                                            <div className='form-group'>
                                                <label>
                                                    Email <span>(required)*</span>
                                                </label>
                                                <input
                                                    onChange={handleChangeEmail}
                                                    value={email}
                                                    type='email'
                                                    name='email'
                                                    id='email'
                                                    className='form-control'
                                                    required={true}
                                                    data-error='Please enter your email'
                                                    placeholder='Enter your Email Address'
                                                />
                                                <div className='help-block with-errors'></div>
                                            </div>
                                        </div>

                                        <div className='col-lg-12 col-md-12'>
                                            <div className='form-group'>
                                                <label>
                                                    Phone Number <span>(required)*</span>
                                                </label>
                                                <input
                                                    onChange={handleChangePhone}
                                                    value={phone}
                                                    type='text'
                                                    name='phone_number'
                                                    id='phone_number'
                                                    className='form-control'
                                                    required={true}
                                                    data-error='Please enter your phone number'
                                                    placeholder='Enter your Phone Number'
                                                />
                                                <div className='help-block with-errors'></div>
                                            </div>
                                        </div>

                                        <div className='col-lg-12 col-md-12'>
                                            <div className='form-group'>
                                                <label>
                                                    Your Message <span>(required)*</span>
                                                </label>
                                                <textarea
                                                    onChange={handleChangeMess}
                                                    value={mess}
                                                    name='message'
                                                    id='message'
                                                    cols={30}
                                                    rows={8}
                                                    required={true}
                                                    data-error='Please enter your message'
                                                    className='form-control'
                                                    placeholder='Enter your Message'
                                                />
                                                <div className='help-block with-errors'></div>
                                            </div>
                                        </div>

                                        <div className='col-lg-12 col-md-12'>
                                            <button
                                                type='submit'
                                                className='btn btn-primary'
                                                style={{width: 165}}
                                            >
                                                {!isSignUpLoading ? (
                                                    'Send Message'
                                                ) : (
                                                    <span className='spinner-grow spinner-grow-sm'/>
                                                )}
                                            </button>
                                            <div
                                                id='msgSubmit'
                                                className='h3 text-center hidden'
                                            ></div>
                                            <div className='clearfix'></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Facility/>
        </>
    );
};
