export const checkWishlist = ({ wishlistItems, data }) => {
  if (!wishlistItems || !wishlistItems.length) return 0;

  const item = wishlistItems.find(
    (item) => item.product && item.product.sku === data.sku
  );

  return !!item ? item.id : 0;
};

