export const ACTION_TYPE = {
  ROUTING_ACTION: 'ROUTING_ACTION',
  API_ACTION: 'API_ACTION',
  INTERACTION_ACTION: 'INTERACTION_ACTION',
  BACKGROUND_ACTION: 'BACKGROUND_ACTION',
};

export const PROMISE_ACTION_TYPE = {
  PENDING: 'PENDING',
  FULFILLED: 'FULFILLED',
  REJECTED: 'REJECTED',
};

export const PENDING_TYPE = (type) => `${type}_${PROMISE_ACTION_TYPE.PENDING}`;
export const FULFILLED_TYPE = (type) =>
  `${type}_${PROMISE_ACTION_TYPE.FULFILLED}`;
export const REJECTED_TYPE = (type) =>
  `${type}_${PROMISE_ACTION_TYPE.REJECTED}`;
