import { saveAuthInfoAction, signOutAction } from '../../../flows/auth/action';
import { clearUerInfoAction } from '../../../flows/user/action';
import { clearWishlistAction } from '../../../flows/wishlist/action';
import { clearCompareData } from '../../../flows/compare/action';
import { clearCartAction } from '../../../flows/checkout/action';

export const mapStateToProps = (state) => ({
  userState: state.user,
  checkoutState: state.checkout,
});

export const mapDispatchToProps = (dispatch) => ({
  clearCartAction: () => dispatch(clearCartAction()),
  saveAuthInfoAction: (data: any) => dispatch(saveAuthInfoAction(data)),
  signOutAction: () => dispatch(signOutAction()),
  clearUerInfoAction: () => dispatch(clearUerInfoAction()),
  clearCompareData: () => dispatch(clearCompareData()),
  clearWishlistAction: () => dispatch(clearWishlistAction()),
});
