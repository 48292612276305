import React, {useEffect, useState} from "react";
import {QUERY_GET_SHIPPING_AREA_GQL} from "../../graphql/layout.gql";
import {renderHtmlContent} from "../../utils/html";
import callGetApi, {toAstError} from "../../api";

const Shipping = (props) => {
    const [shipping, setShipping] = useState(null);

    const init = () => {
        queryGetShipping();
    };

    const queryGetShipping = () => {
        callGetApi(QUERY_GET_SHIPPING_AREA_GQL).then((res: any) => {
            let cmsBlocks =
                !!res.data &&
                !!res.data.data &&
                !!res.data.data.cmsBlocks &&
                res.data.data.cmsBlocks;

            if (cmsBlocks) {
                setShipping(cmsBlocks.items);
            }

            if (res.data.errors) {
                let errors = res.data.errors;
                toAstError(errors);
            }
        });
    };

    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const {closeShipModal} = props;
    return (
        <div
            className="modal fade productShippingModal show"
            style={{paddingRight: "16px", display: "block"}}
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <button
                        type="button"
                        onClick={closeShipModal}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
            <span aria-hidden="true">
              <i className="fas fa-times"></i>
            </span>
                    </button>

                    <div className="shipping-content">
                        {!!shipping &&
                            !!shipping.length &&
                            shipping.map((item) => {
                                return renderHtmlContent({content: item.content});
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Shipping;
