export const isPromise = (value) =>
  !!value && !!value.promise && 'function' === typeof value.promise.then;

export const replaceAllSpace = (str) => {
  try {
    if (!str) return '';

    return typeof str.replaceAll === 'function' && str.replaceAll(' ', '');
  } catch (e) {
    return '';
  }
};

export const checkEmptryString = (str) => {
  try {
    if (!str) return true;

    const replacement = replaceAllSpace(str);
    if (!replacement || !replacement.length) return true;

    return false;
  } catch (e) {
    return true;
  }
};
