import React, {Suspense} from 'react';
import {Provider} from 'react-redux';
import {createBrowserHistory} from 'history';
import {BrowserRouter} from 'react-router-dom';
import {ApolloProvider} from '@apollo/client/react';
import {PersistGate} from 'redux-persist/integration/react';
import {ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';

import App from '../app';
import i18n from "../../i18n";
import {I18nextProvider} from 'react-i18next';

import configureStore from '../../flows/store.config';
import {GRAPHQL_URI} from '../../graphql/config';

import '../../assets/styles/bootstrap.min.css';
import '../../assets/styles/fontawesome.min.css';
import '../../assets/styles/animate.min.css';
import '../../assets/styles/slick.css';
import '../../assets/styles/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-image-lightbox/style.css';
import '../../assets/styles/style.css';
import '../../assets/styles/responsive.css';

import './global-variable';

const history = createBrowserHistory();
const {store, persistor} = configureStore(history, {});

const httpLink = createHttpLink({
    uri: GRAPHQL_URI,
});

/**
 * Note isPreventToken
 * Set in context for each API
 * Default allow token (not prevent)
 *
 * context: { isPreventToken: true },
 */
const authLink = setContext((_, {headers, isPreventToken = false}) => {
    const token = localStorage.getItem('token');
    const Store = localStorage.getItem('store') || null;
    const currency = localStorage.getItem('currency') || null;
    const authorization = token && !isPreventToken ? `Bearer ${token}` : null;

    return {
        headers: Object.assign(
            {},
            {...headers},
            Store && {Store},
            authorization && {authorization},
            currency && {"Content-Currency": currency}
        ),
    };
});

const client = new ApolloClient({
    uri: GRAPHQL_URI,
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

export {store};
export default () => (
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ApolloProvider client={client}>
                    <Suspense fallback="loading">
                        <BrowserRouter>
                            <I18nextProvider i18n={i18n} defaultNS={'translation'}>
                                <App/>
                            </I18nextProvider>
                        </BrowserRouter>
                    </Suspense>
                </ApolloProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);
