import React, {useEffect, useState} from 'react';
import {
    QUERY_GET_LIST_BRANDINGS_GQL
} from '../../graphql/layout.gql';

import OwlCarousel from 'react-owl-carousel3';
import {NavLink} from "react-router-dom";
import callGetApi, {toAstError} from "../../api";

const options = {
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    navText: [
        "<i class='fas fa-chevron-left'></i>",
        "<i class='fas fa-chevron-right'></i>",
    ],
    responsive: {
        0: {
            items: 3,
            margin: 30,
        },
        576: {
            items: 4,
        },
        768: {
            items: 5,
        },
        1200: {
            items: 6,
        },
    },
};

const Partner = () => {
    const [display, setDisplay] = useState(false);
    const [brands, setBrand] = useState(null);

    const init = () => {
        queryGetBrand();
    };

    const queryGetBrand = () => {
        callGetApi(QUERY_GET_LIST_BRANDINGS_GQL).then((res: any) => {
            let brands =
                !!res.data &&
                !!res.data.data &&
                !!res.data.data.getListBrandings && res.data.data.getListBrandings;

            if (brands) {
                setBrand(brands.items);
                setDisplay(true);
            }

            if (res.data.errors) {
                let errors = res.data.errors;
                toAstError(errors);
            }
        });
    };

    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='partner-area'>
            <div className='container'>
                {display ? (
                    <OwlCarousel
                        className='partner-slides owl-carousel owl-theme'
                        {...options}
                    >
                        {!!brands &&
                            !!brands.length &&
                            brands.map((data) => {
                                return (
                                    <div className='partner-item' key={data.id}>
                                        <NavLink
                                            to={'#'}
                                            onClick={(e) => e.preventDefault()}
                                            style={{pointerEvents: 'none'}}
                                        >
                                            <img src={data.image} alt=''/>
                                        </NavLink>
                                    </div>
                                );
                            })}
                    </OwlCarousel>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

export default Partner;
