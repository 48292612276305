export const enableDocumentScroll = () => {
  try {
    const shopAppElement: any = document.getElementById('shop-app');

    if (!!shopAppElement) {
      const scrollYPages = Math.abs(parseInt(shopAppElement.style.top));
      shopAppElement.setAttribute('style', '');
      !!scrollYPages && scrollElement({ x: 0, y: scrollYPages });
    }

    /** Show facebook customer chat when close modal */
    const fbRootElement: any = document.getElementById('fb-root');
    if (!!fbRootElement) fbRootElement.style.display = 'block';
  } catch (e) {}
};

export const disableDocumentScroll = () => {
  try {
    const shopAppElement: any = document.getElementById('shop-app');
    const isFixScroll =
      !!shopAppElement && 'fixed' !== shopAppElement.style.position;

    if (isFixScroll) {
      const scrollYPages = window.scrollY;
      shopAppElement.style.top = `-${scrollYPages}px`;
      shopAppElement.style.width = '100%';
      shopAppElement.style.position = 'fixed';
    }

    /** Force hide facebook customer chat when open modal */
    const fbRootElement: any = document.getElementById('fb-root');
    if (!!fbRootElement) fbRootElement.style.display = 'none';
  } catch (e) {}
};

interface IScrollElement {
  x: number;
  y: number;
  element?: Window | any;
  isAnimation?: Boolean;
}

export function scrollElement({
  x,
  y,
  element = window,
  isAnimation = false,
}: IScrollElement) {
  if (!!isAnimation) {
    element.scrollTo({
      left: x,
      top: y,
      behavior: 'smooth',
    });
  } else {
    element.scrollTo(x, y);
  }
}
