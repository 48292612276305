import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { calculateProductItemPrice } from '../../utils/cart';

class OrderSummary extends Component<any, any> {
  render() {
    const { cartInfo, selectedShippingMethod } = this.props;
    if (!cartInfo) return null;

    const cartItems = cartInfo.items;
    const shippingPrice =
      (selectedShippingMethod &&
        selectedShippingMethod.amount &&
        selectedShippingMethod.amount.value) ||
      0;

    const discountPrice = Math.abs(
      parseInt(
        (cartInfo &&
          cartInfo.prices &&
          cartInfo.prices.discount &&
          cartInfo.prices.discount.amount &&
          cartInfo.prices.discount.amount.value) ||
          0
      )
    );
    const discountPriceDisplay = !!discountPrice ? `-$${discountPrice}` : '';

    const tax = cartInfo?.prices?.applied_taxes || [];

    return (
      <div className='order-table table-responsive'>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th scope='col'>Product Name</th>
              <th scope='col'>Total</th>
            </tr>
          </thead>

          <tbody>
            {cartItems.map((data, idx) => {
              if (!data || !data.product) return null;

              const price = calculateProductItemPrice(data);
              return (
                <tr key={idx}>
                  <td className='product-name'>
                    <img
                      style={{
                        height: 30,
                        width: 30,
                        objectFit: 'contain',
                        marginRight: 10,
                        verticalAlign: 'middle',
                      }}
                      alt={data.product.thumbnail && data.product.thumbnail.label}
                      src={data.product.thumbnail && data.product.thumbnail.url}
                    />
                    <NavLink
                      to='#'
                      style={{
                        verticalAlign: 'middle',
                        maxWidth: 'calc(100% - 42px)',
                      }}
                    >
                      {data.product && data.product.name} - Qty: {data.quantity}
                    </NavLink>
                    <ul style={{ listStyleType: 'none', paddingLeft: '3rem', marginBottom: 0 }}>
                      {data.configurable_options &&
                      data.configurable_options.map((config) => (
                          <li>
                    <span style={{ textTransform: 'capitalize' }}>
                      {config.option_label}
                    </span>
                            : <strong>{config.value_label}</strong>
                          </li>
                      ))}
                    </ul>
                  </td>

                  <td className='product-total'>
                    <span className='subtotal-amount'>
                      ${Math.round(price * data.quantity * 100) / 100}
                    </span>
                  </td>
                </tr>
              );
            })}

            <tr>
              <td className='order-subtotal'>
                <span>Subtotal Excluding Tax</span>
              </td>

              <td className='order-subtotal-price'>
                <span className='order-subtotal-amount'>
                  $
                  {Math.round(
                    cartInfo?.prices?.subtotal_excluding_tax?.value * 100
                  ) / 100}
                </span>
              </td>
            </tr>

            <tr>
              <td className='order-shipping'>
                <span>Shipping</span>
              </td>

              <td className='shipping-price'>
                <span>${shippingPrice}</span>
              </td>
            </tr>

            {!!discountPriceDisplay && (
              <tr>
                <td className='order-shipping'>
                  <span>Discount price</span>
                </td>

                <td className='shipping-price'>
                  <span>{discountPriceDisplay}</span>
                </td>
              </tr>
            )}
            {!!tax && !!tax.length && (
              <tr>
                <td className='order-shipping'>
                  <span>Taxes</span>
                </td>

                <td className='shipping-price'></td>
              </tr>
            )}
            {tax &&
              tax.map((item, index) => (
                <tr>
                  <td className='order-shipping'>
                    <span style={{ width: 35, display: 'inline-block' }}>
                      {0 === index && ''}
                    </span>
                    <span>{item.label}</span>
                  </td>

                  <td className='shipping-price'>
                    <span>${item.amount && item.amount.value}</span>
                  </td>
                </tr>
              ))}

            <tr>
              <td className='total-price'>
                <span>Order Total</span>
              </td>

              <td className='product-subtotal'>
                <span className='subtotal-amount'>
                  $
                  {cartInfo &&
                    cartInfo.prices &&
                    cartInfo.prices.grand_total &&
                    cartInfo.prices.grand_total.value}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default OrderSummary;
