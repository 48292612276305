import {
  ROUTING_AUTH_CONNECT_FACEBOOK,
  ROUTING_AUTH_CONNECT_GOOGLE,
} from '../routings/path';

const REDIRECT_URL = `${process.env.REACT_APP_HTTP_PROTOCOL}://${
  process.env.REACT_APP_HOST
}${!!process.env.REACT_APP_PORT ? ':' + process.env.REACT_APP_PORT : ''}`;

export const FB_CALLBACK_URI = ROUTING_AUTH_CONNECT_FACEBOOK;
export const FB_AUTH_SCOPE_PARAMS = '';
export const FB_SIGN_IN_URL = (fbAppIdd) => `https://www.facebook.com/dialog/oauth?client_id=${fbAppIdd}&redirect_uri=${REDIRECT_URL}${FB_CALLBACK_URI}&response_type=token&scope=email`;

export const GOOGLE_CALLBACK_URI = ROUTING_AUTH_CONNECT_GOOGLE;
export const GOOGLE_SIGNIN_CLIENT_ID = '';
export const GOOGLE_SIGN_IN_URI = `https://accounts.google.com/o/oauth2/auth?redirect_uri=${REDIRECT_URL}${GOOGLE_CALLBACK_URI}&response_type=code token id_token&scope=openid profile email&client_id=116433332681-sgk1ab4q3bnhn7jmnn78kbte15pn7o3n.apps.googleusercontent.com`;
