import { saveAuthInfoAction } from '../../../flows/auth/action';
import { clearCartAction } from '../../../flows/checkout/action';
import {saveUerInfoAction} from "../../../flows/user/action";

export const mapStateToProps = (state) => ({
  authState: state.auth,
  appState: state.app,
});

export const mapDispatchToProps = (dispatch) => ({
  saveAuthInfoAction: (data: any) => dispatch(saveAuthInfoAction(data)),
  saveUerInfoAction: (data: any) => dispatch(saveUerInfoAction(data)),
  clearCartAction: () => dispatch(clearCartAction()),
});
