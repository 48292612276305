import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { toastAlert } from '../../../utils/toastify';
import { checkEmptryString } from '../../../utils/validation';
import * as ROUTINGS from '../../../routings/path';
import { CHANGE_PASSWORD_GQL } from '../../../graphql/customer.gql';

let strongPassword = new RegExp(
  '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'
);
let mediumPassword = new RegExp(
  '((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))'
);

function StrengthChecker(PasswordParameter) {
  if (!PasswordParameter || !PasswordParameter.length) return null;
  if (strongPassword.test(PasswordParameter)) {
    return (
      <span
        style={{
          padding: '0 10px',
          textTransform: 'capitalize',
          fontSize: 12,
          color: 'green',
        }}
      >
        Strong
      </span>
    );
  } else if (mediumPassword.test(PasswordParameter)) {
    return (
      <span
        style={{
          padding: '0 10px',
          textTransform: 'capitalize',
          fontSize: 12,
          color: '#fb9e66',
        }}
      >
        Medium
      </span>
    );
  } else {
    return (
      <span
        style={{
          padding: '0 10px',
          textTransform: 'capitalize',
          fontSize: 12,
          color: 'red',
        }}
      >
        Weak
      </span>
    );
  }
}

export default (props: any) => {
  const { history } = props;
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newRePassword, setNewRePassword] = useState('');

  const hanndleChangePassword = (e) => setPassword(e.target.value);
  const hanndleChangeNewPassword = (e) => setNewPassword(e.target.value);
  const hanndleChangeNewRePassword = (e) => setNewRePassword(e.target.value);

  const validateForm = () => {
    if (checkEmptryString(password)) {
      toastAlert({ type: 'error', message: 'Error: Password is invalid.' });
      return false;
    }

    if (checkEmptryString(newPassword)) {
      toastAlert({ type: 'error', message: 'Error: New password is invalid.' });
      return false;
    }

    if (password === newPassword) {
      toastAlert({
        type: 'error',
        message: 'Password and re-pass need to different. Please input again',
      });
      return false;
    }

    if (newPassword !== newRePassword) {
      toastAlert({
        type: 'error',
        message: 'New password and re-pass are different. Please input again',
      });
      return false;
    }

    return true;
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();

    if (!validateForm()) return false;

    submitForm({
      variables: {
        currentPassword: password,
        newPassword: newPassword,
      },
    });
  };

  const [submitForm, { loading: isSubmitLoading }]: any = useMutation(
    CHANGE_PASSWORD_GQL,
    {
      onCompleted: () => {
        toastAlert({ type: 'success', message: 'Change password success' });
        history.push(ROUTINGS.ROUTING_USER_EDIT);
      },
      onError: ({ message }) => toastAlert({ type: 'error', message }),
    }
  );

  return (
    <>
      <div className='col-lg-6 col-sm-12' style={{ margin: '0 auto' }}>
        <form className='login-form' onSubmit={handleSubmitForm}>
          <div className='form-group'>
            <label>
              Current password <span className='required'>*</span>
            </label>
            <input
              value={password}
              onChange={hanndleChangePassword}
              type='password'
              required
              className='form-control'
              placeholder='Enter current password'
              id='password'
              name='password'
            />
          </div>

          <div className='form-group'>
            <label>
              New password <span className='required'>*</span>
              {StrengthChecker(newPassword)}
            </label>
            <input
              value={newPassword}
              required
              onChange={hanndleChangeNewPassword}
              type='password'
              className='form-control'
              placeholder='Enter new password'
              id='newPassword'
              name='newPassword'
            />
          </div>

          <div className='form-group'>
            <label>
              Re-enter new password <span className='required'>*</span>
            </label>
            <input
              value={newRePassword}
              required
              onChange={hanndleChangeNewRePassword}
              type='password'
              className='form-control'
              placeholder='Re-enter new password'
              id='rePassword'
              name='rePassword'
            />
          </div>

          <button
            type='submit'
            disabled={isSubmitLoading}
            className='btn btn-primary'
          >
            {!isSubmitLoading ? (
              'Save'
            ) : (
              <span className='spinner-grow spinner-grow-sm' />
            )}
          </button>
        </form>
      </div>
    </>
  );
};
