import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {useLazyQuery, useMutation} from '@apollo/client';
import {toastAlert} from '../../utils/toastify';
import {
    ADD_SIMPLE_PRODUCT_TO_CART_GQL,
    ADD_CONFIGRURABLE_PRODUCT_TO_CART_GQL, GET_CART_INFO_GQL
} from '../../graphql/checkout.gql';
import * as ROUTINGS from '../../routings/path';
import {saveCartInfoAction} from '../../flows/checkout/action';

const AddToCart = (props: any) => {
    // @ts-ignore

    const [addSimpleProductToCart]: any = useMutation(
        ADD_SIMPLE_PRODUCT_TO_CART_GQL,
        {
            onCompleted: ({addSimpleProductsToCart}) => {
                toastAlert({type: 'success', message: 'Added to cart successful'});

                const cartInfo =
                    !!addSimpleProductsToCart && addSimpleProductsToCart.cart;
                !!cartInfo && saveCartInfoAction(cartInfo);
            },
            onError: ({message}) => {
                toastAlert({type: 'error', message});
                getCartInfo({variables: {cartId}});
            },
        }
    );

    const [addConfigurableProductToCart]: any = useMutation(ADD_CONFIGRURABLE_PRODUCT_TO_CART_GQL, {
        onCompleted: ({addConfigurableProductsToCart}) => {
            toastAlert({type: 'success', message: 'Added to cart successful'});
            const cartInfo =
                !!addConfigurableProductsToCart && addConfigurableProductsToCart.cart;
            !!cartInfo && saveCartInfoAction(cartInfo);
        },
        onError: ({message}) => {
            toastAlert({type: 'error', message});
            getCartInfo({variables: {cartId}});
        },
    });

    const [getCartInfo, {data: cartDataInfo}]: any = useLazyQuery(GET_CART_INFO_GQL, {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        onCompleted: ({cart}) => {
            saveCartInfoAction(cart);
        },
        onError: ({message}: any) => {
            if (!!cartDataInfo) {
                const {cart} = cartDataInfo;
                if (!!saveCartInfoAction(cart)) {
                    saveCartInfoAction(cart);
                }
            }
        },
    });

    const {
        saveCartInfoAction,
        checkoutState: {cartId},
        product,
        type = 'button',
        childSku = '',
        fromWishList = false,
        onClick = () => {
        }
    } = props;

    const {sku, configurableOptions, slug} = product || {
        sku: null,
        configurableOptions: null,
        slug: null,
    };

    const handleAddToCart = (e) => {
        if (!product) return;

        if (!!fromWishList && !!product.variants) {
            addConfigurableProductToCart({
                variables: {sku: childSku, quantity: 1, cartId, parentSku: sku},
            });
            onClick && onClick();
            return;
        }

        if (!!configurableOptions) {
            toastAlert({
                type: 'info',
                message: 'Please select required variants before adding to cart',
            });
            return;
        }
        e.preventDefault();

        addSimpleProductToCart({
            variables: {sku, quantity: 1, cartId},
        });

        onClick && onClick();
        return;
    };

    return (
        <NavLink
            className='btn btn-light'
            onClick={handleAddToCart}
            style={
                type === 'text'
                    ? {
                        display: 'block',
                        padding: 0,
                        color: '#000',
                        background: '#fff',
                        width: 86,
                        fontSize: 11,
                        height: 20,
                        lineHeight: '18px',
                        marginTop: 5,
                    }
                    : {}
            }
            to={!slug ? '#' : `${ROUTINGS.ROUTING_PRODUCT_DETAIL_PATH}/${slug}`}
        >
            Add to Cart
        </NavLink>
    );
};

export const mapStateToProps = (state) => ({
    checkoutState: state.checkout,
});

export const mapDispatchToProps = (dispatch) => ({
    saveCartInfoAction: (data: any) => dispatch(saveCartInfoAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddToCart);
