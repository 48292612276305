import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import Wishlist from "../Modal/Wishlist";
import {toastAlert} from "../../utils/toastify";
import * as ROUTINGS from "../../routings/path";
import {LANGUAGES} from "../../constants/languages";
import {useTranslation} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const TopHeader = ({wishlistState}: any) => {
    const items = (wishlistState.info && wishlistState.info.items) || [];
    const [display, setDisplay] = useState(false);
    const {i18n, t} = useTranslation();

    useEffect(() => {
        
    }, []);
    const handleWishlist = () => {
        if (!items || !items.length) {
            return toastAlert({
                type: "error",
                message: "There are no item in wishlist.",
            });
        }
        setDisplay(!display);
    };

    const onChangeLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const lang_code = e.target.value;
        i18n.changeLanguage(lang_code);
    };

    const detectCurrentLang = () => {
        let currentLanguageDetector = new LanguageDetector();
        return currentLanguageDetector.detect();
    }

    return (
        <>
            <div className="top-header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-6">
                            <ul className="top-header-nav">
                                <li>
                                    <NavLink to="/about">
                                        {t("about")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/">
                                        {t("Our Stores")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/faq">
                                        FAQ's
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contact-us">
                                        Contact
                                    </NavLink>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-5 col-md-6">
                            <ul className="top-header-right-nav">
                                <li>
                                    <NavLink to="#" data-toggle={'modal'} data-target={'#shoppingWishlistModal'}
                                             onClick={(e) => {
                                                 e.preventDefault();
                                                 handleWishlist();
                                             }}
                                    >
                                        Wishlist <i className="far fa-heart"></i>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${ROUTINGS.ROUTING_COMPARE}`}>
                                        Compare <i className="fas fa-balance-scale"></i>
                                    </NavLink>
                                </li>
                                <li>
                                    <div className="languages-list">
                                        <select defaultValue={detectCurrentLang()} onChange={onChangeLang}>
                                            {LANGUAGES.map(({code, label}) => (
                                                <option key={code} value={code}>
                                                    {label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {display ? <Wishlist items={items} onClick={handleWishlist}/> : ""}
        </>
    );
};

export const mapStateToProps = (state) => ({
    wishlistState: state.wishlist,
});

export const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(TopHeader);
