import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {GET_POST_GRID_GQL} from '../../graphql/blog.gql';
import {useLazyQuery} from '@apollo/client';
import {toastAlert} from '../../utils/toastify';
import {NavLink} from 'react-router-dom';
import {renderHtmlContent} from '../../utils/html';
import ReactPaginate from 'react-paginate';
import {getUrlParameter} from '../../utils/uri';
import * as ROUTINGS from '../../routings/path';
import styles from './BlogGrid.module.scss';

export default withRouter((props) => {
    const {
        location: {search},
    } = props;

    const [posts, setPosts] = useState(null);
    const [page, setPage]: any = useState(getUrlParameter(search, 'page') || 1);
    const [totalPage, setTotalPage]: any = useState(1);

    const init = () => {
        const newPage = getUrlParameter(search, 'page') || 1;
        setPage(newPage);
        getPostGrid({
            variables: {page: newPage},
        });
    };

    const [getPostGrid]: any = useLazyQuery(GET_POST_GRID_GQL, {
        fetchPolicy: 'network-only',
        onCompleted: ({posts}) => {
            setPosts(posts.items);
            setTotalPage(posts.last_page_number);
        },
        onError: ({message}) => toastAlert({type: 'error', message}),
    });

    const hanleOnPageChange = ({selected}) => {
        if (selected + 1 === parseInt(page)) return;

        props.history.push(
            `${ROUTINGS.ROUTING_BLOG_PATH}?page=${selected * 1 + 1}`
        );
    };

    useEffect(() => {
        init();
    }, [props.location.search]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <section className='news-area ptb-60'>
            <div className='container'>
                <div className='row'>
                    {!!posts &&
                        !!posts.length &&
                        posts.map((item, idx) => {
                            return (
                                <React.Fragment key={idx}>
                                    <div className='col-lg-4 col-md-6'>
                                        <div className='single-blog-post'>
                                            <div className='blog-image'>
                                                <NavLink to={`${ROUTINGS.ROUTING_BLOG_PATH}/${item.url_key}`}>
                                                    <img src={item.post_thumbnail} alt=''/>
                                                </NavLink>

                                                <div className='post-tag'>
                                                    {item.categories && (
                                                        <NavLink to='#'>{item.categories[0]}</NavLink>
                                                    )}
                                                </div>
                                            </div>

                                            <div className='blog-post-content'>
                                                <span className='date'>{item.published_at}</span>
                                                <h3>
                                                    <NavLink
                                                        to={`${ROUTINGS.ROUTING_BLOG_PATH}/${item.url_key}`}
                                                        className={styles.itemName}
                                                    >
                                                        {item.title}
                                                    </NavLink>
                                                </h3>
                                                <div className={styles.itemDescription}>
                                                    {renderHtmlContent({content: item.short_content})}
                                                </div>

                                                <NavLink
                                                    to={`${ROUTINGS.ROUTING_BLOG_PATH}/${item.url_key}`}
                                                    className={'read-more-btn'}
                                                >
                                                    Read More <i className='icofont-double-right'></i>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            );
                        })}

                    {totalPage > 1 && (
                        <div className='col-lg-12 col-md-12'>
                            <div className={styles.pagination}>
                                <ReactPaginate
                                    forcePage={parseInt(page) - 1}
                                    initialPage={parseInt(page) - 1}
                                    activeClassName={styles.activeLink}
                                    activeLinkClassName={styles.activeLink}
                                    breakClassName={''}
                                    breakLabel='...'
                                    breakLinkClassName={''}
                                    containerClassName={''}
                                    disabledClassName={''}
                                    marginPagesDisplayed={1}
                                    nextClassName={''}
                                    nextLabel='Next'
                                    nextLinkClassName={styles.nextLink}
                                    onPageChange={hanleOnPageChange}
                                    pageClassName={styles.page}
                                    pageCount={totalPage}
                                    pageLinkClassName={styles.pageLink}
                                    pageRangeDisplayed={5}
                                    previousClassName={styles.previous}
                                    previousLabel='Prev'
                                    previousLinkClassName={styles.previousLink}
                                    subContainerClassName='pages pagination'
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
});
