import Breadcrumb from '../../../components/Common/Breadcrumb';
import CheckoutForm from '../../../components/checkout/CheckoutForm';
import Facility from '../../../components/Common/Facility';
import React, {useState} from "react";
import Meta from "../../../components/meta";

export default () => {
    const [meta] = useState({
        title: "Checkout page",
        description: "",
        keyword: ""
    });

    return (
        <>
            <Meta
                title={meta.title}
                description={meta.description}
                keywords={meta.keyword}
            />
            <Breadcrumb title='Checkout'/>

            <CheckoutForm/>
            <Facility/>
        </>
    );
};
