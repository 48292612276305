import { REDUCER_GROUP } from '../reducer.group';
import * as ACTION_TYPE from './type';

export const INITIAL_STATE = {
  compareListUUID: '',
  isAuthUUID: false,
  compareData: {},
};

const reducer = (
  state = INITIAL_STATE,
  action = {
    type: '',
    payload: { compareListUUID: '', isAuthUUID: false, compareData: {} },
    meta: {},
    group: '',
  }
) => {
  if (action.group !== REDUCER_GROUP.COMPARE) return state;

  switch (action.type) {
    case ACTION_TYPE.SAVE_COMPARE_INFO:
      const { compareListUUID, isAuthUUID } = action.payload;

      const newData = Object.assign(
        {},
        compareListUUID && { compareListUUID },
        isAuthUUID && { isAuthUUID }
      );

      return Object.assign({}, state, newData);

    case ACTION_TYPE.SAVE_COMPARE_LIST:
      const { compareData } = action.payload;

      return Object.assign({}, state, {
        compareData,
      });

    case ACTION_TYPE.CLEAR_COMPARE_DATA:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
