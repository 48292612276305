import {connect} from 'react-redux';
import React, {useState, useEffect} from 'react';
import {useLazyQuery} from '@apollo/client';
import {toastAlert} from '../../../utils/toastify';
import {NavLink} from 'react-router-dom';
import * as ROUTINGS from '../../../routings/path';

import {GET_LIST_ADDRESS_GQL} from '../../../graphql/customer.gql';
import {mapStateToProps, mapDispatchToProps} from './state';

interface IProps {
    userState: any;
    location: any;
    history: any;
    saveUerInfoAction: any;
}

const AddressList = ({customerAddresses}) => {

    const itemStyle = {
        border: '1px solid #000000',
        padding: 'calc(var(--bs-gutter-x)/ 2)',
        marginBottom: 'calc(var(--bs-gutter-x))',
        cursor: 'pointer',
    };

    const addingItem = {
        height: 'calc(100% - var(--bs-gutter-x))',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#000',
    };

    return (
        <form className='row'>
            {!!customerAddresses &&
                customerAddresses.map((item, idx) => (
                    <React.Fragment key={idx}>
                        <label className='col-lg-6 col-md-12'>
                            <div style={itemStyle}>
                                <div>
                                    <b>Name:</b> {`${item.lastname} ${item.firstname}`}
                                    <NavLink
                                        to={`${ROUTINGS.ROUTING_ADDRESS_LIST}/${item.id}`}
                                        style={{float: 'right', color: '#000'}}
                                    >
                                        <i className='fas fa-pencil-alt'></i>
                                    </NavLink>
                                </div>
                                <div>
                                    <b>Phone number:</b> {item.telephone}
                                </div>
                                <div>
                                    <b>Address:</b>{' '}
                                    {`${item.street && item.street[0]}, ${item.city}, ${item.country_code}`}
                                </div>
                            </div>
                        </label>
                    </React.Fragment>
                ))}
            <label
                className='col-lg-6 col-md-12'
                onClick={() => {
                    // onAddingNew();
                    // setSelected(0);
                }}
            >
                <NavLink to={ROUTINGS.ROUTING_CREATE_ADDRESS}>
                    <div style={{...itemStyle, ...addingItem}}>
                        <span style={{marginRight: 5}} className={'fa fa-plus'}></span>
                        Create New Address
                    </div>
                </NavLink>
            </label>
        </form>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)((props: IProps) => {
    const [customerAddresses, setcustomerAddresses] = useState(null);
    const init = () => {
        getListAddress();
    };
    const [getListAddress]: any = useLazyQuery(
        GET_LIST_ADDRESS_GQL,

        {
            fetchPolicy: 'network-only',
            onCompleted: ({customer}) => {
                setcustomerAddresses(customer.addresses);
            },
            onError: ({message}) => toastAlert({type: 'error', message}),
        }
    );

    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <AddressList {...{customerAddresses}} />;
});
