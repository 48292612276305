import {v4 as uuidv4} from 'uuid';
export const RatingValue = ({ value = 0 }) => {
  const formatedValue =
    value < 0.25
      ? [
          'far fa-star',
          'far fa-star',
          'far fa-star',
          'far fa-star',
          'far fa-star',
        ]
      : value < 0.75
      ? [
          'fas fa-star-half-alt',
          'far fa-star',
          'far fa-star',
          'far fa-star',
          'far fa-star',
        ]
      : value < 1.25
      ? [
          'fas fa-star',
          'far fa-star',
          'far fa-star',
          'far fa-star',
          'far fa-star',
        ]
      : value < 1.75
      ? [
          'fas fa-star',
          'fas fa-star-half-alt',
          'far fa-star',
          'far fa-star',
          'far fa-star',
        ]
      : value < 2.25
      ? [
          'fas fa-star',
          'fas fa-star',
          'far fa-star',
          'far fa-star',
          'far fa-star',
        ]
      : value < 2.75
      ? [
          'fas fa-star',
          'fas fa-star',
          'fas fa-star-half-alt',
          'far fa-star',
          'far fa-star',
        ]
      : value < 3.25
      ? [
          'fas fa-star',
          'fas fa-star',
          'fas fa-star',
          'far fa-star',
          'far fa-star',
        ]
      : value < 3.75
      ? [
          'fas fa-star',
          'fas fa-star',
          'fas fa-star',
          'fas fa-star-half-alt',
          'far fa-star',
        ]
      : value < 4.25
      ? [
          'fas fa-star',
          'fas fa-star',
          'fas fa-star',
          'fas fa-star',
          'far fa-star',
        ]
      : value < 4.75
      ? [
          'fas fa-star',
          'fas fa-star',
          'fas fa-star',
          'fas fa-star',
          'fas fa-star-half-alt',
        ]
      : [
          'fas fa-star',
          'fas fa-star',
          'fas fa-star',
          'fas fa-star',
          'fas fa-star',
        ];

  return (
    <>
      {formatedValue.map((c) => (
        <i key={uuidv4()} className={c}></i>
      ))}
    </>
  );
};
