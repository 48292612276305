import * as ACTION_TYPE from './type';
import { REDUCER_GROUP } from '../reducer.group';

export const saveCartTokenAction = (cartId) => ({
  type: ACTION_TYPE.SAVE_CART_TOKEN,
  payload: { cartId },
  group: REDUCER_GROUP.CHECKOUT,
});

export const clearCartAction = () => ({
  type: ACTION_TYPE.CLEAR_CART,
  payload: {},
  group: REDUCER_GROUP.CHECKOUT,
});

export const saveCartItemsAction = (cartItems) => ({
  type: ACTION_TYPE.SAVE_CART_ITEMS,
  payload: { cartItems },
  group: REDUCER_GROUP.CHECKOUT,
});

export const saveCartInfoAction = (cartInfo) => ({
  type: ACTION_TYPE.SAVE_CART_INFO,
  payload: { cartInfo },
  group: REDUCER_GROUP.CHECKOUT,
});

export const saveCustomerAddressAction = (customerAddress) => ({
  type: ACTION_TYPE.SAVE_CUSTOMER_ADDRESS,
  payload: { customerAddress },
  group: REDUCER_GROUP.CHECKOUT,
});

export const updateSelectedPaymentMethodAction = (selectedPaymentMethod) => ({
  type: ACTION_TYPE.UPDATE_SELECTED_PAYMENT_METHOD,
  payload: { selectedPaymentMethod },
  group: REDUCER_GROUP.CHECKOUT,
});
