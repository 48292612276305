import * as ACTION_TYPE from './type';
import { REDUCER_GROUP } from '../reducer.group';

export const saveWishlistInfoAction = (data) => ({
  type: ACTION_TYPE.SAVE_WISHLIST_INFO,
  payload: { data },
  group: REDUCER_GROUP.WISHLIST,
});

export const updateWishlistItemsAction = (items) => ({
  type: ACTION_TYPE.UPDATE_WISHLIST_ITEMS,
  payload: { items },
  group: REDUCER_GROUP.WISHLIST,
});

export const clearWishlistAction = () => ({
  type: ACTION_TYPE.CLEAR_WISHLIST,
  payload: { },
  group: REDUCER_GROUP.WISHLIST,
});
