import { auth } from '../../utils/auth';

import { REDUCER_GROUP } from '../reducer.group';
import * as ACTION_TYPE from './type';

export const INITIAL_STATE = {
  cartId: '',
  cartInfo: {
    items: [],
  },
  totalItemsInCart: 0,
  customerAddress: [],
};

const calcTotalItemsInCart = (cartItems) => {
  if (!cartItems || !cartItems.length) return 0;
  return cartItems.length;
  // if (!cartItems || !cartItems.length) return 0;

  // return cartItems.reduce((acc, item) => acc + item.quantity, 0);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = INITIAL_STATE,
  action = {
    type: '',
    payload: {
      selectedPaymentMethod: {},
      cartId: '',
      cartItems: [],
      cartInfo: {
        items: [],
        applied_coupons: null,
      },
      customerAddress: [],
    },
    meta: {},
    group: '',
  }
) => {
  if (action.group !== REDUCER_GROUP.CHECKOUT) return state;

  switch (action.type) {
    case ACTION_TYPE.SAVE_CART_TOKEN:
      const { cartId } = action.payload;

      if (!auth.loggedIn()) {
        localStorage.setItem('guest-cart-id', cartId);
      }

      return Object.assign({}, state, {
        cartId: cartId || '',
      });

    case ACTION_TYPE.CLEAR_CART:
      return Object.assign({}, INITIAL_STATE);

    case ACTION_TYPE.SAVE_CART_ITEMS: {
      const { cartItems } = action.payload;

      return Object.assign({}, state, {
        cartInfo: Object.assign({}, state.cartInfo, { items: cartItems || [] }),
        totalItemsInCart: calcTotalItemsInCart(cartItems),
      });
    }

    case ACTION_TYPE.SAVE_CART_INFO: {
      const { cartInfo } = action.payload;
      // @ts-ignore
      const {items: cartItems} = cartInfo ? cartInfo : [];

      if (!auth.loggedIn()) {
        localStorage.setItem('guest-cart-id', state.cartId);
      }

      return Object.assign({}, state, {
        cartInfo: Object.assign({}, state.cartInfo, cartInfo, {
          applied_coupons: cartInfo?.applied_coupons ? cartInfo.applied_coupons : null,
        }),
        totalItemsInCart: calcTotalItemsInCart(cartItems),
      });
    }

    case ACTION_TYPE.SAVE_CUSTOMER_ADDRESS: {
      const { customerAddress } = action.payload;

      return Object.assign({}, state, {
        customerAddress,
      });
    }

    case ACTION_TYPE.UPDATE_SELECTED_PAYMENT_METHOD: {
      const { selectedPaymentMethod } = action.payload;

      return Object.assign({}, state, {
        cartInfo: Object.assign({}, state.cartInfo, {
          selected_payment_method: selectedPaymentMethod || {},
        }),
      });
    }

    default:
      return state;
  }
};
