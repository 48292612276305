import React, {useState} from 'react';
import classname from 'classnames';
import {NavLink} from 'react-router-dom';
import BlogSidebar from './BlogSidebar';
import {renderHtmlContent} from '../../utils/html';
import {scrollElement} from '../../utils/scroll';
import styles from './DetailsContentTwo.module.scss';

function CommentItem(item, index) {
    if (!item) return null;

    const handleOnClick = (e) => {
        e.preventDefault();

        const commentsRespondForm = document.getElementById(
            'comments-respond-form'
        );
        if (!commentsRespondForm) return;

        this.onReplyTo(item);
        scrollElement({
            x: 0,
            y: commentsRespondForm.offsetTop,
            isAnimation: true,
        });
    };

    return (
        <li className='comment' key={`comment-item-${item.comment_id}`}>
            <article className='comment-body'>
                <footer className='comment-meta'>
                    <div className='comment-author vcard'>
                        <div
                            className='avatar'
                            style={{
                                border: '1px solid #ccc',
                                background: '#eee',
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                lineHeight: '50px',
                                fontWeight: 700,
                                color: '#aaa',
                                fontSize: 30,
                            }}
                        >
                            {item.name[0]}
                        </div>
                        {/* <img  alt=' ' /> */}
                        <b className='fn'>{item.name}</b>
                        <span className='says'>says:</span>
                    </div>

                    <div className='comment-metadata'>
                        <time>{item.updated_at}</time>
                    </div>
                </footer>

                <div className='comment-content'>
                    <p>{item.message}</p>
                </div>

                <div className='reply'>
                    <NavLink
                        to=''
                        key={`reply-comment-item-${item.comment_id}`}
                        className='comment-reply-link'
                        onClick={handleOnClick}
                    >
                        Reply
                    </NavLink>
                </div>
            </article>
            {!!item.children && (
                <ol className='children'>
                    {item.children.map(CommentItem, {onReplyTo: this.onReplyTo})}
                </ol>
            )}
        </li>
    );
}

export default (props) => {
    const {post, comments, totalComment, onSubmit, isAddCommentLoading} = props;
    if (!post) return null;

    const [replyTo, setReplyTo] = useState(null);
    const [replyToContent, setReplyToContent]: any = useState({});
    const handleOnReplyTo = (item) => {
        setReplyTo(item.comment_id);
        setReplyToContent(item);
    };

    const [message, setMessage] = useState('');
    const handleChangeMessage = (e) => setMessage(e.target.value);

    const [name, setName] = useState('');
    const handleChangeName = (e) => setName(e.target.value);

    const [email, setEmail] = useState('');
    const handleChangeEmail = (e) => setEmail(e.target.value);

    const handleOnSubmit = (e) => {
        e.preventDefault();
        if (!!isAddCommentLoading) return;
        onSubmit({replyTo, message, name, email});

        setTimeout(() => {
            setMessage('');
            setEmail('');
            setName('');
            setReplyTo(0);
        });
    };

    return (
        <section className='blog-details-area ptb-60'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-8'>
                        {!!post && (
                            <div className='blog-details'>
                                <div className='article-img'>
                                    <img src={post.post_thumbnail} alt=' '/>
                                </div>

                                <div className='article-content'>
                                    <ul className='entry-meta'>
                                        <li>
                                            <i className='far fa-calendar-alt'></i>{' '}
                                            {post.published_at}
                                        </li>
                                        <li>
                                            <i className='far fa-comment-dots'></i> {totalComment}{' '}
                                            Comment
                                        </li>
                                    </ul>

                                    <h3>{post.title}</h3>
                                    {renderHtmlContent({content: post.full_content})}

                                    <ul className='category'>
                                        <li>
                                            <span>Tags:</span>
                                        </li>
                                        {post.tags.map((item, idx) => {
                                            return (
                                                <React.Fragment key={idx}>
                                                    <li>
                                                        <NavLink to='#'>{item}</NavLink>
                                                    </li>
                                                </React.Fragment>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        )}
                        <div className='comments-area'>
                            <h3 className='comments-title'>{totalComment} Comments:</h3>
                            <ol className='comment-list'>
                                {comments.map(CommentItem, {onReplyTo: handleOnReplyTo})}
                            </ol>

                            <div className='comment-respond' id='comments-respond-form'>
                                <h3 className='comment-reply-title'>Leave a Reply</h3>

                                <form className='comment-form' onSubmit={handleOnSubmit}>
                                    <p className='comment-notes'>
                                        <span id='email-notes'>
                                          Your email address will not be published.
                                        </span>
                                        Required fields are marked <span className='required'>*</span>
                                    </p>

                                    {!!replyTo && (
                                        <div className={styles.reply}>
                                            <div className={styles.content}>
                                                <b>Reply to: </b>
                                                <b>{replyToContent.name}</b>
                                                <span>{replyToContent.message}</span>
                                            </div>
                                            <i
                                                onClick={() => setReplyTo(null)}
                                                className={classname('fas fa-times', styles.close)}
                                            ></i>
                                        </div>
                                    )}

                                    <p className='comment-form-comment'>
                                        <label>
                                            Comment <span className='required'>*</span>
                                        </label>
                                        <textarea
                                            name='comment'
                                            id='comment'
                                            cols={45}
                                            rows={5}
                                            maxLength={65525}
                                            required={true}
                                            value={message}
                                            onChange={handleChangeMessage}
                                        />
                                    </p>
                                    <p className='comment-form-author'>
                                        <label>
                                            Name <span className='required'>*</span>
                                        </label>
                                        <input
                                            type='text'
                                            id='author'
                                            name='author'
                                            value={name}
                                            required={true}
                                            onChange={handleChangeName}
                                        />
                                    </p>
                                    <p className='comment-form-email'>
                                        <label>
                                            Email <span className='required'>*</span>
                                        </label>
                                        <input
                                            type='email'
                                            id='email'
                                            name='email'
                                            value={email}
                                            required={true}
                                            onChange={handleChangeEmail}
                                        />
                                    </p>
                                    <p className='form-submit'>
                                        <input
                                            type='submit'
                                            name='submit'
                                            id='submit'
                                            className='submit'
                                            value={
                                                isAddCommentLoading ? 'Loadding...' : 'Post Comment'
                                            }
                                        />
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <BlogSidebar/>
                    </div>
                </div>
            </div>
        </section>
    );
};
