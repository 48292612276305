import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {toastAlert} from '../../utils/toastify';
import {useMutation, useLazyQuery} from '@apollo/client';
import {
    GET_WISH_LIST,
    ADD_PRODUCT_TO_WISH_LIST,
    REMOVE_PRODUCT_FROM_WISH_LIST,
    ADD_CONFIGURABLE_PRODUCT_TO_WISH_LIST,
} from '../../graphql/customer.gql';
import {saveWishlistInfoAction} from '../../flows/wishlist/action';
import {checkWishlist} from '../../utils/wishlist';

import QuickView from '../Modal/QuickView';
import * as ROUTINGS from '../../routings/path';
import AddToCart from '../Shared/AddToCart';
import OwlCarousel from 'react-owl-carousel3';
import styles from './Products.module.scss';

const options = {
    loop: false,
    rewind: true,
    nav: true,
    smartSpeed: 750,
    dots: false,
    autoplayHoverPause: true,
    margin: 30,
    autoplay: true,
    navText: [
        "<i class='fas fa-chevron-left'></i>",
        "<i class='fas fa-chevron-right'></i>",
    ],
    responsive: {
        0: {
            items: 1,
        },
        576: {
            items: 2,
        },
        768: {
            items: 2,
        },
        1024: {
            items: 3,
        },
        1200: {
            items: 4,
        },
    },
};

const PopularProducts = (props: any) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [display, setDisplay] = useState(true);

    const openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName('tabs_item_popular');
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].classList.remove('fadeInUp');
            tabcontent[i].style.display = 'none';
        }

        tablinks = document.getElementsByTagName('li');
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace('current', '');
        }

        document.getElementById(tabNmae).style.display = 'block';
        document.getElementById(tabNmae).className += ' fadeInUp animated';
        evt.currentTarget.className += 'current';
    };

    useEffect(() => {
        setDisplay(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleModalData = (data) => {
        setModalData(data);
    };

    let {popularProducts, wishlistState, authState, saveWishlistInfoAction} =
        props;
    const wishlistId = wishlistState.info && wishlistState.info.id;
    const wishlistItems = (wishlistState.info && wishlistState.info.items) || [];

    const handleWishlist = (e, data, wishlistItemId, rootProduct) => {
        e.preventDefault();

        if (!authState.info) {
            toastAlert({
                type: 'error',
                message: 'Please login to wishlist',
            });
            return;
        }

        const {sku} = data;
        if (!wishlistId || !sku) return;
        if (
            !!isAddWishlistLoading ||
            !!isAddConfigurableWishlistLoading ||
            !!isRemoveWishlistLoading
        )
            return;

        if (!!wishlistItemId) {
            removeProductFromWishlist({
                variables: {
                    wishlistId,
                    id: wishlistItemId,
                },
            });
        } else {
            if (!!rootProduct.configurableOptions) {
                addConfigurableProductToWishlist({
                    variables: {
                        parentSku: rootProduct.sku,
                        wishlistId,
                        sku,
                    },
                });
            } else {
                addProductToWishlist({
                    variables: {
                        wishlistId,
                        sku,
                    },
                });
            }
        }
    };
    const [addProductToWishlist, {loading: isAddWishlistLoading}]: any =
        useMutation(ADD_PRODUCT_TO_WISH_LIST, {
            onCompleted: () => {
                toastAlert({
                    type: 'success',
                    message: 'Adding product to with list success',
                });
                getWishList();
            },
            onError: ({message}) => toastAlert({type: 'error', message}),
        });

    const [
        addConfigurableProductToWishlist,
        {loading: isAddConfigurableWishlistLoading},
    ]: any = useMutation(ADD_CONFIGURABLE_PRODUCT_TO_WISH_LIST, {
        onCompleted: () => {
            toastAlert({
                type: 'success',
                message: 'Adding product to with list success',
            });
            getWishList();
        },
        onError: ({message}) => toastAlert({type: 'error', message}),
    });

    const [removeProductFromWishlist, {loading: isRemoveWishlistLoading}]: any =
        useMutation(REMOVE_PRODUCT_FROM_WISH_LIST, {
            onCompleted: () => {
                toastAlert({
                    type: 'info',
                    message: 'Removing product from with list success',
                });
                getWishList();
            },
            onError: ({message}) => toastAlert({type: 'error', message}),
        });

    const [getWishList]: any = useLazyQuery(GET_WISH_LIST, {
        fetchPolicy: 'network-only',
        onCompleted: ({customer}) =>
            customer && saveWishlistInfoAction(customer.wishlist),
    });

    return (
        <section className='popular-products-area pb-60'>
            <div className='container'>
                <div className='tab products-category-tab-style-2'>
                    <div className='title'>
                        <h2>
                            <span className='dot'></span> Popular Products
                        </h2>
                    </div>

                    {/* Tabs Nav */}
                    <ul className='tabs'>
                        <li
                            onClick={(e) => {
                                e.preventDefault();
                                openTabSection(e, 'tab1');
                            }}
                            className='current'
                        >
                            {/*<span>All</span>*/}
                        </li>
                    </ul>

                    {/* Tab Content */}
                    <div className='tab_content'>
                        <div id='tab1' className='tabs_item_popular'>
                            {display ? (
                                <OwlCarousel
                                    className='product-slides owl-carousel owl-theme'
                                    {...options}
                                >
                                    {popularProducts.map((data, idx) => {
                                        const selectedProduct = !!data.configurableOptions
                                            ? data.variants &&
                                            data.variants[0] &&
                                            data.variants[0].product
                                            : data;
                                        const wishlistItemId = checkWishlist({
                                            wishlistItems,
                                            data,
                                        });
                                        return (
                                            <div className='single-product-item ' key={idx}>
                                                <div className='product-image'>
                                                    <NavLink
                                                        to={`${ROUTINGS.ROUTING_PRODUCT_DETAIL_PATH}/${data.slug}`}
                                                        style={{
                                                            position: 'relative',
                                                            paddingTop: '113.3%',
                                                        }}
                                                    >
                                                        <img
                                                            src={data.small_image}
                                                            alt={data.image_label}
                                                            style={{
                                                                position: 'absolute',
                                                                top: '0',
                                                                left: '0',
                                                                width: '100%',
                                                                height: '100%',
                                                                objectFit: 'cover',
                                                            }}
                                                        />
                                                    </NavLink>
                                                </div>

                                                <div className='product-content'>
                                                    <h3>
                                                        <NavLink
                                                            to={`${ROUTINGS.ROUTING_PRODUCT_DETAIL_PATH}/${data.slug}`}
                                                            style={{
                                                                fontSize: '16px',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                display: '-webkit-box',
                                                                WebkitBoxOrient: 'vertical',
                                                                lineHeight: '20px',
                                                                height: 40,
                                                                WebkitLineClamp: 2,
                                                            }}
                                                        >
                                                            {data.title}
                                                        </NavLink>
                                                    </h3>

                                                    <div className='product-price'>
                                                        {!!data.oldPrice && (
                                                            <span
                                                                style={{
                                                                    textDecoration: 'line-through',
                                                                    opacity: 0.4,
                                                                    marginRight: 10,
                                                                }}
                                                                className='new-price'
                                                            >
                                                                ${data.oldPrice}
                                                            </span>
                                                        )}
                                                        <span className='new-price'>${data.price}</span>
                                                    </div>

                                                    <div className='row align-items-end'>
                                                        <div className='col-lg-7 col-md-6 col-7'>
                                                            <AddToCart product={data}/>
                                                        </div>

                                                        <div className='col-lg-5 col-md-6 col-5'>
                                                            <ul>
                                                                <li>
                                                                    <NavLink
                                                                        to='#'
                                                                        data-tip='Quick View'
                                                                        data-place='left'
                                                                        onMouseDown={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            openModal();
                                                                            handleModalData(data.slug);
                                                                        }}
                                                                    >
                                                                        <i className='far fa-eye'></i>
                                                                    </NavLink>
                                                                </li>
                                                                <li
                                                                    className={!!wishlistItemId ? styles.visible : ''}
                                                                >
                                                                    <NavLink
                                                                        to='#'
                                                                        onClick={(e) =>
                                                                            handleWishlist(
                                                                                e,
                                                                                selectedProduct,
                                                                                wishlistItemId,
                                                                                data
                                                                            )
                                                                        }
                                                                        data-tip='Add to Wishlist'
                                                                        data-place='left'
                                                                    >
                                                                        <i className={`${!!wishlistItemId ? 'fas' : 'far'} fa-heart`}
                                                                        ></i>
                                                                    </NavLink>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </OwlCarousel>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {modalOpen ? (
                <QuickView closeModal={closeModal} modalData={modalData}/>
            ) : (
                ''
            )}
        </section>
    );
};

export const mapStateToProps = (state) => ({
    wishlistState: state.wishlist,
    authState: state.auth,
});

export const mapDispatchToProps = (dispatch) => ({
    saveWishlistInfoAction: (data) => dispatch(saveWishlistInfoAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PopularProducts);
