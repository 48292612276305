import * as ACTION_TYPE from './type';
import { REDUCER_GROUP } from '../reducer.group';

export const initAppAction = (data) => ({
  type: ACTION_TYPE.INIT_APP,
  payload: { data },
  group: REDUCER_GROUP.APP,
});

export const saveStoreConfigAction = (data) => ({
  type: ACTION_TYPE.SAVE_STORE_CONFIG,
  payload: data,
  group: REDUCER_GROUP.APP,
});
