import {REDUCER_GROUP} from '../reducer.group';
import * as ACTION_TYPE from './type';

export const INITIAL_STATE = {
    initData: null,
    sociallogin_facebook_is_enabled: false,
    sociallogin_facebook_app_id: '',
    sociallogin_google_is_enabled: false
};

const Reducer = (
    state = INITIAL_STATE,
    action = {
        type: '',
        payload: {
            data: {},
            sociallogin_facebook_is_enabled: false,
            sociallogin_facebook_app_id: '',
            sociallogin_google_is_enabled: false,
            default_country: ''
        },
        meta: {},
        group: '',
    }
) => {
    if (action.group !== REDUCER_GROUP.APP) return state;

    switch (action.type) {
        case ACTION_TYPE.INIT_APP:
            const {data} = action.payload;

            return Object.assign({}, state, {
                initData: (data || {id: 1}),
            });

        case ACTION_TYPE.SAVE_STORE_CONFIG:
            const {
                sociallogin_facebook_is_enabled,
                sociallogin_facebook_app_id,
                sociallogin_google_is_enabled,
                default_country
            } = action.payload;

            return Object.assign({}, state, {
                sociallogin_facebook_is_enabled,
                sociallogin_facebook_app_id,
                sociallogin_google_is_enabled,
                default_country
            });

        default:
            return state;
    }
};

export default Reducer;
