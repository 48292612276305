export const transformComment = (comments) => {
  let rootList = [];
  let childrenList = [];

  comments.items.map((commentItem) => {
    if (!commentItem.reply_to) {
      rootList.push(createCommentItem(commentItem));
    } else {
      childrenList.push(createCommentItem(commentItem));
    }
    
    return true;
  });

  childrenList.map((children) => {
    rootList = rootList.map((root) => {
      return insertChildItem(root, children);
    });
    
    return true;
  });

  return rootList;
};

const createCommentItem = (comment) => ({
  ...comment,
  children: [],
});

const insertChildItem = (parent, child) => {
  if (parent.comment_id * 1 === child.reply_to * 1) {
    return {
      ...parent,
      children: [...parent.children, child],
    };
  }

  const findChild = parent.children.map((item) => insertChildItem(item, child));
  return {
    ...parent,
    children: findChild,
  };
};
