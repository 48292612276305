import { initAppAction, saveStoreConfigAction } from '../../flows/app/action';
import {clearUerInfoAction, saveUerInfoAction} from '../../flows/user/action';
import {clearWishlistAction, saveWishlistInfoAction} from '../../flows/wishlist/action';
import {clearCompareData, saveCompareInfo, saveCompareList} from '../../flows/compare/action';
import {
  saveCartTokenAction,
  saveCartInfoAction,
} from '../../flows/checkout/action';
import {signOutAction} from "../../flows/auth/action";

export const mapStateToProps = (state) => ({
  appState: state.app,
  authState: state.auth,
  checkoutState: state.checkout,
  compareState: state.compare,
});

export const mapDispatchToProps = (dispatch) => ({
  initAppAction: (data: any) => dispatch(initAppAction(data)),
  saveUerInfoAction: (data: any) => dispatch(saveUerInfoAction(data)),
  saveCartTokenAction: (data: any) => dispatch(saveCartTokenAction(data)),
  saveCartInfoAction: (data: any) => dispatch(saveCartInfoAction(data)),
  saveWishlistInfoAction: (data: any) => dispatch(saveWishlistInfoAction(data)),
  saveCompareInfo: (data: any) => dispatch(saveCompareInfo(data)),
  saveCompareList: (data: any) => dispatch(saveCompareList(data)),
  saveStoreConfigAction: (data: any) => dispatch(saveStoreConfigAction(data)),
  signOutAction: () => dispatch(signOutAction()),
  clearUerInfoAction: () => dispatch(clearUerInfoAction()),
  clearWishlistAction: () => dispatch(clearWishlistAction()),
  clearCompareData: () => dispatch(clearCompareData()),
});
