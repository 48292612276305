import React, {useEffect, useState} from 'react';
import {GET_INSTAGRAM_SQL} from '../../graphql/layout.gql';
import {useLazyQuery} from '@apollo/client';
import {toastAlert} from '../../utils/toastify';
import Lightbox from 'react-image-lightbox';
import {NavLink} from 'react-router-dom';
import Facility from '../../components/Common/Facility';
import Breadcrumb from '../../components/Common/Breadcrumb';
import Meta from "../../components/meta";

const images = [];

const Index = () => {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpenImage, setisOpenImage] = useState(false);
    const [gallery, setGallery] = useState(null);

    const init = () => {
        getGallery();
    };
    const setImage = (array) => {
        array.map((item) => {
            images.push(item.image_url);
            return true;
        });
    };
    const [getGallery]: any = useLazyQuery(GET_INSTAGRAM_SQL, {
        fetchPolicy: 'network-only',
        onCompleted: ({getGalleriesByCategoryId}) => {
            setGallery(getGalleriesByCategoryId.items);
            setImage(getGalleriesByCategoryId.items);
        },
        onError: ({message}) => toastAlert({type: 'error', message}),
    });

    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [meta] = useState({
        title: "Gallery page",
        description: "",
        keyword: ""
    });

    return (
        <>
            <Meta
                title={meta.title}
                description={meta.description}
                keywords={meta.keyword}
            />
            <Breadcrumb title='Gallery'/>

            <section className='gallery-area ptb-60'>
                <div className='container'>
                    <div className='section-title'>
                        <h2>
                            <span className='dot'></span> Gallery
                        </h2>
                    </div>

                    <div className='row m-0'>
                        {!!gallery &&
                            !!gallery.length &&
                            gallery.map((data, idx) => {
                                return (
                                    <>
                                        <div className='col-lg-4 col-md-6 p-0' key={idx}>
                                            <div className='single-gallery-item'>
                                                <img src={data.image_url} alt=''/>

                                                <div className='gallery-content'>
                                                    <span>{data.alt}</span>
                                                    <h3>{data.name}</h3>
                                                </div>

                                                <NavLink to='#'
                                                         className={'popup-btn'}
                                                         onClick={(e) => {
                                                             e.preventDefault();
                                                             setisOpenImage(true);
                                                             setPhotoIndex(idx);
                                                         }}
                                                >
                                                </NavLink>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}

                        {isOpenImage && (
                            <Lightbox
                                mainSrc={images[photoIndex]}
                                nextSrc={images[(photoIndex + 1) % images.length]}
                                prevSrc={
                                    images[(photoIndex + images.length - 1) % images.length]
                                }
                                onCloseRequest={() => setisOpenImage(false)}
                                onMovePrevRequest={() =>
                                    setPhotoIndex(
                                        (photoIndex + images.length - 1) % images.length
                                    )
                                }
                                onMoveNextRequest={() =>
                                    setPhotoIndex((photoIndex + 1) % images.length)
                                }
                            />
                        )}
                    </div>
                </div>
            </section>

            <Facility/>
        </>
    );
};

export default Index;
