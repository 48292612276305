import { connect } from "react-redux";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { checkEmptryString } from "../../../utils/validation";
import { toastAlert } from "../../../utils/toastify";
import * as ROUTINGS from "../../../routings/path";
import { SIGN_IN_GQL, SIGN_UP_GQL } from "../../../graphql/customer.gql";

import { mapStateToProps, mapDispatchToProps } from "./state";
import Facility from "../../../components/Common/Facility";
import Meta from "../../../components/meta";

interface IProps {
  saveAuthInfoAction: Function;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props: IProps) => {
  const { saveAuthInfoAction } = props;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [birthday, setBirthday] = useState("");
  const [isSubscribed, setSubscribed] = useState(true);

  const handleChangeFirstName = (e) => setFirstName(e.target.value);
  const handleChangelastName = (e) => setLastName(e.target.value);
  const handleChangeEmail = (e) => setEmail(e.target.value);
  const handleChangePassword = (e) => setPassword(e.target.value);
  const handleChangePhone = (e) => setPhone(e.target.value);
  const handleChangeBirthday = (e) => setBirthday(e.target.value);
  const hanndleChangeSubscribed = (e) => setSubscribed(!isSubscribed);

  const [submitSignUp, { loading: isSignUpLoading }]: any = useMutation(
    SIGN_UP_GQL,
    {
      onCompleted: () => {
        toastAlert({
          type: "success",
          message: "Register new account successful",
        });
        submitSignIn({ variables: { email, password } });
      },
      onError: ({ message }) => toastAlert({ type: "error", message }),
    }
  );

  const [submitSignIn, { loading: isSignInLoading }]: any = useMutation(
    SIGN_IN_GQL,
    {
      onCompleted: ({ generateCustomerToken }) =>
        saveAuthInfoAction(generateCustomerToken),
    }
  );

  const isSubmitLoading = isSignUpLoading || isSignInLoading;

  const validateForm = () => {
    if (checkEmptryString(firstName)) {
      toastAlert({ type: "error", message: "Error: First name is invalid." });
      return false;
    }

    if (checkEmptryString(lastName)) {
      toastAlert({ type: "error", message: "Error: Last name is invalid." });
      return false;
    }

    if (checkEmptryString(password)) {
      toastAlert({ type: "error", message: "Error: Password is invalid." });
      return false;
    }

    return true;
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (!validateForm()) return false;

    submitSignUp({
      variables: {
        input: {
          firstname: firstName,
          lastname: lastName,
          email,
          password,
          phone,
          date_of_birth: birthday,
          is_subscribed: isSubscribed,
        },
      },
    });
  };

  const [meta] = useState({
    title: "Register page",
    description: "",
    keyword: ""
  });

  return (
    <>
      <Meta
          title={meta.title}
          description={meta.description}
          keywords={meta.keyword}
      />
      <section className="signup-area ptb-60">
        <div className="container">
          <div className="signup-content">
            <div className="section-title">
              <h2>
                <span className="dot"></span> Create an Account
              </h2>
            </div>

            <form className="signup-form" onSubmit={handleSubmitForm}>
              <div className="form-group">
                <label>
                  First Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  value={firstName}
                  required
                  onChange={handleChangeFirstName}
                  className="form-control"
                  placeholder="Enter your name"
                  id="fname"
                  name="fname"
                />
              </div>

              <div className="form-group">
                <label>
                  Last Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  value={lastName}
                  required
                  onChange={handleChangelastName}
                  className="form-control"
                  placeholder="Enter your name"
                  id="lname"
                  name="lname"
                />
              </div>

              <div className="form-group">
                <label>
                  Email <span className="required">*</span>
                </label>
                <input
                  type="email"
                  value={email}
                  required
                  onChange={handleChangeEmail}
                  className="form-control"
                  placeholder="Enter your name"
                  id="name"
                  name="name"
                />
              </div>

              <div className="form-group">
                <label>
                  Password <span className="required">*</span>
                </label>
                <input
                  type="password"
                  value={password}
                  required
                  onChange={handleChangePassword}
                  className="form-control"
                  placeholder="Enter your password"
                  id="password"
                  name="password"
                />
              </div>

              <div className="form-group">
                <label>
                  Phone <span className="required">*</span>
                </label>
                <input
                  type="tel"
                  value={phone}
                  required
                  onChange={handleChangePhone}
                  className="form-control"
                  placeholder="Enter your phone"
                  title="Invalid phone number format, use like +2923432432432"
                  id="phone"
                  name="phone"
                  pattern="^[+]?\d{9,12}$"
                />
              </div>

              <div className="form-group">
                <label>Birthday</label>
                <input
                  type="date"
                  value={birthday}
                  onChange={handleChangeBirthday}
                  className="form-control"
                  placeholder="Enter your birthday"
                  id="birthday"
                  name="birthday"
                />
              </div>
              <div className='pb-3 form-check form-switch'>
                <input
                  checked={isSubscribed}
                  onChange={hanndleChangeSubscribed}
                  className='form-check-input'
                  type='checkbox'
                  id='isSubscribed'
                />
                <label
                  style={{ cursor: 'pointer' }}
                  className='form-check-label'
                  htmlFor='isSubscribed'
                >
                  Is Subscribed
                </label>
              </div>

              <button
                type="submit"
                disabled={isSubmitLoading}
                className="btn btn-primary"
              >
                {!isSubmitLoading ? (
                  "Signup"
                ) : (
                  <span className="spinner-grow spinner-grow-sm" />
                )}
              </button>
              <NavLink to={ROUTINGS.ROUTING_AUTH_SIGN_IN} className={'return-store'}>
                or Return Sign in
              </NavLink>
            </form>
          </div>
        </div>
      </section>
      <Facility />
    </>
  );
});
