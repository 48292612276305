import axios from 'axios';
import {toastAlert} from "../utils/toastify";

export default function callGetApi(endpoint, isNeedAuth = false, isNeedCustomerGroupId = false, method = 'GET') {
    const Store = localStorage.getItem('store') || null;
    const currency = localStorage.getItem('currency') || null;
    let customer_group_id = null;

    let authorization = null;
    if (isNeedAuth === true) {
        const token = localStorage.getItem('token');
        authorization = token ? `Bearer ${token}` : null;
    }

    if (isNeedCustomerGroupId === true) {
        customer_group_id = localStorage.getItem('customer-group-id') || null;
    }
    
    let headers = Object.assign(
        {},
        Store && {Store},
        authorization && {authorization},
        currency && {"Content-Currency": currency},
        customer_group_id && {"Customer-Group-Id": customer_group_id}
    );

    // @ts-ignore
    return axios({
        method,
        headers,
        url: `${process.env.REACT_APP_GRAPHQL_URI}?query=${endpoint}`,
    }).catch((err) => {
        console.log(err);
    });
}

export function getCustomerDataWithToken(endpoint, token, method = 'GET') {
    const Store = localStorage.getItem('store') || null;
    const currency = localStorage.getItem('currency') || null;
    const authorization = token ? `Bearer ${token}` : null;

    let headers = Object.assign(
        {},
        Store && {Store},
        authorization && {authorization},
        currency && {"Content-Currency": currency},
    );

    // @ts-ignore
    return axios({
        method,
        headers,
        url: `${process.env.REACT_APP_GRAPHQL_URI}?query=${endpoint}`,
    }).catch((err) => {
        console.log(err);
    });
}

export function toAstError(errors) {
    Object.keys(errors).map(function (key) {
        toastAlert({type: 'error', message: errors[key].message})
        return false
    });
}
