import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {toastAlert} from '../../utils/toastify';
import {renderHtmlContent} from '../../utils/html';
import SizeGuide from './SizeGuide';
import Shipping from './Shipping';
import {RatingValue} from '../../utils/rating';
import {checkWishlist} from '../../utils/wishlist';
import {QUERY_GET_CUSTOM_PAYMENT_AREA_GQL} from '../../graphql/layout.gql';
import {
    GET_WISH_LIST,
    ADD_PRODUCT_TO_WISH_LIST,
    ADD_CONFIGURABLE_PRODUCT_TO_WISH_LIST,
    REMOVE_PRODUCT_FROM_WISH_LIST,
} from '../../graphql/customer.gql';
import {
    ADD_PRODUCT_TO_COMPARE_LIST,
    GET_COMPARE_LIST,
    REMOVE_PRODUCT_FROM_COMPARE_LIST,
} from '../../graphql/customer.gql';
import {useLazyQuery, useMutation} from '@apollo/client';
import callGetApi, {toAstError} from '../../api';

export default (props) => {
    const [loadingTarget, setLoadingTarget] = useState('');
    const [sizeGuide, setSizeGuide] = useState(false);
    const [shipModal, setShipModal] = useState(false);
    const [qty, setQty]: any = useState(1);
    const DecreaseItem = () => qty > 1 && setQty(qty * 1 - 1);
    const IncrementItem = () => setQty(qty * 1 + 1);

    const {
        product,
        authState,
        wishlistId,
        onAddToCart,
        compareListUUID,
        compareData,
        wishlistItems,
        isAddToCartLoading,
        saveWishlistInfoAction,
        saveCompareList,
        onFetchNewInfo,
    } = props;
    if (!product) return null;

    const initProduct = !!product.configurableOptions
        ? product.variants && product.variants[0] && product.variants[0].product
        : product;
    const [wishlistItemId, setWishlistItemId] = useState(checkWishlist({wishlistItems, data: product}));
    const [currentProduct, setCurrentProduct] = useState(initProduct);
    const handleUpdateCurrentProduct = () => {
        const selectedVariant = getSelectedVariant();
        if (!!selectedVariant) setCurrentProduct(selectedVariant.product);
    };

    useEffect(() => {
        setWishlistItemId(checkWishlist({wishlistItems, data: product}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wishlistItems, currentProduct]);

    const handleWishlist = () => {
        if (!authState.info) {
            toastAlert({
                type: 'error',
                message: 'Please login to wishlist',
            });
            return;
        }

        const {sku} = currentProduct;
        if (!wishlistId || !sku) return;
        if (!!isAddWishlistLoading || !!isRemoveWishlistLoading) return;

        if (!!wishlistItemId) {
            removeProductFromWishlist({
                variables: {
                    wishlistId,
                    id: wishlistItemId,
                },
            });
        } else {
            if (!!product.configurableOptions) {
                addConfigurableProductToWishlist({
                    variables: {
                        parentSku: product.sku,
                        wishlistId,
                        sku,
                    },
                });
            } else {
                addProductToWishlist({
                    variables: {
                        wishlistId,
                        sku,
                    },
                });
            }
        }
    };

    const [addProductToWishlist, {loading: isAddWishlistLoading}]: any =
        useMutation(ADD_PRODUCT_TO_WISH_LIST, {
            onCompleted: () => {
                toastAlert({
                    type: 'success',
                    message: 'Adding product to with list success',
                });
                getWishList();
            },
            onError: ({message}) => toastAlert({type: 'error', message}),
        });

    const [
        addConfigurableProductToWishlist,
        {loading: isAddConfigurableWishlistLoading},
    ]: any = useMutation(ADD_CONFIGURABLE_PRODUCT_TO_WISH_LIST, {
        onCompleted: () => {
            toastAlert({
                type: 'success',
                message: 'Adding product to with list success',
            });
            getWishList();
        },
        onError: ({message}) => toastAlert({type: 'error', message}),
    });

    const [removeProductFromWishlist, {loading: isRemoveWishlistLoading}]: any =
        useMutation(REMOVE_PRODUCT_FROM_WISH_LIST, {
            onCompleted: () => {
                toastAlert({
                    type: 'info',
                    message: 'Removing product from with list success',
                });
                getWishList();
            },
            onError: ({message}) => toastAlert({type: 'error', message}),
        });

    const isWishlistLoading =
        !!isAddWishlistLoading ||
        !!isRemoveWishlistLoading ||
        !!isAddConfigurableWishlistLoading;

    const [getWishList]: any = useLazyQuery(GET_WISH_LIST, {
        fetchPolicy: 'network-only',
        onCompleted: ({customer}) =>
            customer && saveWishlistInfoAction(customer.wishlist),
    });

    const initSelectedVariants =
        product?.configurableOptions &&
        product?.configurableOptions.map((item) => {
            return {
                key: item.attribute_code,
                uid: item.uid,
                value: null,
                is_selected: false,
            };
        });
    const [selectedVariants, setSelectedVariants] =
        useState(initSelectedVariants);

    const initAvailableVariantProducts =
        product?.variants &&
        product?.variants.map((item) => {
            let array = [];
            item?.attributes.map((attribute) => {
                array.push(attribute.uid);
                return true;
            });
            return array;
        });

    const [availableVariantProducts, setAvailableVariantProducts] = useState(
        initAvailableVariantProducts
    );

    const upAvailableProducts = () => {
        if (!availableVariantProducts) return false;

        selectedVariants.map((item) => {
            if (item?.value) {
                initAvailableVariantProducts.map((initAvailableVariantProduct, idx) => {
                    if (!initAvailableVariantProduct.includes(item.value.uid)) {
                        delete initAvailableVariantProducts[idx];
                    }

                    return true;
                });
            }

            return true;
        });

        let newAvailableVariantProducts = initAvailableVariantProducts.filter(
            function (el) {
                return el != null;
            }
        );

        setAvailableVariantProducts(newAvailableVariantProducts);
    };

    const handleSelectVariant = (key, value) => {
        const newSelectedVariants = selectedVariants.map((item) => {
            if (item.key !== key) return item;

            if (
                item.key === key &&
                item.is_selected &&
                item.value.value_index === value.value_index
            ) {
                return {
                    key: item.key,
                    uid: item.uid,
                    value: null,
                    is_selected: false,
                };
            }

            return {
                key: item.key,
                uid: item.uid,
                value,
                is_selected: true,
            };
        });

        setSelectedVariants(newSelectedVariants);
    };

    useEffect(() => {
        handleUpdateCurrentProduct();
        detectPriceChange();
        upAvailableProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVariants]);

    const getSelectedVariant = () => {
        if (!product || !product.configurableOptions) return null;

        const {variants} = product;
        if (!variants || !variants.length) return null;

        return variants.find((variant) => {
            if (!variant || !variant.attributes) return false;

            let checMatch = true;
            variant.attributes.map((attribute) => {
                const findVariant = selectedVariants.find(
                    (item) => item.key === attribute.code
                );

                if (findVariant?.value?.value_index !== attribute?.value_index)
                    checMatch = false;
                return null;
            });

            return checMatch;
        });
    };

    const handleClickAddToCart = (type) => {
        if (!!isAddToCartLoading) return;
        // SIMPLE PRODUCT
        if (!product.configurableOptions) {
            onAddToCart({
                isCongifurableProduct: false,
                sku: product.sku,
                quantity: qty,
                type,
            });

            return;
        }

        const missingSelectedVariants = selectedVariants.find(
            (item) => !item.value
        );

        if (!!missingSelectedVariants) {
            return toastAlert({
                type: 'error',
                message: `Need to select ${missingSelectedVariants.key} variants`,
            });
        }

        const selectedVariant = getSelectedVariant();

        if (!selectedVariant)
            return toastAlert({
                type: 'error',
                message: 'This option(s) is disabled, please try another.',
            });

        onAddToCart({
            isCongifurableProduct: true,
            parentSku: product.sku,
            sku: selectedVariant.product.sku,
            quantity: qty,
            type,
        });
    };

    const detectPriceChange = () => {
        const selectedVariant = getSelectedVariant();
        if (!selectedVariant) return;

        const price =
            selectedVariant?.product?.price_range?.minimum_price?.regular_price
                ?.value || 0;

        const finalPrice =
            selectedVariant?.product?.price_range?.minimum_price?.final_price
                ?.value || 0;

        const newSku = selectedVariant.product && selectedVariant.product.sku;
        onFetchNewInfo && onFetchNewInfo(newSku);

        const salePrice = finalPrice ? finalPrice : price;

        if (!!salePrice) setPrice(salePrice);
        if (price !== finalPrice) setOldPrice(price);
    };

    const [oldPrice, setOldPrice] = useState(product.oldPrice);
    const [price, setPrice] = useState(product.price);

    const [customPayment, setCustomPayment] = useState(null);

    const init = () => {
        queryGetCustomPayment();
    };

    const queryGetCustomPayment = () => {
        callGetApi(QUERY_GET_CUSTOM_PAYMENT_AREA_GQL).then((res: any) => {
            let cmsBlocks =
                !!res.data &&
                !!res.data.data &&
                !!res.data.data.cmsBlocks &&
                res.data.data.cmsBlocks;

            if (cmsBlocks) {
                setCustomPayment(cmsBlocks.items);
            }

            if (res.data.errors) {
                let errors = res.data.errors;
                toAstError(errors);
            }
        });
    };

    const [isInCompareList, setInCompareList] = useState(false);

    const checkIsInCompareList = () => {
        if (!compareData || !compareData.items) return false;

        const checkIsInCompareList =
            !!compareData.items &&
            !!compareData.items.length &&
            compareData.items.find((item) => {
                if (!item || !item.product) return false;
                return item.product.url_key === product.slug;
            });

        setInCompareList(!!checkIsInCompareList);
    };

    useEffect(() => {
        checkIsInCompareList();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleCompare = (e) => {
        e.preventDefault();

        if (!compareData) return;

        if (!!isInCompareList) {
            removeProductFromComparelist({
                variables: {
                    compareListUUID,
                    productId: product.id,
                },
            });
        } else {
            addProductToComparelist({
                variables: {
                    compareListUUID,
                    productId: product.id,
                    trigger_report_event: true,
                },
            });
        }
    };

    const [addProductToComparelist, {loading: isAddComparelistLoading}]: any =
        useMutation(ADD_PRODUCT_TO_COMPARE_LIST, {
            onCompleted: () => {
                toastAlert({
                    type: 'success',
                    message: 'Adding product to compare list success',
                });
                !!compareListUUID &&
                !!compareListUUID.length &&
                getCompareList({variables: {compareListUUID}});
                setInCompareList(true);
            },
            onError: ({message}) => toastAlert({type: 'error', message}),
        });

    const [
        removeProductFromComparelist,
        {loading: isRemoveComparelistLoading},
    ]: any = useMutation(REMOVE_PRODUCT_FROM_COMPARE_LIST, {
        onCompleted: () => {
            toastAlert({
                type: 'success',
                message: 'Removing product from compare list success',
            });
            !!compareListUUID &&
            !!compareListUUID.length &&
            getCompareList({variables: {compareListUUID}});
            setInCompareList(false);
        },
        onError: ({message}) => toastAlert({type: 'error', message}),
    });

    const [getCompareList]: any = useLazyQuery(GET_COMPARE_LIST, {
        fetchPolicy: 'network-only',
        onCompleted: ({compareList}) => {
            saveCompareList({compareData: compareList});
        },
    });

    const openTabSection = (evt) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName('tabs_item');
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].classList.remove('fadeInUp');
            tabcontent[i].style.display = 'none';
        }

        tablinks = document.getElementsByTagName('li');
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace('current', '');
        }

        document.getElementById('tab5').style.display = 'block';
        document.getElementById('tab5').className += ' fadeInUp animated';
        evt.currentTarget.className += 'current';
    };

    useEffect(() => {
        init();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isCompareLoading =
        !!isAddComparelistLoading || !!isRemoveComparelistLoading;
    const compareTitle = isInCompareList ? 'Remove Compare' : 'Add to Compare';

    return (
        <>
            <div className='col-lg-6 col-md-6'>
                <div className='product-details-content'>
                    <h3>{product.name}</h3>

                    <div className='price'>
                        {!!oldPrice && (
                            <span
                                style={{
                                    textDecoration: 'line-through',
                                    opacity: 0.4,
                                    marginRight: 10,
                                }}
                                className='new-price'
                            >
                                ${oldPrice}
                            </span>
                        )}
                        <span className='new-price'>${price}</span>
                    </div>

                    <div className='product-review'>
                        <div className='rating'>
                            <RatingValue value={product.ratingSummary / 20}/>
                        </div>
                        <NavLink
                            to='#'
                            className='rating-count'
                            onClick={(e) => {
                                e.preventDefault();
                                openTabSection(e);

                                const scrollToTop =
                                    document.getElementById('product-space-tab');
                                !!scrollToTop &&
                                scrollToTop.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'start',
                                });
                            }}
                        >
                            <span>{product.reviewCount} reviews</span>
                        </NavLink>
                    </div>

                    <div>
                        {product &&
                            renderHtmlContent({content: product.shortDescription})}
                    </div>

                    <ul className='product-info' style={{marginBottom: '15px'}}>
                        <li>
                            <span>Availability:</span>{' '}
                            <NavLink to='#'>{product.stockStatus}</NavLink>
                        </li>
                    </ul>

                    {!!product.configurableOptions &&
                        product.configurableOptions.map((variant, idx) => {
                            if (!variant) return null;
                            if ('color' === variant.attribute_code) {
                                return (
                                    <React.Fragment key={idx}>
                                        <div className='product-color-switch'>
                                            <h4>{variant?.label}:</h4>
                                            <ul>
                                                {!!variant.values &&
                                                    variant.values.map((variantValues, idx) => {
                                                        const isSelected = selectedVariants.find(
                                                            (item) => item?.value?.uid === variantValues?.uid
                                                        );

                                                        let className = '';
                                                        if (isSelected) {
                                                            className = 'active';
                                                        }

                                                        selectedVariants.map((child) => {
                                                            if (child.key === variant.attribute_code) {
                                                                if (!child.is_selected) {
                                                                    let isAvailable = false;
                                                                    availableVariantProducts.map(
                                                                        (availableVariantProduct) => {
                                                                            if (availableVariantProduct.includes(variantValues.uid)
                                                                            ) {
                                                                                isAvailable = true;
                                                                                return true;
                                                                            }

                                                                            return false;
                                                                        }
                                                                    );

                                                                    if (!isAvailable) {
                                                                        className = 'disabled';
                                                                    }
                                                                } else {
                                                                    if (availableVariantProducts.length === 0) {
                                                                        selectedVariants.map((selectedVariant) => {
                                                                            if (selectedVariant?.value?.uid === variantValues?.uid) {
                                                                                className = 'disabled';
                                                                            }
                                                                            return true;
                                                                        });
                                                                    }
                                                                }
                                                            }

                                                            return true;
                                                        });

                                                        return (
                                                            <React.Fragment key={idx}>
                                                                <li
                                                                    className={className}
                                                                    onMouseDown={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        handleSelectVariant(
                                                                            variant.attribute_code,
                                                                            variantValues
                                                                        );
                                                                    }}
                                                                >
                                                                    <NavLink
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                        }}
                                                                        to='#'
                                                                        title={variantValues.label}
                                                                    >
                                                                    <span
                                                                        style={{
                                                                            position: 'absolute',
                                                                            width: 'calc(100% - 4px)',
                                                                            height: 'calc(100% - 4px)',
                                                                            top: 2,
                                                                            left: 2,
                                                                            zIndex: 100,
                                                                            borderRadius: '50%',
                                                                            background: variantValues.label,
                                                                        }}
                                                                    ></span>
                                                                    </NavLink>
                                                                </li>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                            </ul>
                                        </div>
                                    </React.Fragment>
                                );
                            }

                            return (
                                <React.Fragment key={idx}>
                                    <div className='product-size-wrapper'>
                                        <h4>{variant?.label}:</h4>

                                        <ul>
                                            {!!variant?.values &&
                                                variant?.values.map((variantValues, idx) => {
                                                    const isSelected = selectedVariants.find(
                                                        (item) => item?.value?.uid === variantValues?.uid
                                                    );

                                                    let className = '';
                                                    if (isSelected) {
                                                        className = 'active';
                                                    }

                                                    selectedVariants.map((child) => {
                                                        if (child.key === variant.attribute_code) {
                                                            if (!child.is_selected) {
                                                                let isAvailable = false;
                                                                availableVariantProducts.map(
                                                                    (availableVariantProduct) => {
                                                                        if (availableVariantProduct.includes(variantValues.uid)) {
                                                                            isAvailable = true;
                                                                            return true;
                                                                        }
                                                                        return false;
                                                                    }
                                                                );

                                                                if (!isAvailable) {
                                                                    className = 'disabled';
                                                                }
                                                            } else {
                                                                if (availableVariantProducts.length === 0) {
                                                                    selectedVariants.map((selectedVariant) => {
                                                                        if (selectedVariant?.value?.uid === variantValues?.uid) {
                                                                            className = 'disabled';
                                                                        }
                                                                        return true;
                                                                    });
                                                                }
                                                            }
                                                        }
                                                        return true;
                                                    });

                                                    return (
                                                        <React.Fragment key={idx}>
                                                            <li
                                                                className={className}
                                                                onMouseDown={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    handleSelectVariant(
                                                                        variant.attribute_code,
                                                                        variantValues
                                                                    );
                                                                }}
                                                            >
                                                                <NavLink
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                    }}
                                                                    style={{
                                                                        width: 'auto',
                                                                        paddingLeft: 12,
                                                                        paddingRight: 12,
                                                                    }}
                                                                    to='#'
                                                                >
                                                                    {variantValues.label}
                                                                </NavLink>
                                                            </li>
                                                        </React.Fragment>
                                                    );
                                                })}
                                        </ul>
                                    </div>
                                </React.Fragment>
                            );
                        })}

                    <div className='product-info-btn'>
                        <NavLink
                            to='#'
                            onClick={(e) => {
                                e.preventDefault();
                                setSizeGuide(true);
                            }}
                        >
                            <i className='fas fa-crop'></i> Size guide
                        </NavLink>
                        <NavLink
                            to='#'
                            onClick={(e) => {
                                e.preventDefault();
                                setShipModal(true);
                            }}
                        >
                            <i className='fas fa-truck'></i> Shipping
                        </NavLink>
                    </div>

                    <div className='product-add-to-cart'>
                        <div className='input-counter'>
                            <span className='minus-btn' onClick={DecreaseItem}>
                                <i className='fas fa-minus'></i>
                            </span>
                            <input
                                value={qty}
                                type={'number'}
                                min='1'
                                className={'hide-control'}
                                readOnly={true}
                                onKeyDown={(e) => {
                                    if (
                                        !(
                                            (e.keyCode >= 48 && e.keyCode <= 57) ||
                                            [46, 8, 9, 27, 13, 110].indexOf(e.keyCode) >= 0
                                        )
                                    )
                                        e.preventDefault();
                                }}
                                onChange={(e) => setQty(e.target.value)}
                            />
                            <span className='plus-btn' onClick={IncrementItem}>
                                <i className='fas fa-plus'></i>
                            </span>
                        </div>

                        <button
                            type='submit'
                            className='btn btn-primary'
                            style={{width: 166}}
                            onClick={(e) => {
                                e.preventDefault();
                                setLoadingTarget('add-to-cart');
                                handleClickAddToCart('add-to-cart');
                            }}
                        >
                            {isAddToCartLoading && loadingTarget === 'add-to-cart' ? (
                                <span className='spinner-grow spinner-grow-sm'/>
                            ) : (
                                <>
                                    <i className='fas fa-cart-plus'></i> Add to cart
                                </>
                            )}
                        </button>
                    </div>

                    <div className='wishlist-compare-btn'>
                        <NavLink
                            to='#'
                            className='btn btn-light'
                            onClick={(e) => {
                                e.preventDefault();
                                if (isWishlistLoading) return;
                                handleWishlist();
                            }}
                            style={{
                                border: '1px solid #fff',
                                background: '#000',
                                filter: !wishlistItemId ? 'invert()' : '',
                            }}
                        >
                            {isWishlistLoading ? (
                                <span
                                    style={{marginRight: 8}}
                                    className='spinner-grow spinner-grow-sm'
                                />
                            ) : (
                                <i
                                    style={{marginRight: 8}}
                                    className={`${!!wishlistItemId ? 'fas' : 'far'} fa-heart`}
                                ></i>
                            )}

                            {!!wishlistItemId ? 'Remove Wishlist' : 'Add to Wishlist'}
                        </NavLink>

                        <NavLink
                            to='#'
                            className='btn btn-light'
                            onClick={handleCompare}
                            style={{
                                whiteSpace: 'nowrap',
                                border: '1px solid #fff',
                                background: '#000',
                                filter: !isInCompareList ? 'invert()' : '',
                            }}
                        >
                            {isCompareLoading ? (
                                <>
                                    <span className='spinner-grow spinner-grow-sm'/>
                                    {compareTitle}
                                </>
                            ) : (
                                <>
                                    <i className='fas fa-balance-scale'></i>
                                    {compareTitle}
                                </>
                            )}
                        </NavLink>
                    </div>

                    <div className='buy-checkbox-btn'>

                        <div className='item'>
                            <button
                                type='submit'
                                style={{width: '100%'}}
                                className='btn btn-primary'
                                onClick={(e) => {
                                    e.preventDefault();
                                    setLoadingTarget('buy-now');
                                    handleClickAddToCart('buy-now');
                                }}
                            >
                                {isAddToCartLoading && loadingTarget === 'buy-now' ? (
                                    <span className='spinner-grow spinner-grow-sm'/>
                                ) : (
                                    <>Buy It Now!</>
                                )}
                            </button>
                        </div>
                    </div>

                    <div className='custom-payment-options'>
                        {!!customPayment &&
                            !!customPayment.length &&
                            customPayment.map((item) => {
                                return renderHtmlContent({content: item.content});
                            })}
                    </div>
                </div>
            </div>
            {sizeGuide ? (
                <SizeGuide closeSizeGuide={() => setSizeGuide(false)}/>
            ) : (
                ''
            )}
            {shipModal ? <Shipping closeShipModal={() => setShipModal(false)}/> : ''}
        </>
    );
};
