import { gql } from '@apollo/client';

export const GET_POST_RECENT_GQL = gql`
  query posts($page: Int!) {
    posts(page: $page, type: "recent") {
      last_page_number
      items {
        post_id
        published_at
        title
        short_content
        url_key
        post_thumbnail
        categories
      }
    }
  }
`;
export const GET_POST_GRID_GQL = gql`
  query posts($page: Int!) {
    posts(page: $page, type: "grid") {
      last_page_number
      items {
        post_id
        published_at
        title
        short_content
        url_key
        post_thumbnail
        categories
      }
    }
  }
`;

export const GET_BLOG_CATEGORIES_GQL = gql`
  query blog_categories {
    blog_categories {
      children {
        category_id
        name
        url_key
        parent_id
        path
        level
      }
    }
  }
`;
export const GET_POST_BY_SLUG_GQL = gql`
  query post($param: String!) {
    post(url_key: $param) {
      post_id
      published_at
      title
      short_content
      full_content
      url_key
      post_thumbnail
      categories
      tags
      meta_title
      meta_description
      meta_tags
      author {
        name
      }
    }
  }
`;

export const GET_All_TAGS_GQL = gql`
  query tags {
    tags {
      items {
        tag_id
        name
        url_key
      }
    }
  }
`;

export const GET_COMMENTS_BY_POST_ID = gql`
  query comments($postId: Int!) {
    comments(post_id: $postId, type: "list") {
      items {
        comment_id
        status
        reply_to
        message
        name
        email
        updated_at
      }
    }
  }
`;

export const ADD_NEW_COMMENT = gql`
  mutation addBlogComment(
    $email: String!
    $name: String!
    $message: String!
    $postId: Int!
    $replyTo: Int
  ) {
    addBlogComment(
      customer_id: null
      email: $email
      name: $name
      message: $message
      post_id: $postId
      reply_to: $replyTo
    ) {
      items {
        comment_id
        status
        reply_to
        message
        name
        email
        updated_at
      }
    }
  }
`;
