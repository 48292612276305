/* Home */
export const ROUTING_HOME = '/';

/* Search */
export const ROUTING_SEARCH_PATH = '/search';
export const ROUTING_SEARCH = `${ROUTING_SEARCH_PATH}/:keyword`;

/* Product */
export const ROUTING_PRODUCT_CATEGORY_PATH = '/category';
export const ROUTING_PRODUCT_CATEGORY = '/category/:categorySlug';
export const ROUTING_PRODUCT_DETAIL_PATH = '/product';
export const ROUTING_PRODUCT_DETAIL = '/product/:productSlug';

/* Auth */
export const ROUTING_AUTH_SIGN_IN = '/sign-in';
export const ROUTING_AUTH_SIGN_UP = '/sign-up';
export const ROUTING_AUTH_FORGOT_PASSWORD = '/forgot-password';
export const ROUTING_AUTH_RESET_FORGOT_PASSWORD = '/reset-forgot-password';
export const ROUTING_AUTH_CONNECT_FACEBOOK = '/connect-facebook';
export const ROUTING_AUTH_CONNECT_GOOGLE = '/connect-google';

/* Profile */
export const ROUTING_USER = '/user';
export const ROUTING_USER_EDIT = `${ROUTING_USER}/edit`;
export const ROUTING_USER_CHANGE_PASSWORD = `${ROUTING_USER}/change-password`;
export const ROUTING_ORDER_LIST = `${ROUTING_USER}/orders`;
export const ROUTING_ORDER_ID = `${ROUTING_ORDER_LIST}/:orderID`;
export const ROUTING_ADDRESS_LIST = `${ROUTING_USER}/my-address`;
export const ROUTING_CREATE_ADDRESS = `${ROUTING_USER}/create-address`;
export const ROUTING_EDIT_ADDRESS = `${ROUTING_ADDRESS_LIST}/:addressID`;

/* Checkout */
export const ROUTING_CART = '/cart';
export const ROUTING_CHECKOUT = '/checkout';
export const ROUTING_ORDER_SUCCESS = '/order-success';
export const ROUTING_CONNECT_PAYPAL = '/connect-paypal';
export const ROUTING_CANCEL_PAYPAL = '/cancel-paypal';
export const ROUTING_CONNECT_STRIPE = '/connect-stripe';

/*Blog Page */
export const ROUTING_BLOG_PATH = '/blog';
export const ROUTING_BLOG_DETAIL = `${ROUTING_BLOG_PATH}/:blogSlug`;

/* Static Page */
export const ROUTING_ABOUT_US = '/about';
export const ROUTING_FAQ = '/faq';
export const ROUTING_CONTACT_US = '/contact-us';
export const ROUTING_CUSTOMER_SERVICE = '/customer-service';
export const ROUTING_SIZE_GUIDE = '/sizing-guide';

export const ROUTING_GALLERY = '/gallery';
export const ROUTING_COLLECTION = '/collection';

/*Compare */
export const ROUTING_COMPARE = '/compare';

/* Not Found */
export const ROUTING_404 = '*';
