import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import OwlCarousel from "react-owl-carousel3";
import * as ROUTINGS from "../../routings/path";

const options = {
    loop: true,
    nav: false,
    dots: true,
    margin: 30,
    autoplayHoverPause: true,
    autoplay: true,
    navText: [
        "<i class='fas fa-chevron-left'></i>",
        "<i class='fas fa-chevron-right'></i>",
    ],
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        1024: {
            items: 3,
        },
    },
};

class NewsThree extends Component<any, any> {
    state = {
        display: false,
        panel: true,
    };

    componentDidMount() {
        this.setState({display: true});
    }

    render() {
        let {posts} = this.props;
        return (
            <section className="news-area ptb-60">
                <div className="container">
                    <div className="section-title">
                        <h2>
                            <span className="dot"></span>News
                        </h2>
                    </div>

                    {this.state.display ? (
                        <OwlCarousel
                            className="news-slides owl-carousel owl-theme"
                            {...options}
                        >
                            {!!posts &&
                                posts.map((item, idx) => (
                                    <React.Fragment key={idx}>
                                        <div
                                            className="single-blog-post-box"
                                            style={{textAlign: "center"}}
                                        >
                                            <div className="blog-image">
                                                <NavLink
                                                    to={`${ROUTINGS.ROUTING_BLOG_PATH}/${item.url_key}`}
                                                >
                                                    <img
                                                        src={item.post_thumbnail}
                                                        style={{objectFit: "cover"}}
                                                        alt=""
                                                    />
                                                </NavLink>
                                            </div>

                                            <div className="post-content">
                                                <ul className="post-meta">
                                                    <li>
                                                        By:{" "}
                                                        <NavLink to="#">
                                                            Admin
                                                        </NavLink>
                                                    </li>
                                                    <li>{item.published_at}</li>
                                                </ul>
                                                <h3>
                                                    <NavLink
                                                        to={`${ROUTINGS.ROUTING_BLOG_PATH}/${item.url_key}`}
                                                        style={{
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            display: "-webkit-box",
                                                            WebkitBoxOrient: "vertical",
                                                            lineHeight: "20px",
                                                            height: 60,
                                                            WebkitLineClamp: 3,
                                                        }}
                                                    >
                                                        {item.title}
                                                    </NavLink>
                                                </h3>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))}
                        </OwlCarousel>
                    ) : (
                        ""
                    )}
                </div>
            </section>
        );
    }
}

export default NewsThree;
