import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

class Breadcrumb extends Component<any, any> {
    render() {
        return (
            <div className="page-title-area">
                <div className="container">
                    <ul>
                        <li>
                            <NavLink to="/">
                                Home
                            </NavLink>
                        </li>
                        <li>{this.props.title}</li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Breadcrumb;
