import React, {useEffect, useState} from "react";
import callGetApi, {toAstError} from "../../api";
import {QUERY_GET_AVAILABLE_COUNTRIES} from "../../graphql/customer.gql";

export default () => {

    let initAvailableCountries = [{code: '', country: ''}];
    const [availableCountries, setAvailableCountries] = useState(initAvailableCountries);

    const [initLoading, setInitLoading] = useState(false);

    const queryGetAvailableCountries = (isMounted) => {
        setInitLoading(true);
        if (isMounted) {
            callGetApi(QUERY_GET_AVAILABLE_COUNTRIES).then((res: any) => {
                let countries =
                    !!res.data &&
                    !!res.data.data &&
                    !!res.data.data.countries && res.data.data.countries;

                if (countries) {
                    let availableData = [];
                    countries.map((country) => {
                        if (country.full_name_english) {
                            availableData.push({
                                code: country.id,
                                country: country.full_name_english
                            });
                        }

                        return true;
                    });
                    setAvailableCountries(availableData);
                }

                if (res.data.errors) {
                    let errors = res.data.errors;
                    toAstError(errors);
                }
            });
        }
        setInitLoading(false);
    };

    useEffect(() => {
        let isMounted = true; // note mutable flag
        queryGetAvailableCountries(isMounted);
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {!initLoading ? (
                <>
                    {availableCountries.map(({code, country}) => (
                        <option key={code} value={code}>{country}</option>
                    ))}
                </>
            ) : (
                <span className='spinner-grow spinner-grow-sm'/>
            )}
        </>
    );
};
